import React, { useEffect, useState } from "react";
import { TextField, Button, Typography } from "@mui/material";
import { useField, useFormikContext } from "formik";
import Loading from "../../ui/RingLoader";

const EmailInputField = ({ label, ...props }: any) => {
  const { values } = useFormikContext<any>();
  const [isExist, setIsExist] = useState<boolean>(false);
  const [available, setAvailable] = useState<boolean>(false);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [emailNotValid, setEmailNotValid] = useState<boolean>(false);
  const [field, meta] = useField(props);
  useEffect(() => {
    if (isExist) {
      setTimeout(() => {
        setIsExist(false);
      }, 5000);
    }
  }, [isExist]);
  useEffect(() => {
    if (available) {
      setTimeout(() => {
        setAvailable(false);
      }, 5000);
    }
  }, [available]);
  useEffect(() => {
    if (emailNotValid) {
      setTimeout(() => {
        setEmailNotValid(false);
      }, 5000);
    }
  }, [emailNotValid]);

  const handleCheckEmail = () => {
    setIsLoading(true);
    if (localStorage.getItem("token")) {
      async function fetchData() {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/users/checkEmailExist`,

          {
            method: "POST",
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              email: values.email.toLowerCase(),
            }),
          }
        );

        const jsonResponse = await response.json();
        if (jsonResponse.isExist && values.email !== "") {
          setIsExist(true);
          values.email = "";
        } else if (
          !jsonResponse.isExist &&
          values.email !== "" &&
          values.email.includes("@")
        ) {
          setAvailable(true);
        } else if (
          !jsonResponse.isExist &&
          values.email !== "" &&
          !values.email.includes("@")
        ) {
          values.email = "";
          setEmailNotValid(true);
        }
        setIsLoading(false);
      }
      fetchData();
    }
  };
  return (
    <>
      {isExist && (
        <Typography
          fontSize={14}
          color="#f44336"
          sx={{ marginY: "5px", marginLeft: "15px" }}
        >
          Email exist. Please select a different email.
        </Typography>
      )}
      {available && (
        <Typography
          fontSize={14}
          color="green"
          sx={{ marginY: "5px", marginLeft: "15px" }}
        >
          Email Available.
        </Typography>
      )}
      {emailNotValid && (
        <Typography
          fontSize={14}
          color="#f44336"
          sx={{ marginY: "5px", marginLeft: "15px" }}
        >
          Please create a valid email.
        </Typography>
      )}
      {loading ? (
        <Loading />
      ) : (
        <div>
          <TextField
            fullWidth
            label={label}
            {...field}
            {...props}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            variant="filled"
          />
          <Button
            onClick={handleCheckEmail}
            type="button"
            sx={{
              width: "100px",
              paddingX: "0",
              height: "40px",
              gridColumn: "span 4",
              font: "inherit",
              fontWeight:"700",
              fontSize:"10px",
              color: "#777777",
              marginTop: "10px",
            }}
            className="checkIfExist"
          >
            Check Availability
          </Button>
        </div>
      )}
    </>
  );
};

export default EmailInputField;
