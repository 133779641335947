import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
const CycleBox: React.FC<{center: string, value: number}> = (props) => {
  console.log('cycle component');
    console.log(props);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <Box
        sx={{ p: "12px 0" }}
        bgcolor={colors.grey[800]}
        borderRadius="0px 0 6px 6px"
        marginBottom="24px"
      >
        <Box>
          <Typography textAlign="center" variant="h5">
            {props.center}
          </Typography>
          <Typography textAlign="center" variant="h4" fontWeight="bold">
            {props.value}
          </Typography>
        </Box>
      </Box>
    )
}




export default CycleBox;