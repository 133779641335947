import { useContext } from "react";
import UserContext from "../../store/user-context";
import { Box, Typography, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import usePackage from "../../hooks/use-package";
import UpgradeForm from "../../components/forms/UpgradeForm";

const Store = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useContext(UserContext);
  const hermonPackage = usePackage();
  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="STORE"
          subtitle="Upgrade your package or buy a product."
        />
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gridTemplateRows="auto auto 1fr 1fr 1fr auto auto"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn={{ xs: "span 12", md: "span 3" }}
          bgcolor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="8px"
          height="80%"
          maxHeight="300px"
          minHeight="300px"
        >
          <Box
            gridColumn={{ xs: "span 12", md: "span 12" }}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <img
              alt="profile-user"
              height="100px"
              width="100px"
              src="/../assets/default_avatar.png"
              style={{ cursor: "pointer", borderRadius: "8px" }}
            />
            <Typography
              variant="h5"
              marginTop="10px"
              fontWeight="bold"
              sx={{ color: colors.grey[900] }}
            >
              {user.firstName + " " + user.lastName}
            </Typography>
            <Typography
              variant="h5"
              fontWeight="200"
              marginTop="10px"
              sx={{ color: colors.grey[1300] }}
            >
              Current Package
            </Typography>
            <Typography
              variant="h5"
              marginTop="2px"
              fontWeight="bold"
              sx={{ color: colors.grey[900] }}
            >
              {/* {hermonPackage} Pack */}
              {user.currentPackage} Pack
            </Typography>
            <Box
              sx={{
                border: "10px",
                borderColor: "white",
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                marginTop: "10px",
              }}
            >
              <Typography
                variant="h5"
                fontWeight="200"
                sx={{ color: colors.grey[1300] }}
              >
                Shipping Address
              </Typography>
              <Typography
                variant="h5"
                marginTop="2px"
                fontWeight="bold"
                sx={{ color: colors.grey[900] }}
              >
                {user.shippingAddress}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          gridColumn={{ xs: "span 12", md: "span 9" }}
          bgcolor={colors.primary[400]}
          alignItems="center"
          justifyContent="center"
          borderRadius="8px"
          height="100%"
        >
          <Box
            gridColumn={{ xs: "span 12", md: "span 12" }}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            marginY="20px"
          >
            {hermonPackage !== "Legacy" && (
              <Typography
                variant="h3"
                fontWeight="700"
                color={colors.grey[100]}
                marginBottom="10px"
              >
                {user.currentPackage === "Legacy" ? "BUY" : "UPGRADE"}
              </Typography>
            )}
            <img
              alt="profile-user"
              width="240px"
              src={`/../assets/probiox.png`}
              style={{ cursor: "pointer", borderRadius: "8px" }}
            />
            <UpgradeForm />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Store;
