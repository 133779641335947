import { useState, useContext } from "react";
import {
  Box,
  Button,
  TextField,
  useTheme,
  InputAdornment,
  IconButton,
  Checkbox,
  Typography,
} from "@mui/material";
import UserContext from "../../store/user-context";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { tokens } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup";

import Header from "../../components/Header";

type FormValues = {
  userName: string;
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

const passwordRegExp =
  /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+=[\]{};':"\\|,.<>/?]).{8,}$/;

const ChangePassword = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [showCurrentPassword, setShowCurrentPassword] =
    useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] =
    useState<boolean>(false);
  const [passwordIsCorrect, setPasswordIsCorrect] = useState<boolean | null>(
    null
  );
  const { user } = useContext(UserContext);
  const userSchema = yup.object().shape({
    userName: yup.string().required("required"),
    currentPassword: !user.isAdmin
      ? yup.string().required("required")
      : yup.string(),
    newPassword: yup
      .string()
      .matches(passwordRegExp, "Password is not valid")
      .required("required"),
    confirmNewPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Passwords must match")
      .required("required"),
  });
  const initialValues = {
    userName: user.username,
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };
  const handleFormSubmit = (values: FormValues) => {
    console.log("VALUES");
    console.log(values);
    async function fetchPassword() {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/changepassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(values),
        }
      );

      const jsonResponse = await response.json();
      console.log(jsonResponse);
      if (!jsonResponse.passwordCorrect) {
        setPasswordIsCorrect(false);
      }
      if (jsonResponse.passwordChange) {
        setPasswordIsCorrect(true);
      }
    }
    fetchPassword();
  };

  const handleToggleShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handleToggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleToggleShowConfirmNewPassword = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="CHANGE PASSWORD"
          subtitle="Minimum of 8 characters, with capital letter, symbol, and number. "
        />
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gridTemplateRows="auto auto 1fr 1fr 1fr auto auto"
        gap="20px"
      >
        <Box
          gridColumn={{ xs: "span 12", md: "span 12" }}
          bgcolor={colors.primary[400]}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          borderRadius="8px"
          paddingTop="20px"
        >
          {passwordIsCorrect && passwordIsCorrect !== null && (
            <Typography
              variant="h5"
              fontWeight="400"
              color={colors.greenAccent[1000]}
              sx={{ marginBottom: "10px" }}
            >
              Password successfully changed
            </Typography>
          )}

          {!passwordIsCorrect && passwordIsCorrect !== null && (
             <Typography variant="h5" fontWeight="400" color="red">
             Current password not correct.
           </Typography>
          )}
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={userSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box marginX="20px">
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Username"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.userName}
                    name="userName"
                    error={!!touched.userName && !!errors.userName}
                    helperText={touched.userName && errors.userName}
                    inputProps={{ readOnly: user.isAdmin ? false : true }}
                    sx={{
                      gridColumn: "span 4",
                      marginBottom: "20px",
                    }}
                  />
                  {!user.isAdmin && (
                    <TextField
                      fullWidth
                      variant="filled"
                      type={showCurrentPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleToggleShowCurrentPassword}
                            >
                              <Checkbox
                                checked={showCurrentPassword}
                                icon={<VisibilityOff />}
                                checkedIcon={<Visibility />}
                                inputProps={{
                                  "aria-label": "toggle password visibility",
                                }}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      label="Current Password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.currentPassword}
                      name="currentPassword"
                      error={
                        !!touched.currentPassword && !!errors.currentPassword
                      }
                      helperText={
                        touched.currentPassword && errors.currentPassword
                      }
                      sx={{
                        gridColumn: "span 4",
                        marginBottom: "20px",
                      }}
                    />
                  )}
                  <TextField
                    fullWidth
                    variant="filled"
                    type={showNewPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleToggleShowNewPassword}>
                            <Checkbox
                              checked={showNewPassword}
                              icon={<VisibilityOff />}
                              checkedIcon={<Visibility />}
                              inputProps={{
                                "aria-label": "toggle password visibility",
                              }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    label="New Password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.newPassword}
                    name="newPassword"
                    error={!!touched.newPassword && !!errors.newPassword}
                    helperText={touched.newPassword && errors.newPassword}
                    sx={{
                      gridColumn: "span 4",
                      marginBottom: "20px",
                    }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type={showConfirmNewPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleToggleShowConfirmNewPassword}
                          >
                            <Checkbox
                              checked={showConfirmNewPassword}
                              icon={<VisibilityOff />}
                              checkedIcon={<Visibility />}
                              inputProps={{
                                "aria-label": "toggle password visibility",
                              }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    label="Confirm New Password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirmNewPassword}
                    name="confirmNewPassword"
                    error={
                      !!touched.confirmNewPassword &&
                      !!errors.confirmNewPassword
                    }
                    helperText={
                      touched.confirmNewPassword && errors.confirmNewPassword
                    }
                    sx={{
                      gridColumn: "span 4",
                      marginBottom: "20px",
                    }}
                  />
                  <Box display="flex" justifyContent="end" marginBottom="20px">
                    <Button
                      type="submit"
                      color="primary"
                      className="multiStepButton"
                      variant="contained"
                    >
                      SUBMIT
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
};

export default ChangePassword;
