import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataContacts } from "../../data/mock-data";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";



export const Contacts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.5
    },
    {
        field: "registrarID",
        fieldName: "Registrar ID",

    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      headerAlign: "left" as const,
      align: "left" as const,
    },
      {
        field: "phone",
        headerName: "Phone Number",
        flex: 1,
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
      },
      {
        field: "address",
        headerName: "Address",
        flex: 1,
      },
      {
        field: "city",
        headerName: "City",
        flex: 1,
      },
      {
        field: "zipCode",
        headerName: "ZipCode",
        flex: 1,
      },

  ];
  return (
    <Box m="20px">
      <Header title="Contacts" subtitle="List of Contacts for Future Reference" />
      <Box m="40px 0 0 0"
        height="75vh"
        sx = {{ 
            "& .MuiDataGrid-root": {
                border: "none"
            },
            "& .MuiDataGrid-cell": {
                borderBottom: "none"
            },
            "& .name-column--cell": {
                // color: colors.greenAccent[300]
                color: "#de9728"
            },
            "& .MuiDataGrid-columnHeaders":{
                // backgroundColor: colors.blueAccent[700],
                backgroundColor: "#de9728",
                borderBottom: "none"
            },
            "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400]
            },
            "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                // backgroundColor: colors.blueAccent[700],
                backgroundColor: "#de9728",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`
            }
         }}
      >
        <DataGrid rows={mockDataContacts} columns={columns} components = {{ Toolbar: GridToolbar }}></DataGrid>
      </Box>
    </Box>
  );
};

export default Contacts;
