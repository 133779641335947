import { Box, Button, useTheme, Typography, Link } from "@mui/material";

import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

import Header from "../../components/Header";
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import AnnouncementIcon from '@mui/icons-material/Announcement';
const Library = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    //states

    //others

    //effects

    //handlers
    // ...code here

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="LIBRARY" subtitle="List of helpful materials that can help you grow your network." />

                {/* <Box>
                    <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                    >
                        <DownloadOutlinedIcon sx={{ mr: "10px" }} />
                        Download Reports
                    </Button>
                </Box> */}
            </Box>

            {/* GRID & CHARTS */}
            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="140px"
                gap="20px"
            >
                {/* ROW 1 */}
                {/* <Link to="https://www.google.com"> */}

                <Link
                    gridColumn={{ xs: "span 12", md: "span 6", lg: "span 2" }}
                    bgcolor={colors.primary[400]}
                    display="flex"
                    flexDirection={"column"}
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="8px"
                    
                    href="https://drive.google.com/drive/folders/1PWLxSAAkCHiqdkz1PeDZx0nAasxYgKDp"
                    target="_blank"
                    sx = {{ textDecoration:"none" }}
                >
                    <FormatAlignCenterIcon sx={{ color: colors.grey[900], fontSize: "52px" }} />
                    <Typography variant="h6" fontWeight={200} color={colors.grey[100]} sx={{ marginTop:"10px" }}>
                        Application Form
                    </Typography>
                </Link>

                {/* </Link> */}
                <Link
                    gridColumn={{ xs: "span 12", md: "span 6", lg: "span 2" }}
                    bgcolor={colors.primary[400]}
                    display="flex"
                    flexDirection={"column"}
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="8px"
                    href="https://drive.google.com/drive/folders/1b_phBJJ7egociiw0kO6QDeSxvVIcz6ur"
                    target="_blank"
                    sx = {{ textDecoration:"none" }}
                >
                    <CreditScoreIcon sx={{ color: colors.grey[900], fontSize: "52px" }} />
                    <Typography variant="h6" fontWeight={200} color={colors.grey[100]} sx={{ marginTop:"10px" }}>
                        Business Card Templates
                    </Typography>
                </Link>
                <Link
                    gridColumn={{ xs: "span 12", md: "span 6", lg: "span 2" }}
                    bgcolor={colors.primary[400]}
                    display="flex"
                    flexDirection={"column"}
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="8px"
                    href="https://drive.google.com/drive/folders/1xtbSp96_EUyHv_BxGJ_f-iK4RqJwgOU0"
                    target="_blank"
                    sx = {{ textDecoration:"none" }}
                >
                    <AirplaneTicketIcon sx={{ color: colors.grey[900], fontSize: "52px" }} />
                    <Typography variant="h6" fontWeight={200} color={colors.grey[100]} sx={{ marginTop:"10px" }}>
                        Flyers
                    </Typography>
                </Link>
                <Link
                    gridColumn={{ xs: "span 12", md: "span 6", lg: "span 2" }}
                    bgcolor={colors.primary[400]}
                    display="flex"
                    flexDirection={"column"}
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="8px"
                    href="https://drive.google.com/drive/folders/1HNbVDBDM-mfLYtMyBpzYzwQWXiO9LiLr"
                    target="_blank"
                    sx = {{ textDecoration:"none" }}
                >
                    <TrackChangesIcon sx={{ color: colors.grey[900], fontSize: "52px" }} />
                    <Typography variant="h6" fontWeight={200} color={colors.grey[100]} sx={{ marginTop:"10px" }}>
                        Logos
                    </Typography>
                </Link>
                <Link
                    gridColumn={{ xs: "span 12", md: "span 6", lg: "span 2" }}
                    bgcolor={colors.primary[400]}
                    display="flex"
                    flexDirection={"column"}
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="8px"
                    href="https://drive.google.com/drive/folders/1yJ0kFOrOLmIANFrQxlJy9U6zJsq7_Hin"
                    target="_blank"
                    sx = {{ textDecoration:"none" }}
                >
                    <AnnouncementIcon sx={{ color: colors.grey[900], fontSize: "52px" }} />
                    <Typography variant="h6" fontWeight={200} color={colors.grey[100]} sx={{ marginTop:"10px" }}>
                        Tarps
                    </Typography>
                </Link>
            </Box>
        </Box>
    );
};

export default Library;
