import { useContext, useEffect } from "react";
import { HermonPackageContext } from "../../../store/hermon-package-context";
import { Select, Typography } from "@mui/material";
import { useField, useFormikContext } from "formik";
import MenuItem from "@mui/material/MenuItem";
import useShippingFee from "../../../hooks/use-shipping-fee";

const SelectPackageField = ({ label, ...props }: any) => {
    
    const { setHermonPackage } = useContext(HermonPackageContext);
    const { values } = useFormikContext<any>();
    useShippingFee(values.country, +values.package);
    const [field, meta] = useField(props);
    
    useEffect(() => {
        //hermonPackage is the quantity in bottles.
        setHermonPackage(values.package);
    }, [setHermonPackage, values.package])
    return <>

        <Select label={label} {...field}
            error={meta.touched && Boolean(meta.error)}
        >
            <MenuItem value="1">Starter Package - $125.00</MenuItem>
            <MenuItem value="3">Business Package - $300.00</MenuItem>
            <MenuItem value="6">Premiere Package - $510.00</MenuItem>
            <MenuItem value="10">Achievers Package - $780.00</MenuItem>
            <MenuItem value="20">Legacy Package - $1300.00</MenuItem>
        </Select>
        {meta.touched && Boolean(meta.error) &&
            <Typography

                fontSize={10}
                color="#f44336"
                sx={{ marginTop: "5px", marginLeft: "15px" }}
            >required</Typography>
        }
    </>

}

export default SelectPackageField;