import { useContext, useEffect } from "react";
import { ProvinceContext } from "../../../store/province-context";

import { Select, Typography } from "@mui/material";
import { useField, useFormikContext } from "formik";
import MenuItem from "@mui/material/MenuItem";


const SelectProvinceField = ({ label, ...props }: any) => {
    const { setProvinceCode } = useContext(ProvinceContext);
    
    const { values, setFieldValue } = useFormikContext<any>();
    const options = props.options;
    const [field, meta] = useField(props)

    useEffect(() => {
        setProvinceCode(values.province);
    }, [setProvinceCode, values.province])
    // useEffect(()=>{
    //     setFieldValue(field.name, '');
    // },[values.country,setFieldValue, field.name])
    return <>

        <Select label={label} {...field}
            error={meta.touched && Boolean(meta.error)}
        >
            <MenuItem value="">--Select District or Province--</MenuItem>
            {options.map((option: any) => (
                <MenuItem value={`${option.code}-${option.name}`} key={option.code}>
                    {option.name}
                </MenuItem>
            ))}

        </Select>
        {meta.touched && Boolean(meta.error) &&
            <Typography

                fontSize={10}
                color="#f44336"
                sx={{ marginTop: "5px", marginLeft: "15px" }}
            >required</Typography>
        }
    </>

}

export default SelectProvinceField;