import React, { useContext, useState, useEffect } from "react";
import { EnrolleeContext } from "../../store/enrolle-context";
import { SalesContext } from "../../store/sales-context";
import RetailStoreContext from "../../store/retail-store-context";
import { useNavigate } from "react-router-dom";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import Swal from "sweetalert2";

const PaypalPayment: React.FC<{ payer: any }> = ({ payer }) => {
  const { enrollee } = useContext(EnrolleeContext);
  const { sale } = useContext(SalesContext);
  const { retail, setRetail } = useContext(RetailStoreContext);
  const navigate = useNavigate();

  return (
    <PayPalScriptProvider
      options={{
        "client-id": `${process.env.REACT_APP_PAYPAL_ID}`, //testing
      }}
    >
      <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  currency_code: "USD",
                  value: payer.totalAmount,
                  // value: totalAmount,
                  breakdown: {
                    item_total: {
                      currency_code: "USD",
                      value: payer.totalAmount,
                      // value: totalAmount,
                    },
                  },
                },
              },
            ],
          });
        }}
        onApprove={(data, actions): any => {
          if (actions.order) {
            return actions.order
              .capture()
              .then(function (details) {
                if (
                  details &&
                  details.payer &&
                  details.payer.name &&
                  details.status === "COMPLETED"
                ) {
                  if (retail.storeType === "retail") {
                    retail.paymentGateWay = "paypal";
                    retail.transactionId = details.id;
                    retail.transactionEmail = details.payer.email_address;
                    retail.paymentType = "buy-retail";
                    async function createRetailSale() {
                      const token = localStorage.getItem("token");

                      let url = `${process.env.REACT_APP_API_URL}/retailStore/backend`;
                      if (payer.storeId) {
                        url = `${process.env.REACT_APP_API_URL}/retailStore/backend/${payer.storeId}`;
                      }
                      let response: any;
                      let jsonResponse: any;
                      if (token) {
                        response = await fetch(url, {
                          method: "POST",
                          headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify(retail),
                        });

                        jsonResponse = await response.json();
                      } else {
                        response = await fetch(url, {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify(retail),
                        });

                        jsonResponse = await response.json();
                      }

                      if (jsonResponse.isCreated) {
                        Swal.fire("Thank you! Your purchased was successful");
                        setRetail({});
                        if (payer.storeId) {
                          return navigate(`/retailStore/buy/${payer.storeId}`);
                        }
                        setRetail({});
                        return navigate("/retailStore/buy");
                      } else if (jsonResponse.userDontExist) {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Retail store don't exist. Please coordinate with your reseller and get the right link",
                        });
                        setRetail({});
                        if (payer.storeId) {
                          return navigate(`/retailStore/buy/${payer.storeId}`);
                        }
                        return navigate("/retailStore/buy");
                      } else {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!Please try again or contact your administrator",
                        });
                        setRetail({});
                        if (payer.storeId) {
                          return navigate(`/retailStore/buy/${payer.storeId}`);
                        }
                        return navigate("/retailStore/buy");
                      }
                    }
                    createRetailSale();
                  } else if (retail.storeType === "retail-enroll") {
                    retail.paymentGateWay = "paypal";
                    retail.transactionId = details.id;
                    retail.transactionEmail = details.payer.email_address;
                    retail.paymentType = "enroll-retail";
                    async function createRetailEnrollment() {
                      let url = `${process.env.REACT_APP_API_URL}/retailStore/backend-enroll`;
                      if (payer.storeId) {
                        url = `${process.env.REACT_APP_API_URL}/retailStore/backend-enroll/${payer.storeId}`;
                      }
                      const response = await fetch(url, {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify(retail),
                      });

                      const jsonResponse = await response.json();
                      // setRetail({}); //temporary
                      if (jsonResponse.isCreated) {
                        Swal.fire(
                          "Congratulations! You are now enrolled. Please login with your username and password"
                        );
                        setRetail({});
                        return navigate(`/login`);
                      } else if (jsonResponse.userDontExist) {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Retail store don't exist. Please coordinate with your reseller and get the right link",
                        });
                        setRetail({});
                        if (payer.storeId) {
                          return navigate(
                            `/retailStore/enroll/${payer.storeId}`
                          );
                        }
                        return navigate("/retailStore/enroll");
                      } else {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!Please try again or contact your administrator",
                        });
                        setRetail({});
                        if (payer.storeId) {
                          return navigate(
                            `/retailStore/enroll/${payer.storeId}`
                          );
                        }
                        return navigate("/retailStore/enroll");
                      }
                    }
                    createRetailEnrollment();
                  } else {
                    if (JSON.stringify(enrollee) === "{}") {
                      sale.paymentGateWay = "paypal";
                      sale.transactionId = details.id;
                      sale.transactionEmail = details.payer.email_address;
                      return navigate("/decider/upgrade");
                    } else {
                      enrollee.paymentGateWay = "paypal";
                      enrollee.transactionId = details.id;
                      enrollee.transactionEmail = details.payer.email_address;
                      return navigate("/decider/enrollee");
                    }
                  }
                }
              })
              .catch((error) => {
                console.log("error", error);
                navigate("/error/enrollee");
              })
              .finally(() => {
                return Promise.resolve();
              });
          }
        }}
      />
    </PayPalScriptProvider>
  );
};

export default PaypalPayment;
