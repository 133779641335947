import { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import "./Organization.css";
import Loading from "../../components/ui/RingLoader";

export const Organization = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [members, setMembers] = useState<any>([]);
  const [loading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    setIsLoading(true);
    if (localStorage.getItem("token")) {
      async function fetchData() {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/users/all`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        const jsonResponse = await response.json();
        setMembers(jsonResponse);
        console.log(jsonResponse);
        setIsLoading(false);
      }
      fetchData();
    }
  }, []);
  const columns = [
    {
      field: "gender",
      headerName: "",
      renderCell: ({ row: { gender } }: any) => {
        return (
          <Box m="0 auto" p="5px">
            <img
              src="../../assets/default_avatar.png"
              alt="gender"
              className="genderImg"
            />
          </Box>
        );
      },
    },

    {
      field: "fullName",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "userName",
      headerName: "Username",
      flex: 1,
    },
    {
      field: "sponsor",
      headerName: "Sponsor",
      flex: 1,
    },
    {
      field: "upLine",
      headerName: "Upline",
      flex: 1,
    },
    {
      field: "position",
      headerName: "Binary Position",
    },
  ];
  interface Member {
    _id: string;
    genealogy: {
      directSponsor: string;
      directSponsorName: string;
      upLine: string;
      upLineName: string;
      position: string;
      left: string | null;
      right: string | null;
    };
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    isAdmin: boolean;
    __v: number;
    gender: string;
    userName: string;
  }
  return (
    <Box m="20px">
      <Header
        title="ORGANIZATION"
        subtitle="List of people on your organizational tree"
      />

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none", //outer border
          },
          "& .MuiDataGrid-cell": {
            // borderBottom: "none",
            borderX:`0.5px solid ${colors.grey[800]}`,
            
          },
          "& .name-column--cell": {
            // color: colors.greenAccent[300]
            color: "#de9728",
            
          },
          "& .MuiDataGrid-columnHeaders": {
            // backgroundColor: colors.blueAccent[700],
            backgroundColor: `${colors.grey[800]}`,
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            // backgroundColor: colors.blueAccent[700],
            backgroundColor: `${colors.grey[1200]}`,
          },
        }}
        textAlign="center"
      >
        {loading && <Loading />}
        <DataGrid
          rows={members.map(
            (row: any) =>
              ({
                ...row,
                id: row._id,
                fullName: row.firstName + " " + row.lastName,
                position: row.genealogy.position,
                sponsor: row.genealogy.directSponsorUserName,
                upLine: row.genealogy.upLineUserName,
              } as Member)
          )}
          columns={columns}
          
        ></DataGrid>
      </Box>
    </Box>
  );
};

export default Organization;
