import moment from "moment-timezone";
export const LATime = () => {
  const offset = -8.0;
  const localTime = new Date().getTime();
  const localOffset = new Date().getTimezoneOffset() * 60000;
  const utc = localTime + localOffset;
  const LA = utc + 3600000 * offset;
  const nd = new Date(LA);

  return nd;
};

export const LAStandardDate = () => {
  const dateString = LATime();
  const dateObj = new Date(dateString);

  const year = dateObj.getFullYear();
  const month = ("0" + (dateObj.getMonth() + 1)).slice(-2); // add leading zero to month
  const day = ("0" + dateObj.getDate()).slice(-2); // add leading zero to day

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const cutOffDay = () => {
  const timeZone = "America/Los_Angeles";
  const now = new Date();
  const laTime = moment.tz(now, timeZone);
  const options: any = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
    timeZone: "America/Los_Angeles",
  };

  const currentDay = laTime.day(); //return days in number from 1 to 7
  console.log("current Day");
  console.log(currentDay);
  if (currentDay === 0) {
    return laTime.format("MMM Do YY");
  } else {
    return laTime.clone().endOf("isoWeek").format("MMM Do YY");
  }
};
