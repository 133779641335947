export const createPaymentIntent = async (
  totalAmount: string,
  user: string
) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/paymongo/createIntent`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        totalAmount: totalAmount,
        user: user,
      }),
    }
  );
  const jsonResponse = await response.json();
  return jsonResponse;
};
export const createPaymentMethod = async (data: any) => {
  const response = await fetch("https://api.paymongo.com/v1/payment_methods", {
    method: "POST",
    headers: {
      authorization: `Basic ${process.env.REACT_APP_PAYMONGO_API_KEY}`, //encoded in base64 test public key
      // authorization: "Basic cGtfbGl2ZV9FRnZEcFl1RUFaOHRRalNGZ1pmdDZ4YkQ=", //encoded in base64 live public key
      "content-type": "application/json",
    },
    body: JSON.stringify(data),
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const attachPaymentMethod = async (
  paymentIntentId: string,
  paymentMethodId: string,
  clientKey: string
) => {
  const response = await fetch(
    `https://api.paymongo.com/v1/payment_intents/${paymentIntentId}/attach`,
    {
      method: "POST",
      headers: {
        authorization: `Basic ${process.env.REACT_APP_PAYMONGO_API_KEY}`, //encoded in base64 test public key
        // authorization: "Basic cGtfbGl2ZV9FRnZEcFl1RUFaOHRRalNGZ1pmdDZ4YkQ=", //encoded in base64 live public key

        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: {
          attributes: {
            payment_method: paymentMethodId,
            client_key: clientKey,
            // return_url: `https://localhost:3001/paymongo/processing`,
            return_url: `${process.env.REACT_APP_RETURN_URL}/paymongo/processing`,
          },
        },
      }),
    }
  );
  const jsonResponse = await response.json();
  return jsonResponse;
};

export const checkStatus = async () => {
  const uPaymentIntentId = localStorage.getItem("paymentIntentId");
  const uClientKey = localStorage.getItem("clientKey");
  const uPaymentMethodId = localStorage.getItem("paymentMethodId");
  let response: any = {};
  if (uPaymentIntentId !== "" && uClientKey !== "" && uPaymentMethodId !== "") {
    // double check if succedded
    async function checkStatus() {
      const statusResponse = await fetch(
        "https://api.paymongo.com/v1/payment_intents/" +
          uPaymentIntentId +
          "?client_key=" +
          uClientKey,
        {
          method: "GET",
          headers: {
            authorization: `Basic ${process.env.REACT_APP_PAYMONGO_API_KEY}`, //encoded in base64 test public key
            //  authorization: "Basic cGtfbGl2ZV9FRnZEcFl1RUFaOHRRalNGZ1pmdDZ4YkQ=", //encoded in base64 live public key
          },
        }
      );

      const jsonStatushResponse = await statusResponse.json();
      response = jsonStatushResponse;
    }
    checkStatus();
    // localStorage.removeItem("paymentMethodId");
    // localStorage.removeItem("clientKey");
    // localStorage.removeItem("paymentIntentId");
    return response;
  }
};

export const clearStorage = () => {
  localStorage.removeItem("paymentMethodId");
  localStorage.removeItem("clientKey");
  localStorage.removeItem("paymentIntentId");
  localStorage.removeItem("enrollee");
  localStorage.removeItem("sale");
};
