import { useContext, useEffect } from "react";
import { RegionContext } from "../../../store/region-context";
import {  Select, Typography } from "@mui/material";
import { useField, useFormikContext } from "formik";
import MenuItem from "@mui/material/MenuItem";


const SelectRegionField = ({ label, ...props }: any) => {
    const { setRegionCode } = useContext(RegionContext);
    const { values, setFieldValue } = useFormikContext<any>();
    const options = props.options;
    const [field, meta] = useField(props)
    useEffect(()=>{
        setRegionCode(values.region);
    },[setRegionCode,values.region])
    // useEffect(()=>{
    //     setFieldValue(field.name, '');
    // },[values.country,setFieldValue, field.name])
    return <>

        <Select label={label} {...field}
            error={meta.touched && Boolean(meta.error)}
        >
            <MenuItem value="">--Select Region--</MenuItem>
            {options.map((option: any) => (
                <MenuItem value={`${option.code}-${option.regionName}`} key={option.code}>
                    {option.regionName}
                </MenuItem>
            ))}

        </Select>
        {meta.touched && Boolean(meta.error) &&
            <Typography

                fontSize={10}
                color="#f44336"
                sx={{ marginTop: "5px", marginLeft: "15px" }}
            >required</Typography>
        }
    </>

}

export default SelectRegionField;