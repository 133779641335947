import { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup";

import Header from "../../components/Header";

type FormValues = {
  totalAmount: string;
  name: string;
  email: string;
  contact: string;
  paymentType: string;
  eWallet: string;
  user: string;
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const userSchema = yup.object().shape({
  name: yup.string().required("required"),
  email: yup.string().required("required"),
  contact: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  eWallet: yup.string().required("required")
});

const PaymongoPayment: React.FC<{
  pay: any;
  totalAmount: number;
  firstName: string;
  lastName: string;
}> = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);


  const handleFormSubmit = (values: FormValues) => {
    values.paymentType = "eWallet";
    props.pay(values);
  };

  const initialValues = {
    totalAmount: props.totalAmount.toFixed(2),
    name: "",
    email: "",
    contact: "",
    paymentType: "eWallet",
    eWallet: "",
    user: props.firstName + " " + props.lastName,
  };

  return (
    <Box m="20px">
      <Header
        title="PHILIPPINE PAYMENT"
        subtitle="Accepts gcash, paymaya, grabpay(ONLY SUPPORTS MLM PAYMENT AT THE MOMENT. FOR RETAILER PROGRAM USE PAYPAL)"
      />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={userSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Total Amount(in PESOS)"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.totalAmount}
                name="totalAmount"
                error={!!touched.totalAmount && !!errors.totalAmount}
                helperText={touched.totalAmount && errors.totalAmount}
                inputProps={{ readOnly: true }}
                sx={{
                  gridColumn: "span 4",
                  marginBottom: "20px",
                }}
              />
              <Typography
                variant="h5"
                fontWeight="400"
                color={colors.grey[100]}
                sx={{ marginBottom: "10px" }}
              >
                Select E-Wallet
              </Typography>
              <Select
                fullWidth
                variant="filled"
                sx={{
                  marginBottom: "40px",
                }}
                labelId="eWallet"
                name="eWallet"
                id="eWallet"
                label="E-Wallet"
                onBlur={handleBlur}
                onChange={handleChange}
                error={!!touched.eWallet && !!errors.eWallet}
              >
                <MenuItem value="">Select E-Wallet</MenuItem>
                <MenuItem value="gcash">Gcash</MenuItem>
                <MenuItem value="paymaya">Paymaya</MenuItem>
                <MenuItem value="grab_pay">Grab-pay</MenuItem>
                {/* <MenuItem value="grab_pay">Grab-pay</MenuItem> */}
              </Select>
              <Typography
                variant="h5"
                fontWeight="400"
                color={colors.grey[100]}
                sx={{ marginBottom: "10px" }}
              >
                Personal Info(Information of the Payer)
              </Typography>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Payer name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{
                  gridColumn: "span 4",
                  marginBottom: "20px",
                }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{
                  gridColumn: "span 4",
                  marginBottom: "20px",
                }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Contact #"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.contact}
                name="contact"
                error={!!touched.contact && !!errors.contact}
                helperText={touched.contact && errors.contact}
                sx={{
                  gridColumn: "span 4",
                  // marginBottom: "40px",
                }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              {/* <Button type="submit" color="secondary" variant="contained">
                SUBMIT
              </Button> */}
              <Button
                type="submit"
                color="primary"
                className="multiStepButton"
                variant="contained"
              >
                SUBMIT
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default PaymongoPayment;
