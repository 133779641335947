import { useContext, useEffect, useState } from "react";
import UserContext from "../../store/user-context";
import { useNavigate } from "react-router-dom";
import { SalesContext } from "../../store/sales-context";
import { Select, Button, Typography, Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

const UpgradeForm = () => {
  const navigate = useNavigate();
  const { setSale, shippingFee, setShippingFee } = useContext(SalesContext);

  const { user } = useContext(UserContext);
  const [selectedValue, setSelectedValue] = useState("");
  const [packageArr, setPackageArr] = useState<any>([]);
  const [bottles, setBottles] = useState<string>("");
  const [nextPackage, setNextPackage] = useState<string>("");
  const [total, setTotal] = useState<string>("");
  const [maintenance, setMaintenance] = useState<string>("");

  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
  };
  useEffect(() => {
    const newArr: any = [];
    if (user.currentPackage === "Starter") {
      newArr.push(
        {
          packageName: "Business",
          packageAmount: "180.00",
          bottles: "2",
        },
        {
          packageName: "Premiere",
          packageAmount: "425.00",
          bottles: "5",
        },
        {
          packageName: "Achiever",
          packageAmount: "675.00",
          bottles: "9",
        },
        {
          packageName: "Legacy",
          packageAmount: "1235.00",
          bottles: "19",
        }
      );
      setMaintenance("95.00");
    } else if (user.currentPackage === "Business") {
      newArr.push(
        {
          packageName: "Premiere",
          packageAmount: "255.00",
          bottles: "3",
        },
        {
          packageName: "Achiever",
          packageAmount: "525.00",
          bottles: "7",
        },
        {
          packageName: "Legacy",
          packageAmount: "1105.00",
          bottles: "17",
        }
      );
      setMaintenance("90.00");
    } else if (user.currentPackage === "Premiere") {
      newArr.push(
        {
          packageName: "Achiever",
          packageAmount: "300.00",
          bottles: "4",
        },
        {
          packageName: "Legacy",
          packageAmount: "910.00",
          bottles: "14",
        }
      );
      setMaintenance("85.00");
    } else if (user.currentPackage === "Achiever") {
      newArr.push({
        packageName: "Legacy",
        packageAmount: "650.00",
      });
      setMaintenance("75.00");
    } else if (user.currentPackage === "Legacy") {
      setSelectedValue("65.00");
    }

    setPackageArr(newArr);
    if (newArr[0]) {
      setSelectedValue(newArr[0].packageAmount);
    }
  }, [user.currentPackage]);

  useEffect(() => {
    if (user.currentPackage === "Starter") {
      if (selectedValue === "180.00") {
        setBottles("2");
        setNextPackage("Business");
      } else if (selectedValue === "425.00") {
        setBottles("5");
        setNextPackage("Premiere");
      } else if (selectedValue === "675.00") {
        setBottles("9");
        setNextPackage("Achiever");
      } else if (selectedValue === "1235.00") {
        setBottles("19");
        setNextPackage("Legacy");
      } else {
        setBottles("1");
        setNextPackage(user.currentPackage);
      }
    } else if (user.currentPackage === "Business") {
      if (selectedValue === "255.00") {
        setBottles("3");
        setNextPackage("Premiere");
      } else if (selectedValue === "525.00") {
        setBottles("7");
        setNextPackage("Achiever");
      } else if (selectedValue === "1105.00") {
        setBottles("17");
        setNextPackage("Legacy");
      } else {
        setBottles("1");
        setNextPackage(user.currentPackage);
      }
    } else if (user.currentPackage === "Premiere") {
      if (selectedValue === "300.00") {
        setBottles("4");
        setNextPackage("Achiever");
      } else if (selectedValue === "910.00") {
        setBottles("14");
        setNextPackage("Legacy");
      } else {
        setBottles("1");
        setNextPackage(user.currentPackage);
      }
    } else if (user.currentPackage === "Achiever") {
      if (selectedValue === "650.00") {
        setBottles("10");
        setNextPackage("Legacy");
      } else {
        setBottles("1");
        setNextPackage(user.currentPackage);
      }
    } else if (user.currentPackage === "Legacy") {
      if (selectedValue === "65.00") {
        setBottles("2");
      } else if (selectedValue === "130.00") {
        setBottles("4");
      } else if (selectedValue === "195.00") {
        setBottles("6");
      } else if (selectedValue === "260.00") {
        setBottles("8");
      } else if (selectedValue === "325.00") {
        setBottles("10");
      } else if (selectedValue === "390.00") {
        setBottles("12");
      } else if (selectedValue === "455.00") {
        setBottles("14");
      } else if (selectedValue === "520.00") {
        setBottles("16");
      } else if (selectedValue === "585.00") {
        setBottles("18");
      } else if (selectedValue === "650.00") {
        setBottles("20");
      } else if (selectedValue === "715.00") {
        setBottles("22");
      } else if (selectedValue === "780.00") {
        setBottles("24");
      } else if (selectedValue === "845.00") {
        setBottles("26");
      } else if (selectedValue === "910.00") {
        setBottles("28");
      } else if (selectedValue === "975.00") {
        setBottles("30");
      } else if (selectedValue === "1040.00") {
        setBottles("32");
      } else if (selectedValue === "1105.00") {
        setBottles("34");
      } else if (selectedValue === "1170.00") {
        setBottles("36");
      } else if (selectedValue === "1235.00") {
        setBottles("38");
      } else if (selectedValue === "1300.00") {
        setBottles("40");
      }
    }
  }, [selectedValue, user.currentPackage]);

  useEffect(() => {
    const subTotal = (Number(selectedValue) + Number(shippingFee)).toFixed(2);
    setTotal(subTotal);
  }, [selectedValue, shippingFee]);

  useEffect(() => {
    if (user.shippingCountry === "au") {
      if (+bottles < 7) {
        setShippingFee("10.00");
      } else if (+bottles > 6 && +bottles < 21) {
        setShippingFee("16.77");
      }
    } else if (user.shippingCountry === "ph") {
      setShippingFee("0.00"); //to be confirmed.
    } else {
      if (+bottles < 7) {
        setShippingFee("25.00");
      } else if (+bottles > 6 && +bottles < 11) {
        setShippingFee("35.00");
      } else if (+bottles > 10 && +bottles < 21) {
        setShippingFee("42.00");
      }
    }
  }, [user.shippingCountry, bottles, setShippingFee]);
  const handleSubmit = (event: any) => {
    event.preventDefault();
    const totalAmount = (+selectedValue + +shippingFee).toFixed(2);
    const saleObj = {
      userName: user.username,
      userId: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      price: selectedValue,
      shippingFee: shippingFee,
      totalAmount: totalAmount,
      quantity: bottles,
      nextPackage: nextPackage,
      shippingCountry: user.shippingCountry,
      shippingAddress: user.shippingAddress,
    };
    setSale(saleObj);
    navigate("/package/payment");
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h5" fontWeight="200" marginTop="10px">
            Quantity: <span>{bottles}</span> bottles.
          </Typography>
        </Box>
        {user.currentPackage === "Legacy" ? (
          <Select
            name="packageQuantity"
            value={selectedValue}
            onChange={handleChange}
            label="Package"
            sx={{ marginTop: "20px", width: "240px" }}
          >
            <MenuItem value="65.00" key="65.00">
              2 Bottle - 65.00USD
            </MenuItem>
            <MenuItem value="130.00" key="130.00">
              4 Bottles - 130.00USD
            </MenuItem>
            <MenuItem value="195.00" key="195.00">
              6 Bottles - 195.00USD
            </MenuItem>
            <MenuItem value="260.00" key="260.00">
              8 Bottles - 260.00USD
            </MenuItem>
            <MenuItem value="325.00" key="325.00">
              10 Bottles - 325.00USD
            </MenuItem>
            <MenuItem value="390.00" key="390.00">
              12 Bottles - 390.00USD
            </MenuItem>
            <MenuItem value="455.00" key="455.00">
              14 Bottles - 455.00USD
            </MenuItem>
            <MenuItem value="520.00" key="520.00">
              16 Bottles - 520.00USD
            </MenuItem>
            <MenuItem value="585.00" key="585.00">
              18 Bottles - 585.00USD
            </MenuItem>
            <MenuItem value="650.00" key="650.00">
              20 Bottles - 650.00USD
            </MenuItem>
            <MenuItem value="715.00" key="715.00">
              22 Bottles - 715.00USD
            </MenuItem>
            <MenuItem value="780.00" key="780.00">
              24 Bottles - 780.00USD
            </MenuItem>
            <MenuItem value="845.00" key="845.00">
              26 Bottles - 845.00USD
            </MenuItem>
            <MenuItem value="910.00" key="910.00">
              28 Bottles - 910.00USD
            </MenuItem>
            <MenuItem value="975.00" key="975.00">
              30 Bottles - 975.00USD
            </MenuItem>
            <MenuItem value="1040.00" key="1040.00">
              32 Bottles - 1040.00USD
            </MenuItem>
            <MenuItem value="1105.00" key="1105.00">
              34 Bottles - 1105.00USD
            </MenuItem>
            <MenuItem value="1170.00" key="1170.00">
              36 Bottles - 1170.00USD
            </MenuItem>
            <MenuItem value="1235.00" key="1235.00">
              38 Bottles - 1235.00USD
            </MenuItem>
            <MenuItem value="1300.00" key="1300.00">
              40 Bottles - 1300.00USD
            </MenuItem>
          </Select>
        ) : (
          <Select
            name="packageQuantity"
            value={selectedValue}
            onChange={handleChange}
            label="Package"
            sx={{ marginTop: "20px", width: "240px" }}
          >
            <MenuItem value={maintenance}>
              Maintenance - {maintenance}USD
            </MenuItem>
            {packageArr.map((pack: any) => (
              <MenuItem value={pack.packageAmount} key={pack.packageAmount}>
                {pack.packageName} Package - {pack.packageAmount}USD
              </MenuItem>
            ))}
            )
          </Select>
        )}

        <Box sx={{ display: "flex", justifyContent: "right" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingRight: "20px",
            }}
          >
            <Typography
              variant="h5"
              fontWeight="200"
              marginTop="10px"
              textAlign="right"
            >
              Amount:
            </Typography>
            <Typography
              variant="h5"
              fontWeight="200"
              marginTop="10px"
              textAlign="right"
            >
              Shipping Fee:
            </Typography>
            <Typography
              variant="h5"
              fontWeight="700"
              marginTop="10px"
              textAlign="right"
            >
              Total:
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h5"
              fontWeight="200"
              marginTop="10px"
              textAlign="right"
            >
              {selectedValue} USD
            </Typography>
            <Typography
              variant="h5"
              fontWeight="200"
              marginTop="10px"
              textAlign="right"
            >
              {shippingFee} USD
            </Typography>
            <Typography
              variant="h5"
              fontWeight="700"
              marginTop="10px"
              textAlign="right"
              color="green"
            >
              {total} USD
            </Typography>
          </Box>
        </Box>
        <Button
          type="submit"
          sx={{
            bgcolor: "#de9728",
            paddingX: "50px",
            font: "inherit",
            marginTop: "10px",
            color: "white",
            ":hover": { bgcolor: "green" },
          }}
        >
          {user.currentPackage === "Legacy" ? "BUY" : "UPGRADE"}
        </Button>
      </FormControl>
    </form>
  );
};

export default UpgradeForm;
