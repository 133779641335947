import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { EnrolleeContext } from "../../store/enrolle-context";
import { SalesContext } from "../../store/sales-context";
import UserContext from "../../store/user-context";
import RetailStoreContext from "../../store/retail-store-context";
import { PackageContext } from "../../store/package-context";
import Loading from "../../components/ui/RingLoader";
import { userObj, getUserDetails } from "../../utils/user-object";
import moment from "moment";
import Swal from "sweetalert2"

const Decider = () => {
  const { type } = useParams();
  const { enrollee, setEnrollee } = useContext(EnrolleeContext);
  const { sale, setSale } = useContext(SalesContext);
  const { user, setUser } = useContext(UserContext);
  const { setCurrentPackage } = useContext(PackageContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(
    () => {
      setIsLoading(true);

      if (
        type === "enrollee" &&
        (localStorage.getItem("retail") === "{}" ||
          localStorage.getItem("retail") === null)
      ) {
        
        enrollee.paymentType = "enrollment";
        enrollee.createdDate = moment()
          .tz("America/Los_Angeles")
          .format("MMM D, YYYY h:mm:ssA");
        async function fetchData() {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/users/create`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(enrollee),
            }
          );
          const jsonResponse = await response.json();
          if (jsonResponse.isCreated) {
            navigate("/success/enrollee");
          } else {
            navigate("/error/enrollee");
          }
          setEnrollee({});
          //look for changes on the user object from the backend
          const fetchUserDetails = await getUserDetails();
          setUser(userObj(fetchUserDetails));
          setIsLoading(false);
        }
        fetchData();
      } else if (
        type === "upgrade" &&
        (localStorage.getItem("retail") === "{}" ||
          localStorage.getItem("retail") === null)
      ) {
        
        const pg = sale.paymentGateWay.split("-");
        const paymentGateWay = pg[1];
        let jsonUser: any = {};
        if (paymentGateWay === "eWallet") {
          const userString = localStorage.getItem("user");
          if (userString !== null) {
            jsonUser = JSON.parse(userString);
            setUser(jsonUser);
          }
          if (sale.quantity === "1" && jsonUser.currentPackage !== "Legacy") {
            sale.paymentType = "maintenance";
          } else if (jsonUser.currentPackage === "Legacy") {
            sale.paymentType = "buy";
          } else {
            sale.paymentType = "upgrade";
          }
        } else {
          if (sale.quantity === "1" && user.currentPackage !== "Legacy") {
            sale.paymentType = "maintenance";
          } else if (user.currentPackage === "Legacy") {
            sale.paymentType = "buy";
          } else {
            sale.paymentType = "upgrade";
          }
        }

        sale.firstName =
          paymentGateWay === "eWallet" ? jsonUser.firstName : user.firstName;
        sale.lastName =
          paymentGateWay === "eWallet" ? jsonUser.lastName : user.lastName;
        sale.gender =
          paymentGateWay === "eWallet" ? jsonUser.gender : user.gender;
        sale.userName =
          paymentGateWay === "eWallet" ? jsonUser.username : user.username;
        sale.userId = paymentGateWay === "eWallet" ? jsonUser.id : user.id;
        sale.product = "probiox";
        sale.currentPackage =
          paymentGateWay === "eWallet"
            ? jsonUser.currentPackage
            : user.currentPackage;
        sale.activeUntil =
          paymentGateWay === "eWallet"
            ? jsonUser.activeUntil
            : user.activeUntil;
        sale.createdDate = moment()
          .tz("America/Los_Angeles")
          .format("MMM D, YYYY h:mm:ssA");
        sale.purchasedDate = moment()
          .tz("America/Los_Angeles")
          .format("YYYY-MM-DD");
        async function fetchData() {
          const token = localStorage.getItem("token");
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/sale/create`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(sale),
            }
          );
          const jsonResponse = await response.json();
          setIsLoading(false);
          if (jsonResponse.isCreated) {
            // setCurrentPackage((prev: number) => prev + +sale.quantity);

            // const newUser = { ...user };
            // newUser.currentPackage = sale.nextPackage;
            // newUser.currentVolume = +sale.quantity * 20 + +user.currentVolume;
            // newUser.lastPurchased = await handleDateUpdate();
            // setUser(newUser);
            navigate("/success/upgrade");
          } else {
            navigate("/error/upgrade");
          }
          setSale({});
          //look for changes on the user object from the backend
          const fetchUserDetails = await getUserDetails();
          setUser(userObj(fetchUserDetails));
        }
        fetchData();
      } else {
        // retail program
        let retail = JSON.parse(localStorage.getItem("retail")!);
        
        if (retail.storeType === "retail") {
          // retail.paymentGateWay = "paypal";
          // retail.transactionId = details.id;
          // retail.transactionEmail = details.payer.email_address;
          retail.paymentType = "buy-retail";
          async function createRetailSale() {
            const token = localStorage.getItem("token");

            let url = `${process.env.REACT_APP_API_URL}/retailStore/backend`;
            if (retail.storeId) {
              url = `${process.env.REACT_APP_API_URL}/retailStore/backend/${retail.storeId}`;
            }
            let response: any;
            let jsonResponse: any;
            if (token) {
              response = await fetch(url, {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(retail),
              });

              jsonResponse = await response.json();
            } else {
              response = await fetch(url, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(retail),
              });

              jsonResponse = await response.json();
            }

            if (jsonResponse.isCreated) {
              Swal.fire("Thank you! Your purchased was successful");
              // setRetail({});
              // retail = {};
              localStorage.removeItem("retail");
              if (retail.storeId) {
                localStorage.removeItem("retail");
                return navigate(`/retailStore/buy/${retail.storeId}`);
              }
              // setRetail({});
              localStorage.removeItem("retail");
              return navigate("/retailStore/buy");
            } else if (jsonResponse.userDontExist) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Retail store don't exist. Please coordinate with your reseller and get the right link",
              });
              // setRetail({});
              if (retail.storeId) {
                localStorage.removeItem("retail");
                return navigate(`/retailStore/buy/${retail.storeId}`);
              }
              localStorage.removeItem("retail");
              return navigate("/retailStore/buy");
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!Please try again or contact your administrator",
              });
              // setRetail({});
              if (retail.storeId) {
                localStorage.removeItem("retail");
                return navigate(`/retailStore/buy/${retail.storeId}`);
              }
              localStorage.removeItem("retail");
              return navigate("/retailStore/buy");
            }
          }
          createRetailSale();
        } else if (retail.storeType === "retail-enroll") {
          // retail.paymentGateWay = "paypal";
          // retail.transactionId = details.id;
          // retail.transactionEmail = details.payer.email_address;
          retail.paymentType = "enroll-retail";
          async function createRetailEnrollment() {
            let url = `${process.env.REACT_APP_API_URL}/retailStore/backend-enroll`;
            if (retail.storeId) {
              url = `${process.env.REACT_APP_API_URL}/retailStore/backend-enroll/${retail.storeId}`;
            }
            const response = await fetch(url, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(retail),
            });

            const jsonResponse = await response.json();
            // setRetail({}); //temporary
            if (jsonResponse.isCreated) {
              Swal.fire(
                "Congratulations! You are now enrolled. Please login with your username and password"
              );
              // setRetail({});
              localStorage.removeItem("retail");
              return navigate(`/login`);
            } else if (jsonResponse.userDontExist) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Retail store don't exist. Please coordinate with your reseller and get the right link",
              });
              // setRetail({});
              if (retail.storeId) {
                localStorage.removeItem("retail");
                return navigate(
                  `/retailStore/enroll/${retail.storeId}`
                );
              }
              localStorage.removeItem("retail");
              return navigate("/retailStore/enroll");
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!Please try again or contact your administrator",
              });
              // setRetail({});
              if (retail.storeId) {
                localStorage.removeItem("retail");
                return navigate(
                  `/retailStore/enroll/${retail.storeId}`
                );
              }
              localStorage.removeItem("retail");
              return navigate("/retailStore/enroll");
            }
          }
          createRetailEnrollment();
        }
        
      }
    },
    // , [type, enrollee, navigate, sale, user, setCurrentPackage, setUser]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  ); //do not include handleDateUpdate

  return <>{isLoading && <Loading />}</>;
};

export default Decider;
