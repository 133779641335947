import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";

const CashingStatBox: React.FC<{
  walletBalance: string;
  totalPayout: any;
  subtitle: string;
  icon: JSX.Element;
  definition: string;
  increase: string;
}> = ({ walletBalance, totalPayout , subtitle, icon, definition, increase }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box width="100%" m="20px 30px">
      {/* <Box display="flex" justifyContent="space-between">
        {icon}
        <Box bgcolor={colors.grey[1000]} p="5px 10px" borderRadius="4px">
          Overall Cachings
        </Box>
      </Box> */}
     
        <Box display="flex" justifyContent="space-between">
          <Typography
            variant="h5"
            sx={{ color: colors.grey[1300], paddingTop: "2px" }}
          >
            Total Earning
          </Typography>
          <Typography
            variant="h3"
            fontWeight="bold"
            sx={{ color: colors.grey[900], textAlign: "right" }}
          >
            ${walletBalance}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography
            variant="h5"
            sx={{ color: colors.grey[1300], paddingTop: "2px" }}
          >
            Total Withdrawal
          </Typography>
          <Typography
            variant="h3"
            fontWeight="bold"
            sx={{ color: colors.grey[900], textAlign: "right" }}
          >
            ${totalPayout.toFixed(2).toString()}
            
          </Typography>
        </Box>
      
      <Typography textAlign="right">
        --------------------------------
      </Typography>
      <Box display="flex" justifyContent="space-between" mt="2px">
        <Typography variant="h5" sx={{ color: colors.grey[1300] }}>
          Current Balance
        </Typography>
        <Typography
          variant="h3"
          fontWeight="bold"
          sx={{ color: colors.greenAccent[1000], textAlign: "right" }}
        >
          ${(Number(walletBalance) - totalPayout).toFixed(2)}
        </Typography>
      </Box>
    </Box>
    //this is a test
  );
};

export default CashingStatBox;
