import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EnrolleeContext } from "../../store/enrolle-context";
import { SalesContext } from "../../store/sales-context";
import UserContext from "../../store/user-context";
import RetailStoreContext from "../../store/retail-store-context";
import { Box, useTheme, Button } from "@mui/material";
import PaypalPayment from "../../components/payment/PaypalPayment";
import PaymongoPayment from "../../components/payment/PaymongoPayment";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import Loading from "../../components/ui/RingLoader";
import { Link } from "react-router-dom";
import {
  createPaymentIntent,
  createPaymentMethod,
  attachPaymentMethod,
  checkStatus,
  clearStorage,
} from "../../utils/paymongo";
import { dollarPesoConversion } from "../../utils/dollar-peso-conversion";

const Payment = () => {
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enrollee } = useContext(EnrolleeContext);
  const { sale } = useContext(SalesContext);
  const { user } = useContext(UserContext);
  const { retail } = useContext(RetailStoreContext);
  const [isPhil, setIsPhil] = useState<boolean>(false);
  const [pesoDollarRate, setPesoDollarRate] = useState<number>(0);

  const navigate = useNavigate();
  const handlePayment = () => {
    setIsPhil((prev) => !prev);
  };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const payMongo = async (data: any) => {
    setIsLoading(true);

    let mongoData = {};
    let paymentGateWay = "";
    if (data.paymentType === "eWallet") {
      mongoData = {
        data: {
          attributes: {
            type: data.eWallet,
            billing: {
              name: data.name,
              email: data.email,
              phone: data.contact,
            },
          },
        },
      };
      paymentGateWay = `paymongo-eWallet-${data.eWallet}`;
    } else {
      mongoData = {
        data: {
          attributes: {
            type: "card",
            details: {
              card_number: data.cardNo,
              exp_month: parseInt(data.expMo),
              exp_year: parseInt(data.expYr),
              cvc: data.cvv,
              // bank_code: "test_bank_one",
            },
            billing: {
              address: {
                line1: data.address1,
                line2: data.address2,
                city: data.city,
                postal_code: data.postal_code,
                country: data.country.toUpperCase(),
              },
              name: data.name,
              email: data.email,
              phone: data.contact,
            },
          },
        },
      };
      paymentGateWay = `paymongo-credit-card`;
    }
    let status = "";
    let redirectUrl = "";
    let clientKey = "";
    try {
      //create payment intent
      
      const createIntentResponse = await createPaymentIntent(
        data.totalAmount,
        data.user
      );
      const sPaymentIntentId = createIntentResponse.data.id;
      localStorage.setItem("paymentIntentId", sPaymentIntentId);
      const sClientKey = createIntentResponse.data.attributes.client_key;
      localStorage.setItem("clientKey", sClientKey);

      //create payment method
      const createPaymentMethodResponse = await createPaymentMethod(mongoData);
      const sPaymentMethodId = createPaymentMethodResponse.data.id;
      localStorage.setItem("paymentMethodId", sPaymentMethodId);

      //attach payment method to payment intent
      const attachResponse = await attachPaymentMethod(
        sPaymentIntentId,
        sPaymentMethodId,
        sClientKey
      );

      status = attachResponse.data.attributes.status;
      if (status === "awaiting_next_action") {
        redirectUrl = attachResponse.data.attributes.next_action.redirect.url;
      }
      clientKey = sClientKey;
    } catch (error) {
      console.log(error);
      return navigate("/error/enrollee");
    }

    try {
      if (status === "awaiting_next_action") {
        if (JSON.stringify(enrollee) === "{}") {
          sale.paymentGateWay = paymentGateWay;
          sale.transactionId = `client-key-${clientKey}`;
          sale.transactionEmail = data.email;
        } else {
          enrollee.paymentGateWay = paymentGateWay;
          enrollee.transactionId = `client-key-${clientKey}`;
          enrollee.transactionEmail = data.email;
        }
        localStorage.setItem("enrollee", JSON.stringify(enrollee));
        localStorage.setItem("sale", JSON.stringify(sale));
        localStorage.setItem("user", JSON.stringify(user));

        if(Object.keys(retail).length > 0){ // for retail
          retail.paymentGateWay = paymentGateWay;
          retail.transactionId = `client-key-${clientKey}`;
          retail.transactionEmail = data.email;
          retail.storeId = id ? id : null;
          localStorage.setItem("retail",JSON.stringify(retail));
        }else{
          localStorage.setItem("retail","{}");
        }
        window.location.href = redirectUrl;
      } else if (status === "succeeded") {
        // localStorage.removeItem("paymentMethodId");
        // localStorage.removeItem("clientKey");
        // localStorage.removeItem("paymentIntentId");
        
        clearStorage();
        if (JSON.stringify(enrollee) === "{}") {
          sale.paymentGateWay = paymentGateWay;
          sale.transactionId = `client-key-${clientKey}`;
          sale.transactionEmail = data.email;
          return navigate("/decider/upgrade");
        } else {
          enrollee.paymentGateWay = paymentGateWay;
          enrollee.transactionId = `client-key-${clientKey}`;
          enrollee.transactionEmail = data.email;
          return navigate("/decider/enrollee");
        }
       
        
      } else if (status === "awaiting_payment_method") {
        clearStorage();
        return navigate("/error/enrollee");
      } else if (status === "processing") {
        const checkTransStatus = async () => {
          const transitoryStatus = await checkStatus();
          const statusX = transitoryStatus.data.attributes.status;
          if (statusX === "succeeded") {
            // localStorage.removeItem("paymentMethodId");
            // localStorage.removeItem("clientKey");
            // localStorage.removeItem("paymentIntentId");
            clearStorage();
            if (JSON.stringify(enrollee) === "{}") {
              sale.paymentGateWay = paymentGateWay;
              sale.transactionId = `client-key-${clientKey}`;
              sale.transactionEmail = data.email;
              return navigate("/decider/upgrade");
            } else {
              enrollee.paymentGateWay = paymentGateWay;
              enrollee.transactionId = `client-key-${clientKey}`;
              enrollee.transactionEmail = data.email;
              return navigate("/decider/enrollee");
            }
          } else if (statusX === "awaiting_payment_method") {
            clearStorage();
            return navigate("/error/enrollee");
          }
        };
        let delayTimer = setTimeout(checkTransStatus, 2000);
        setTimeout(() => {
          clearTimeout(delayTimer);
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      return navigate("/error/enrollee");
    }
    setIsLoading(false);
  };
  useEffect(() => {
    const conversion = async () => {
      // const response = await fetch(
      //   `https://api.fastforex.io/fetch-one?from=USD&to=PHP&api_key=${process.env.REACT_APP_FOREIGN_EXCHANGE_KEY}`
      // );
      // const jsonResponse = await response.json();
      // setPesoDollarRate(jsonResponse.result.PHP);
      const dollarToPesos = await dollarPesoConversion();
      setPesoDollarRate(dollarToPesos);
    };
    conversion();
  }, []);

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="PAYMENT"
          subtitle="Pay enrollment, package upgrade, or buy a product."
        />
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gridTemplateRows="auto auto 1fr 1fr 1fr auto auto"
        gap="20px"
      >
        <Box
          gridColumn={{ xs: "span 12", md: "span 12" }}
          bgcolor={colors.primary[400]}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          borderRadius="8px"
          paddingTop="20px"
        >
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {!isPhil && (
                <>
                  {retail.storeType !== "retail" &&
                  retail.storeType !== "retail-enroll" ? (
                    JSON.stringify(enrollee) === "{}" ? (
                      <PaypalPayment payer={sale} />
                    ) : (
                      <PaypalPayment payer={enrollee} />
                    )
                  ) : (
                    <PaypalPayment
                      payer={{
                        totalAmount: retail.totalAmount,
                        storeId: id ? id : null,
                      }}
                    />
                  )}
                </>
              )}

              <Button
                type="button"
                color="info"
                variant="outlined"
                sx={{
                  marginBottom: "16px",
                }}
                onClick={handlePayment}
              >
                {isPhil ? "Go to Paypal Payment" : "Go to Philippine Payment"}
              </Button>

              {isPhil && (
                <>
                  {/* {JSON.stringify(enrollee) === "{}" ? (
                    <PaymongoPayment
                      pay={payMongo}
                      totalAmount={parseInt(sale.totalAmount) * pesoDollarRate}
                      firstName={sale.firstName}
                      lastName={sale.lastName}
                    />
                  ) : (
                    <PaymongoPayment
                      pay={payMongo}
                      totalAmount={
                        parseInt(enrollee.totalAmount) * pesoDollarRate
                      }
                      firstName={enrollee.firstName}
                      lastName={enrollee.lastName}
                    />
                  )} */}
                  {retail.storeType !== "retail" &&
                  retail.storeType !== "retail-enroll" ? (
                    JSON.stringify(enrollee) === "{}" ? (
                      <PaymongoPayment
                        pay={payMongo}
                        totalAmount={
                          parseInt(sale.totalAmount) * pesoDollarRate
                        }
                        firstName={sale.firstName}
                        lastName={sale.lastName}
                      />
                    ) : (
                      <PaymongoPayment
                        pay={payMongo}
                        totalAmount={
                          parseInt(enrollee.totalAmount) * pesoDollarRate
                        }
                        firstName={enrollee.firstName}
                        lastName={enrollee.lastName}
                      />
                    )
                  ) : (
                    <PaymongoPayment
                      pay={payMongo}
                      totalAmount={retail.totalAmount * pesoDollarRate}
                      firstName="ronel"
                      lastName="andaya"
                    />
                  )}
                </>
              )}
              {retail.storeType === "retail" && !isPhil && (
                <Box sx={{ marginBottom: "16px" }}>
                  <Link to="/retailStore/buy">
                    <Button variant="contained" className="multiStepButton">
                      Cancel
                    </Button>
                  </Link>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Payment;
