import { useState, useContext } from "react";
import UserContext from "../../store/user-context";
import NodeContext from "../../store/node-context";
import {
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../Header";
import Loading from "../ui/RingLoader";
import { useNavigate } from "react-router-dom";
import "./LoginForm.css";
import { userObj, getUserDetails } from "../../utils/user-object";

type FormValues = {
  emailOrUserId: string;
  password: string;
};

const initialValues = {
  emailOrUserId: "",
  password: "",
};

const userSchema = yup.object().shape({
  emailOrUserId: yup.string().required("required"),
  password: yup.string().required("required"),
});

const Maintenance = () => {
  const navigate = useNavigate();

  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [loading, setLoading] = useState<boolean>(false);
  const [credentials, setCredentials] = useState<boolean>(true);
  const { user, setUser } = useContext(UserContext);
  const { setNodeId } = useContext(NodeContext);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleFormSubmit = async (values: FormValues) => {
    setLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/users/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: values.emailOrUserId,
          password: values.password,
        }),
      }
    );

    const jsonResponse = await response.json();
    if (typeof jsonResponse.access !== "undefined") {
      localStorage.setItem("token", jsonResponse.access);
      retrieveUserDetails();
    } else {
      setCredentials(false);
      setTimeout(() => {
        setCredentials(true);
      }, 4000);
    }
    setLoading(false);
  };

  const retrieveUserDetails = async () => {
    const jsonResponse = await getUserDetails();
    setUser(userObj(jsonResponse));
    setNodeId(jsonResponse._id);

    if (jsonResponse.isAdmin) {
      navigate("/");
    } else {
      navigate("/user");
    }
  };
  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Box className="logoTopLogin">
        <img src="../../assets/logoMountain.png" alt="Logo" />
      </Box>
      <Box>{/* <Header title="LOGIN" subtitle="Login Profile" /> */}</Box>
      {loading ? (
        <Loading />
      ) : (
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={userSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4,minmax(0,1fr))"
                sx={{
                  "& > div": {
                    gridColumn: isNonMobile ? undefined : "span 12",
                  },
                }}
              >
                {/* <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Email or User ID"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.emailOrUserId}
                  name="emailOrUserId"
                  error={!!touched.emailOrUserId && !!errors.emailOrUserId}
                  helperText={touched.emailOrUserId && errors.emailOrUserId}
                  sx={{
                    gridColumn: "span 12",
                  }}
                /> */}

                {/* <TextField
                  fullWidth
                  variant="filled"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleToggleShowPassword}>
                          <Checkbox
                            checked={showPassword}
                            icon={<VisibilityOff />}
                            checkedIcon={<Visibility />}
                            inputProps={{
                              "aria-label": "toggle password visibility",
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  label="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  error={!!touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                  sx={{
                    gridColumn: "span 12",
                  }}
                /> */}
              </Box>
              
                <Box sx={{ paddingTop: "10px" }}>
                  <Typography variant="h3" fontWeight="400" color="red" textAlign={"center"}>
                    Website Under Maintenance
                  </Typography>
                </Box>
              

              {/* <Box display="flex" justifyContent="center" mt="20px">
                <Button
                  type="submit"
                  className="loginButton"
                  sx={{
                    backgroundColor: "#0081ff",
                    ":hover": { bgcolor: "#0067CC" },
                    fontWeight: "700",
                  }}
                  variant="contained"
                >
                  Login
                </Button>
              </Box> */}
            </form>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default Maintenance;
