import { useState, useContext, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import UserContext from "../../store/user-context";

import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, Button } from "@mui/material";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import LinkIcon from "@mui/icons-material/Link";
// import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
// import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
// import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
// import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
// import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
// import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
// import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
// import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import AssessmentIcon from "@mui/icons-material/Assessment";
import PasswordIcon from "@mui/icons-material/Password";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import FastForwardIcon from "@mui/icons-material/FastForward";
import WalletIcon from "@mui/icons-material/Wallet";
import ActiveCounter from "../../components/ActiveCounter";
import "./Sidenav.css";
import useDateDiff from "../../hooks/use-date-difference";
import LAClock from "../../components/LAClock";
// import Swal from "sweetalert2";

const Item: React.FC<{
  title: string;
  to: string;
  icon: JSX.Element;
  selected: string;
  setSelected: (value: string) => void;
}> = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={props.selected === props.title}
      style={{
        color: colors.grey[1300],
      }}
      onClick={() => props.setSelected(props.title)}
      icon={props.icon}
    >
      <Typography>{props.title}</Typography>
      <Link to={props.to} />
    </MenuItem>
  );
};

const Sidenav = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const location = useLocation();
  let path = location.pathname.split("/");
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const { user } = useContext(UserContext);

  const handleUpgrade = () => {
    navigate("/store");
  };

  const activeDays = useDateDiff({
    activeUntil: user.activeUntil,
    isAdmin: user.isAdmin,
  });

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          // color: "#868dfb !important",
          color: `${colors.grey[900]} !important`,
        },
        "& .pro-menu-item.active": {
          // color: "#6870fa !important",
          color: `${colors.grey[900]}!important`,
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}></Typography>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  // sx={{ pl: "45px" }}
                >
                  {/* <img
                    alt="profile-user"
                    height="50px"
                    src={ path[1] === 'addedituser' ? '/../../../assets/hermonLogo.png':'/../../assets/hermonLogo.png'}
                    style={{ cursor: "pointer" }}
                  /> */}
                </Box>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <LAClock />

              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={
                    path[1] === "addedituser"
                      ? "/../../../assets/default_avatar.png"
                      : "/../../assets/default_avatar.png"
                  }
                  // src="/../../assets/boy.png"
                  style={{ cursor: "pointer" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h4"
                  color={colors.grey[100]}
                  fontWeight="100"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {user.firstName} {user.lastName}
                </Typography>
                <Typography variant="h5" color={colors.grey[1300]}>
                  {user.username}
                </Typography>
                {user.currentPackage !== "Legacy" &&
                  user.currentPackage !== "Retailer" &&
                  !user.isAdmin && (
                    <Button
                      sx={{
                        bgcolor: "#0081FF",
                        paddingX: "50px",
                        font: "inherit",
                        fontWeight: "600",
                        marginTop: "10px",
                        color: "white",
                        ":hover": { bgcolor: "#0067CC" },
                      }}
                      onClick={handleUpgrade}
                    >
                      Upgrade
                    </Button>
                  )}
                {/* <Button
                  sx={{
                    bgcolor: "#0081FF",
                    paddingX: "50px",
                    font: "inherit",
                    fontWeight: "600",
                    marginTop: "10px",
                    color: "white",
                    ":hover": { bgcolor: "#0067CC" },
                  }}
                  onClick={handlePaymonggo}
                >
                  PayMonggo
                </Button> */}
              </Box>
              {!user.isAdmin && user.currentPackage !== "Retailer" && (
                <ActiveCounter activeDays={activeDays} />
              )}
            </Box>
          )}

          <Box
            paddingLeft={isCollapsed ? undefined : "10%"}
            height={isCollapsed ? "90vh" : "70vh"}
          >
            {user.isAdmin && (
              <Item
                title="Admin Dashboard"
                to="/"
                icon={<HomeOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}

            <Item
              title="User Dashboard"
              to="/user"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {user.currentPackage !== "Retailer" && (
              <Item
                title="Members"
                to="/members"
                icon={<PeopleOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            {user.currentPackage !== "Retailer" && (
              <Item
                title="My Organization"
                to="/organization"
                icon={<CorporateFareIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            <Item
              title="Bonus & Commission"
              to="/ftc"
              icon={<FastForwardIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {user.currentPackage === "Legacy" && !user.isAdmin && (
              <Item
                title="MLM Store"
                to="/store"
                icon={<LocalGroceryStoreIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            {user.currentPackage === "Legacy" && !user.isAdmin && (
              <Item
                title="Retail Store"
                to="/insideRetailStore"
                icon={<ShoppingBagIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}

            <Item
              title="Sales Report(Retail)"
              to="/salesReportRetail"
              icon={<AssessmentIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {user.currentPackage !== "Retailer" && (
              <Item
                title="Wallet"
                to="/wallet"
                icon={<WalletIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            <Item
              title="Retailer Link"
              to="/retailerLink"
              icon={<LinkIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="library"
              to="/library"
              icon={<LibraryBooksIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Change Password"
              to="/changepassword"
              icon={<PasswordIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {user.isAdmin && (
              <div>
                {/* <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  Data
                </Typography> */}
                {/* <Item
                  title="Contacts Information"
                  to="/contacts"
                  icon={<ContactsOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                /> */}
                {/* <Item
                  title="Invoices Balances"
                  to="/invoices"
                  icon={<ReceiptOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                /> */}

                {/* <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  Pages
                </Typography> */}
                {/* <Item
                  title="Profile Form"
                  to="/form"
                  icon={<PersonOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                /> */}
                {/* <Item
                  title="Scheduler"
                  to="/calendar"
                  icon={<CalendarTodayOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                /> */}
                {/* <Item
                  title="FAQ Page"
                  to="/faq"
                  icon={<HelpOutlineOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                /> */}

                {/* <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  Others
                </Typography> */}
                {/* <Item
                  title="Sales Bar Chart"
                  to="/bar"
                  icon={<BarChartOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                /> */}
                {/* <Item
                  title="Sales Pie Chart"
                  to="/pie"
                  icon={<PieChartOutlineOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                /> */}
                {/* <Item
                  title="Sales Line Chart"
                  to="/line"
                  icon={<TimelineOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                /> */}
              </div>
            )}
            {/* <hr className="horizontalLine"/> */}
            <div className="horizontalLine"></div>
            <Box sx={{ paddingTop: "10px" }}>
              <Item
                title="Logout"
                to="/logout"
                icon={<LogoutIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            </Box>
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidenav;
