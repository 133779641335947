const setGoodLifeRank = (rank: string) => {
    let rankName = '';
    if(rank === "1"){
        rankName = "Emerald";
    }else if(rank === "2"){
        rankName = "Ruby";
    }else if(rank === "3"){
        rankName = "Sapphire";
    }else if(rank === "4"){
        rankName = "Diamond";
    }else if(rank === "5"){
        rankName = "Blue Diamond";
    }else if(rank === "6"){
        rankName = "Black Diamond";
    }else if(rank === "7"){
        rankName = "Crown";
    }else if(rank === "8"){
        rankName = "Crown Diamond";
    }else if(rank === "9"){
        rankName = "Royal Crown";
    }
    return rankName;
}
const useCurrentRank = (currentPackage: string, goodLifeRank: string): string => {
   let currentRank = '';
    if(currentPackage === 'Starter'){
        currentRank = 'Starter'
    }else if(currentPackage === 'Business'){
        currentRank = 'Bronze'
    }else if(currentPackage === 'Premiere'){
        currentRank = 'Silver'
    }else if(currentPackage === 'Achiever'){
        currentRank = 'Gold'
    }else if(currentPackage === 'Retailer'){
        currentRank = "Retailer"
    }else if(currentPackage === 'Legacy'){
        if(goodLifeRank){
            currentRank = setGoodLifeRank(goodLifeRank);
        }else{
            currentRank = 'Platinum'
        }
    }
    return (currentRank);
  };
  
  export default useCurrentRank;
  