import { useEffect, useState, useContext } from "react";
import { Box, useTheme, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import "./Wallet.css";
import Loading from "../../components/ui/RingLoader";
import WithdrawList from "../../components/wallet/WithdrawyList";
import WalletSummary from "../../components/wallet/WalletSummary";
import UserContext from "../../store/user-context";

export const Wallet = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useContext(UserContext);
  const [list, setList] = useState<any>([]);
  const [loading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    setIsLoading(true);
    if (localStorage.getItem("token")) {
      async function fetchData() {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/payout`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        const jsonResponse = await response.json();
        setList(jsonResponse);
        console.log(jsonResponse);
        setIsLoading(false);
      }
      fetchData();
    }
  }, []);

  return (
    <Box m="20px">
      <Header title="WALLET" subtitle="Monitor and withdraw your earnings" />
      {/* Summary */}
      <WalletSummary
        wallet={{
          walletBalance: user.walletBalance,
          totalPayout: user.totalPayout,
          currentBalance: ((user as {walletBalance: number}).walletBalance - (user as {totalPayout: number}).totalPayout),
          pendingRequest: user.pendingWithdrawalRequest,
          transferredFundsTotal: (user as {transferredFundsTotal: number}).transferredFundsTotal,
        }}
          />
      {/* End Summary  */}
      {loading && <Loading />}
      <WithdrawList widthdrawList={list} />
    </Box>
  );
};

export default Wallet;
