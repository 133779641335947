import { useContext, useRef, useState } from "react";
import UserContext from "../../store/user-context";
import { Box, Typography, useTheme, Button } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";

const RetailLink = () => {
  const linkRef = useRef<HTMLElement>(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useContext(UserContext);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const copyToClipboard = () => {
    const textToCopy = linkRef.current?.textContent ?? "";
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setIsCopied(true);
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
      });
  };
  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="RETAILER LINK"
          subtitle="Share your link and earn money"
        />
      </Box>
      <Box
        display="grid"
      >
        <Box
          gridColumn={{ xs: "span 12", md: "span 12" }}
          bgcolor={colors.primary[400]}
          display="flex"
          flexDirection={"column"}
          alignItems="center"
          justifyContent="center"
          borderRadius="8px"
          paddingX="40px"
          height="100px"
        >
          <Typography variant="h4" fontWeight="300" ref={linkRef} textAlign={"center"}>
            https://www.hermoninternational.com/home?referralID=
            {user.username} 
            {/* https://localhost:3001/retailStore/{user.username} */}
          </Typography>
          <Button type="submit" variant="contained" className="multiStepButton" onClick={copyToClipboard} sx={{ marginTop:"16px" }}>
            {isCopied ? "COPIED" : "COPY TO CLIPBOARD"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default RetailLink;
