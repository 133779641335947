import moment from "moment";

export const setLaTime = () => {
  const timeZone = "America/Los_Angeles";
  const now = new Date();
  const laTime = moment.tz(now, timeZone);
  return laTime;
};

const useDateDiff = (props: any): number => {
  let activeDays = 0;
  const LADate = setLaTime().format("YYYY-MM-DD");
  const activeUntil = props.activeUntil;

  if (!props.isAdmin && activeUntil >= LADate) {
    const date1 = moment(activeUntil);
    const date2 = moment(LADate);
    return (activeDays = date1.diff(date2, "days") + 1);
  }
  return activeDays;
};

export default useDateDiff;
