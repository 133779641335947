import React, { useState, useEffect } from "react";
import { LATime } from "../utils/LA-time";
import { Box, Typography } from "@mui/material";
import moment from 'moment-timezone';
const LAClock = () => {
  const [time, setTime] = useState<string>("");
  const [LADay, setLADay] = useState<string>("");
  useEffect(() => {
    const intervalId = setInterval(() => {
    const nd = moment().tz('America/Los_Angeles').format("MMM D, YYYY h:mm:ssA")
      // console.log(nd);
      setTime(nd);
      setLADay(
        // new Date(time.toLocaleString().slice(0, 10)).toLocaleDateString(
        //   "en-US",
        //   { month: "short", day: "numeric", year: "numeric" }
        
        // )
        moment().tz('America/Los_Angeles').format("MMM D, YYYY h:mm:ssA")
      );
    }, 1000);

    return () => clearInterval(intervalId);
  }, [time]);

  return (
    <Box
      sx={{ alignItems: "center", display: "flex", flexDirection: "column" }}
    >
      <Typography>Los Angeles USA Time</Typography>
      <Typography>{LADay.slice(0,12)}</Typography>
      <Typography>{time.slice(12,23)}</Typography>
    </Box>
  );
};

export default LAClock;
