import { useState, useEffect } from "react";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route, useLocation, useNavigate, Link } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidenav from "./scenes/global/Sidenav";
import Dashboard from "./scenes/dashboard/Dashboard";
import UserDashboard from "./scenes/dashboard/UserDashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import Members from "./scenes/members/Members";
import Login from "./scenes/auth/Login";
import Logout from "./components/Logout";
import Success from "./scenes/final-landing/Success";
import Error from "./scenes/final-landing/Error";
import Decider from "./scenes/final-landing/Decider";
import Store from "./scenes/store/Store";
import FastTrackCommission from "./scenes/fast-track-commission/FastTrackCommission";
import PendingFT from "./components/commissions/PendingFT";
import Processing from "./scenes/paymongo/Processing";

import Invoices from "./scenes/invoices/Invoices";
import Contacts from "./scenes/contacts/Contacts";
import Payment from "./scenes/payment/Payment";
import Bar from "./scenes/bar/Bar";
import Form from "./scenes/form/Form";
import Line from "./scenes/line/Line";
import Pie from "./scenes/pie/Pie";
import FAQ from "./scenes/faq/Faq";
import Geography from "./scenes/geography/Geography";
import Calendar from "./scenes/calendar/Calendar";
import AppContext from "./store/app-context";
import UserContext from "./store/user-context";
import { RegionContext } from "./store/region-context";
import { ProvinceContext } from "./store/province-context";
import { CityContext } from "./store/city-context";
import { BarangayContext } from "./store/barangay-context";
import { HermonPackageContext } from "./store/hermon-package-context";
import NodeContext from "./store/node-context";
import { CountryContext } from "./store/country-context";
import { EnrolleeContext } from "./store/enrolle-context";
import { PackageContext } from "./store/package-context";
import { SalesContext } from "./store/sales-context";
import RetailStoreContext from "./store/retail-store-context";
import { IUser } from "./types/user-types";
import Organization from "./scenes/organization/Organization";
import Library from "./scenes/library/Library";
import AddEditUser from "./scenes/add-edit-user/AddEditUser";
import Announcement from "./scenes/global/Announcement";
import ChangePassword from "./scenes/password/ChangePassword";
import Wallet from "./scenes/wallet/Wallet";
import { userObj, getUserDetails } from "./utils/user-object";
import Withdraw from "./scenes/wallet/Withdraw";
import Transfer from "./scenes/wallet/Transfer";
import RetailStore from "./scenes/retail-store/RetailStore";
import RetailStoreDecider from "./scenes/retail-store/RetailStoreDecider";
import RetailLink from "./scenes/retail-store/RetailLink";
import SalesReportRetail from "./scenes/sales-report-retail/SalesReportRetail";


function App() {
  const { colorMode, theme } = useMode();
  const [currentRoute, setCurrentRoute] = useState<string>(
    window.location.pathname
  );
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState<IUser>({
    id: "",
    isAdmin: false,
    firstName: "",
    lastName: "",
    gender: "",
    username: "",
    currentPackage: "",
    goodLifeRank: "",
    country: "",
    shippingCountry: "",
    shippingAddress: "",
    lastPurchased: {},
    dateNow: "",
    currentVolume: "",
    hasPendingFTComm: false,
    walletBalance: "",
    totalPayout: 0,
    pendingWithdrawalRequest: 0,
    transferredFundsTotal: 0,
  });
  console.log("user obj");
  console.log(user);
  const [nodeId, setNodeId] = useState<string>("");
  const [regionCode, setRegionCode] = useState<string>("");
  const [provinceCode, setProvinceCode] = useState<string>("");
  const [cityCode, setCityCode] = useState<string>("");
  const [barangayCode, setBarangayCode] = useState<string>("");
  const [countryCode, setCountryCode] = useState<string>("");

  const [hermonPackage, setHermonPackage] = useState<string>("");
  const [enrollee, setEnrollee] = useState<any>({});
  const [currentPackage, setCurrentPackage] = useState<number>(0);
  const [sale, setSale] = useState<any>({});
  const [retail, setRetail] = useState<any>({});

  const [shippingFee, setShippingFee] = useState<any>("0.00");
  const [lastActive, setLastActive] = useState<any>(new Date());
  const inactivityTimeOut = 5 * 60 * 1000; //5 minutes

  useEffect(() => {
    const handleRouteChange = (route: string) => {
      setCurrentRoute(route);
    };
    handleRouteChange(currentRoute);
  }, [currentRoute]);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      async function fetchData() {
        const jsonResponse = await getUserDetails();
        if (typeof jsonResponse._id !== "undefined") {
          setUser(userObj(jsonResponse));
          setNodeId(jsonResponse._id);
        }
      }
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      async function fetchCurrentPackage() {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/sale/individualPurchase`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              userId: user.id,
            }),
          }
        );

        const jsonResponse = await response.json();
        console.log(jsonResponse);
        setCurrentPackage(jsonResponse.total);
      }
      fetchCurrentPackage();
    }
  }, [user.id]);

  useEffect(() => {
    document.addEventListener("click", () => {
      setLastActive(new Date());
    });

    document.addEventListener("keypress", () => {
      setLastActive(new Date());
    });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date().getTime();
      const timeSinceLastActivity = now - lastActive;
      if (
        timeSinceLastActivity > inactivityTimeOut &&
        localStorage.getItem("token")
      ) {
        navigate("/logout");
        clearInterval(intervalId);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [lastActive, inactivityTimeOut]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <AppContext.Provider
            value={{
              classIsActive: "",
              setClassIsActive: () => {},
            }}
          >
            <UserContext.Provider value={{ user, setUser }}>
              <NodeContext.Provider value={{ nodeId, setNodeId }}>
                <RegionContext.Provider value={{ regionCode, setRegionCode }}>
                  <ProvinceContext.Provider
                    value={{ provinceCode, setProvinceCode }}
                  >
                    <CityContext.Provider value={{ cityCode, setCityCode }}>
                      <BarangayContext.Provider
                        value={{ barangayCode, setBarangayCode }}
                      >
                        <CountryContext.Provider
                          value={{
                            countryCode,
                            setCountryCode,
                          }}
                        >
                          <HermonPackageContext.Provider
                            value={{ hermonPackage, setHermonPackage }}
                          >
                            <EnrolleeContext.Provider
                              value={{
                                enrollee,
                                setEnrollee,
                              }}
                            >
                              <PackageContext.Provider
                                value={{ currentPackage, setCurrentPackage }}
                              >
                                <SalesContext.Provider
                                  value={{
                                    sale,
                                    setSale,
                                    shippingFee,
                                    setShippingFee,
                                  }}
                                >
                                  <RetailStoreContext.Provider
                                    value={{
                                      retail,
                                      setRetail,
                                    }}
                                  >
                                    {localStorage.getItem("token") && (
                                      <Sidenav />
                                    )}

                                    <main className="content">
                                      {localStorage.getItem("token") && (
                                        <Topbar />
                                      )}
                                      {/* {localStorage.getItem("token") && (
                                      <Announcement />
                                    )} */}
                                      {localStorage.getItem("token") &&
                                        user.hasPendingFTComm && <PendingFT />}
                                      <Routes>
                                        <Route
                                          path="/login"
                                          element={<Login />}
                                        />
                                        <Route
                                          path="/logout"
                                          element={<Logout />}
                                        />
                                        <Route
                                          path="/"
                                          element={
                                            <ProtectedRoute>
                                              <Dashboard />
                                            </ProtectedRoute>
                                          }
                                        />
                                        <Route
                                          path="/user"
                                          element={
                                            <ProtectedRoute>
                                              <UserDashboard />
                                            </ProtectedRoute>
                                          }
                                        />
                                        <Route
                                          path="/members"
                                          element={
                                            <ProtectedRoute>
                                              <Members />
                                            </ProtectedRoute>
                                          }
                                        />
                                        <Route
                                          path="/ftc"
                                          element={
                                            <ProtectedRoute>
                                              <FastTrackCommission />
                                            </ProtectedRoute>
                                          }
                                        />
                                        <Route
                                          path="/addedituser/:upName/:position/:sponsorUserName"
                                          element={<AddEditUser />}
                                        />
                                        <Route
                                          path="/package/payment"
                                          element={<Payment />}
                                        />
                                        <Route
                                          path="/package/payment/:id"
                                          element={<Payment />}
                                        />
                                        <Route
                                          path="/store"
                                          element={<Store />}
                                        />
                                        <Route
                                          path="/insideRetailStore"
                                          element={<RetailStore />}
                                        />
                                         <Route
                                          path="/retailerLink"
                                          element={
                                            <ProtectedRoute>
                                              <RetailLink />
                                            </ProtectedRoute>
                                          }
                                        />
                                        <Route
                                          path="/retailStore"
                                           element={<RetailStoreDecider />}
                                        />
                                        
                                        <Route
                                          path="/retailStore/:id"
                                          element={<RetailStoreDecider />}
                                        />
                                        <Route
                                          path="/retailStore/buy"
                                          // element={<RetailStore />}
                                          element={<Login />}
                                        />
                                        <Route
                                          path="/retailStore/buy/:id"
                                          // element={<RetailStore />}
                                          element={<Login />}

                                        />
                                        <Route
                                          path="/retailStore/enroll"
                                          // element={<RetailStore />}
                                          element={<Login />}

                                        />
                                        <Route
                                          path="/retailStore/enroll/:id"
                                          // element={<RetailStore />}
                                          element={<Login />}

                                        />
                                        <Route
                                          path="/salesReportRetail"
                                          element={
                                            <ProtectedRoute>
                                              <SalesReportRetail />
                                            </ProtectedRoute>
                                          }
                                        />
                                        <Route
                                          path="/success/:type"
                                          element={<Success />}
                                        />
                                        <Route
                                          path="/error/:type"
                                          element={<Error />}
                                        />
                                        <Route
                                          path="/decider/:type"
                                          element={<Decider />}
                                        />
                                        <Route
                                          path="/paymongo/processing"
                                          element={<Processing />}
                                        />
                                        <Route
                                          path="/organization"
                                          element={
                                            <ProtectedRoute>
                                              <Organization />
                                            </ProtectedRoute>
                                          }
                                        />
                                        <Route
                                          path="/library"
                                          element={<Library />}
                                        />
                                        <Route
                                          path="/changepassword"
                                          element={
                                            <ProtectedRoute>
                                              <ChangePassword />
                                            </ProtectedRoute>
                                          }
                                        />
                                        <Route
                                          path="/wallet"
                                          element={
                                            <ProtectedRoute>
                                              <Wallet />
                                            </ProtectedRoute>
                                          }
                                        />
                                        <Route
                                          path="/withdraw"
                                          element={
                                            <ProtectedRoute>
                                              <Withdraw />
                                            </ProtectedRoute>
                                          }
                                        />
                                        <Route
                                          path="/transfer"
                                          element={
                                            <ProtectedRoute>
                                              <Transfer />
                                            </ProtectedRoute>
                                          }
                                        />
                                        <Route
                                          path="/contacts"
                                          element={<Contacts />}
                                        />
                                        <Route
                                          path="/invoices"
                                          element={<Invoices />}
                                        />

                                        <Route
                                          path="/form"
                                          element={<Form />}
                                        />
                                        <Route path="/bar" element={<Bar />} />
                                        <Route path="/pie" element={<Pie />} />
                                        <Route
                                          path="/line"
                                          element={<Line />}
                                        />
                                        <Route path="/faq" element={<FAQ />} />
                                        <Route
                                          path="/geography"
                                          element={<Geography />}
                                        />
                                        <Route
                                          path="/calendar"
                                          element={<Calendar />}
                                        />
                                      </Routes>
                                    </main>
                                  </RetailStoreContext.Provider>
                                </SalesContext.Provider>
                              </PackageContext.Provider>
                            </EnrolleeContext.Provider>
                          </HermonPackageContext.Provider>
                        </CountryContext.Provider>
                      </BarangayContext.Provider>
                    </CityContext.Provider>
                  </ProvinceContext.Provider>
                </RegionContext.Provider>
              </NodeContext.Provider>
            </UserContext.Provider>
          </AppContext.Provider>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
//hello world
export default App;
