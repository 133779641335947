import { useEffect, useState, useContext } from "react";
import UserContext from "../../store/user-context";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
// import "./Organization.css";
import Loading from "../../components/ui/RingLoader";

export const FastTrackCommission = () => {
  const [token, setToken] = useState<any>(localStorage.getItem("token"))
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [ftc, setFtc] = useState<any>([]);
  const [loading, setIsLoading] = useState<boolean>(false);
  const { user } = useContext(UserContext);
  console.log('user');
  console.log(user);
  useEffect(() => {
    setIsLoading(true);
    if (token) {
      console.log('token');
      console.log(token);
      async function fetchData() {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/commissions/ft`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            }
          }
        );

        const jsonResponse = await response.json();
        setFtc(jsonResponse);
        console.log('fast track commission')
        console.log(jsonResponse);
        setIsLoading(false);
      }
      fetchData();
    }
  }, [user.id, token]);
  const columns = [
    {
      field: "id",
      headerName: "Transaction ID",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
    },
    {
        field: "date",
        headerName: "Date Received",
        flex: 1,
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
      }
    // {
    //   field: "sponsor",
    //   headerName: "Sponsor",
    //   flex: 1,
    // },
    // {
    //   field: "upLine",
    //   headerName: "Upline",
    //   flex: 1,
    // },
    // {
    //   field: "position",
    //   headerName: "Binary Position",
    // },
  ];
  interface ft {
    _id: string;
    description: string;
    date: string;
    status: string;
  }
  return (
    <Box m="20px">
      <Header
        title="COMMISSIONS & BONUS"
        subtitle="Collection of commissions and bonuses"
      />

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none", //outer border
          },
          "& .MuiDataGrid-cell": {
            
            borderX: `0.5px solid ${colors.grey[800]}`,
          },
          "& .name-column--cell": {
            
            color: "#de9728",
          },
          "& .MuiDataGrid-columnHeaders": {
            
            backgroundColor: `${colors.grey[800]}`,
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            // backgroundColor: colors.blueAccent[700],
            backgroundColor: `${colors.grey[1200]}`,
          },
        }}
        textAlign="center"
      >
        {loading && <Loading />}
        <DataGrid
          rows={ftc.map(
            (row: any) =>
              ({
                ...row,
                id: row._id,
                description: `$${row.amount} from ${row.senderUserName}` ,
                type: `${row.commissionType}` ,
                date: row.datePurchased.slice(0,10),
                status: row.status,
                // upLine: row.genealogy.upLineName,
              } as ft)
          )}
          columns={columns}
        ></DataGrid>
      </Box>
    </Box>
  );
};

export default FastTrackCommission;
