import { useEffect } from "react";
import { TextField } from "@mui/material";
import { useField } from "formik";
import { useFormikContext } from "formik";

const ShippingAddressInputField = ({ label, ...props }: any) => {
  console.log("do yo render this one");
  const { values, setFieldValue } = useFormikContext<any>();
  console.log(values);
  const [field, meta] = useField(props);
  useEffect(() => {
    if (values.sameShippingAddress === true) {
      if (values.country === "ph") {
        const shippingRegion = values.region.split("-");
        const shippingBarangay = values.barangay.split("-");
        const shippingCity = values.city.split("-");
        const shippingProvince = values.province.split("-");
        const shipToAddress = `${values.phAddress} ${shippingBarangay[1]} ${
          shippingCity[1]
        } ${shippingProvince[1]}, ${values.phZipCode}, ${
          shippingRegion[1]
        }, ${values.country.toUpperCase()}`;
        setFieldValue(field.name, shipToAddress);
      } else {
        const state =
          values.usauState.toUpperCase() !== "NA"
            ? values.usauState.toUpperCase() + ","
            : " ";
        const shipToAddress = `${values.usauAddress}, ${
          values.usauCity
        }, ${state} ${
          values.usauZipCode
        }, ${values.country.toUpperCase()}`;
        setFieldValue(field.name, shipToAddress);
      }
    } 
    else {
      setFieldValue(field.name, "");
    }
  }, [
    values.region,
    values.barangay,
    values.city,
    values.phAddress,
    values.phZipCode,
    values.country,
    values.province,
    values.sameShippingAddress,
    field.name,
    setFieldValue,
    values.usauAddress,
    values.usauCity,
    values.usauState,
    values.usauZipCode,
  ]);

  return (
    <TextField
      fullWidth
      label={label}
      {...field}
      {...props}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      variant="filled"
    />
  );
};

export default ShippingAddressInputField;
