import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../store/user-context";
import { RegionContext } from "../../store/region-context";
import { ProvinceContext } from "../../store/province-context";
import { CityContext } from "../../store/city-context";
import { CountryContext } from "../../store/country-context";
import { HermonPackageContext } from "../../store/hermon-package-context";
import { EnrolleeContext } from "../../store/enrolle-context";
import { SalesContext } from "../../store/sales-context";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  useTheme,
  InputAdornment,
  IconButton,
  Checkbox,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { tokens } from "../../theme";

import MultiStepForm from "../../components/forms/MultiStepForm";
import { FormStep } from "../../components/forms/MultiStepForm";
import InputField from "../../components/forms/InputField";
import UserNameInputField from "../../components/forms/custom-inputs/UserNameInputField";
import EmailInputField from "../../components/forms/custom-inputs/EmailInputField";
import RadioButtonInputField from "../../components/forms/custom-inputs/RadioButtonInputField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import "./addEditUser.css";
import SelectField from "../../components/forms/SelectField";
import SelectRegionField from "../../components/forms/custom-inputs/SelectRegionField";
import SelectProvinceField from "../../components/forms/custom-inputs/SelectProvinceField";
import SelectCityField from "../../components/forms/custom-inputs/SelectCityField";
import SelectBarangayField from "../../components/forms/custom-inputs/SelectBarangayField";
import SelectCountryField from "../../components/forms/custom-inputs/SelectCountryField";
import SelectShippingCountryField from "../../components/forms/custom-inputs/SelectShippingCountryField";
import SelectPackageField from "../../components/forms/custom-inputs/SelectPackageField";
import ShippingAddressInputField from "../../components/forms/custom-inputs/ShippingAddressInputField";
import CheckBoxField from "../../components/forms/custom-inputs/CheckBoxField";
import Loading from "../../components/ui/RingLoader";
import moment from "moment-timezone";

import {
  ValidationUserSchema,
  ValidationSponsorSchema,
} from "../../schema/ValidationUserSchema";

const AddEditUser = () => {
  const { user } = useContext(UserContext);
  const { regionCode } = useContext(RegionContext);
  const { provinceCode } = useContext(ProvinceContext);
  const { cityCode } = useContext(CityContext);
  const { countryCode } = useContext(CountryContext);
  const { hermonPackage } = useContext(HermonPackageContext);
  const { setEnrollee } = useContext(EnrolleeContext);
  const { shippingFee } = useContext(SalesContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { upName, position, sponsorUserName } = useParams();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const [legDropDown] = useState<any>([
    { key: "", value: "Select Leg" },
    { key: "left", value: "Left Leg" },
    { key: "right", value: "Right Leg" },
  ]);

  const [regionList, setRegionList] = useState<any>([]);
  const [provinceList, setProvinceList] = useState<any>([]);
  const [cityList, setCityList] = useState<any>([]);
  const [barangayList, setBarangayList] = useState<any>([]);
  const [regionLoading, setRegionLoading] = useState<boolean>(false);
  const [provinceLoading, setProvinceLoading] = useState<boolean>(false);
  const [cityLoading, setCityLoading] = useState<boolean>(false);
  const [barangayLoading, setBarangayLoading] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>("");
  const [totalAmount, setTotalAmount] = useState<string>("");

  useEffect(() => {
    setRegionLoading(true);
    async function getRegion() {
      const url = "https://psgc.gitlab.io/api/regions/";
      const response = await fetch(url);
      const jsonResponse = await response.json();
      setRegionList(jsonResponse);
      setRegionLoading(false);
    }
    getRegion();
  }, []);
  useEffect(() => {
    console.log("REGION CHANGE")
    if (regionCode !== "") {
      const regionNumber = regionCode.split("-");
      setProvinceLoading(true);
      async function getProvince() {
        let url = "";
        if(regionNumber[0] === "130000000"){
          url = `https://psgc.gitlab.io/api/regions/${regionNumber[0]}/districts/`;
        }else{
           url = `https://psgc.gitlab.io/api/regions/${regionNumber[0]}/provinces/`;
        }
        const response = await fetch(url);
        const jsonResponse = await response.json();
        console.log("regions");
        console.log(jsonResponse);
        setProvinceList(jsonResponse);
        setProvinceLoading(false);
      }
      getProvince();
    }
    setProvinceList([]);
    setCityList([]);
    setBarangayList([]);
  }, [regionCode]);
  useEffect(() => {
    if (provinceCode !== "") {
      const provinceNumber = provinceCode.split("-");
      const regionNumber = regionCode.split("-");
      setCityLoading(true);
      async function getCities() {
        let url = "";
        if(regionNumber[0] === "130000000"){
          url = `https://psgc.gitlab.io/api/districts/${provinceNumber[0]}/cities-municipalities/`;
        }else{
           url = `https://psgc.gitlab.io/api/provinces/${provinceNumber[0]}/cities-municipalities/`;
        }
        const response = await fetch(url);
        const jsonResponse = await response.json();
        setCityList(jsonResponse);
        setCityLoading(false);
      }
      getCities();
    }
    setCityList([]);
    setBarangayList([]);
  }, [provinceCode]);
  useEffect(() => {
    if (cityCode !== "") {
      const cityNumber = cityCode.split("-");
      setBarangayLoading(true);
      async function getBarangay() {
        const url = `https://psgc.gitlab.io/api/cities-municipalities/${cityNumber[0]}/barangays/`;
        const response = await fetch(url);
        const jsonResponse = await response.json();
        setBarangayList(jsonResponse);
        setBarangayLoading(false);
      }
      getBarangay();
    }
    setBarangayList([]);
  }, [cityCode]);
  useEffect(() => {
    if (hermonPackage === "1") {
      setAmount("125.00");
    } else if (hermonPackage === "3") {
      setAmount("300.00");
    } else if (hermonPackage === "6") {
      setAmount("510.00");
    } else if (hermonPackage === "10") {
      setAmount("780.00");
    } else if (hermonPackage === "20") {
      setAmount("1300.00");
    }
    const totalAmount = +amount + +shippingFee;
    setTotalAmount(totalAmount.toFixed(2));
  }, [hermonPackage, amount, shippingFee]);
  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  return (
    //working multi step form
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="ENROLL" subtitle="Enroll new distributor" />
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gridTemplateRows="auto auto 1fr 1fr 1fr auto auto"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn={{ xs: "span 12", md: "span 3" }}
          bgcolor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="8px"
          height="80%"
          maxHeight="300px"
          minHeight="300px"
        >
          <Box
            gridColumn={{ xs: "span 12", md: "span 12" }}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <img
              alt="profile-user"
              height="100px"
              width="100px"
              src={`/../assets/default_avatar.png`}
              style={{ cursor: "pointer" }}
            />
            <Typography variant="h5" fontWeight="100" color={colors.grey[1300]}>
              Sponsor
            </Typography>
            <Typography variant="h5" fontWeight="700" color={colors.grey[900]}>
              {user.username}
            </Typography>
            <Typography
              variant="h5"
              fontWeight="100"
              color={colors.grey[1300]}
              sx={{ marginTop: "15px" }}
            >
              Upline
            </Typography>
            <Typography variant="h5" fontWeight="700" color={colors.grey[900]}>
              {upName}
            </Typography>
          </Box>
        </Box>
        <Box
          gridColumn={{ xs: "span 12", md: "span 9" }}
          bgcolor={colors.primary[400]}
          alignItems="center"
          justifyContent="center"
          borderRadius="8px"
          height="100%"
        >
          {/* put logic here */}
          {/* put logic here */}

          <MultiStepForm
            initialValues={{
              directSponsorUserName: sponsorUserName,
              upLineUserName: upName,
              position: position,
              firstName: "",
              middleName: "",
              lastName: "",
              userName: "",
              email: "",
              password: "",
              confirmPassword: "",
              gender: "m",
              birthday: moment().tz("America/Los_Angeles").format("YYYY-MM-DD"),
              mobile: "",
              country: "ph",
              region: "",
              province: "",
              city: "",
              barangay: "",
              phAddress: "",
              shippingAddress: "",
              shippingCountry: "",
              phZipCode: "",
              usauAddress: "",
              usauState: "",
              usauCity: "",
              usauZipCode: "",
              sameShippingAddress: false,
              package: "1",
              enrollmentDate: moment()
                .tz("America/Los_Angeles")
                .format("YYYY-MM-DD"),
            }}
            onSubmit={(values) => {
              const newValue = values;
              newValue.price = amount;
              newValue.shippingFee = shippingFee;
              newValue.totalAmount = totalAmount;
              newValue.enrolledBy = `${user.firstName} ${user.lastName}`;
              setEnrollee(newValue);
              console.log(newValue);
              navigate("/package/payment");
            }}
          >
            {/* form step 1  */}
            <FormStep
              stepName="Step 1 | Sponsor"
              onSubmit={() => console.log("step 1 Submit")}
              validationSchema={ValidationSponsorSchema}
            >
              <Box sx={{ paddingTop: "30px" }}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4,minmax(0,1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  {" "}
                  <Typography
                    variant="h4"
                    fontWeight="600"
                    color={colors.grey[100]}
                  >
                    Sponsor Information
                  </Typography>
                  <InputField
                    name="directSponsorUserName"
                    label="Sponsor Userame*"
                    sx={{
                      gridColumn: "span 4",
                    }}
                    disabled={false}
                  />
                  <InputField
                    name="upLineUserName"
                    label="Upline Username*"
                    sx={{
                      gridColumn: "span 4",
                    }}
                    disabled={false}
                  />
                  <FormControl
                    sx={{ minWidth: 120 }}
                    size="small"
                    variant="filled"
                  >
                    {/* <SelectField
                      name="position"
                      label="Binary Leg"
                      options={legDropDown}
                    /> */}
                  </FormControl>
                </Box>
                <Typography
              variant="h5"
              fontWeight="400"
              color={colors.grey[100]}
              sx={{ marginBottom: "10px", gridColumn: "span 8" }}
            >
              {position === "right"
                ? "The user to be enrolled was positioned on right leg."
                : "The user to be enrolled was positioned on left leg."}
            </Typography>
              </Box>
            </FormStep>
            
            <FormStep
              stepName="Step 2 | User"
              onSubmit={() => console.log("step 2 Submit")}
              validationSchema={ValidationUserSchema}
            >
              <Box sx={{ paddingTop: "30px" }}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4,minmax(0,1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <Typography
                    variant="h4"
                    fontWeight="600"
                    color={colors.grey[100]}
                  >
                    Personal Information
                  </Typography>
                  <InputField
                    name="firstName"
                    label="First Name"
                    sx={{ gridColumn: "span 4" }}
                  />
                  <InputField
                    name="middleName"
                    label="Middle Name"
                    sx={{ gridColumn: "span 4" }}
                  />
                  <InputField
                    name="lastName"
                    label="Last Name"
                    sx={{ gridColumn: "span 4" }}
                  />
                  <Typography
                    variant="h4"
                    fontWeight="600"
                    color={colors.grey[100]}
                    sx={{ gridColumn: "span 4" }}
                  >
                    Login Information
                  </Typography>
                  <Box sx={{ gridColumn: "span 2" }}>
                    <UserNameInputField
                      name="userName"
                      label="Username"
                      sx={{ gridColumn: "span 4" }}
                    />
                  </Box>
                  <Box sx={{ gridColumn: "span 2" }}>
                    <EmailInputField
                      name="email"
                      label="Email"
                      sx={{ gridColumn: "span 4" }}
                    />
                  </Box>
                  <InputField
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleToggleShowPassword}>
                            <Checkbox
                              checked={showPassword}
                              icon={<VisibilityOff />}
                              checkedIcon={<Visibility />}
                              inputProps={{
                                "aria-label": "toggle password visibility",
                              }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <InputField
                    name="confirmPassword"
                    label="Confirm Password"
                    type={showConfirmPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleToggleShowConfirmPassword}>
                            <Checkbox
                              checked={showConfirmPassword}
                              icon={<VisibilityOff />}
                              checkedIcon={<Visibility />}
                              inputProps={{
                                "aria-label": "toggle password visibility",
                              }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <Box sx={{ gridColumn: "span 4" }}>
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Gender
                      </FormLabel>
                      <RadioButtonInputField name="gender" label="Gender" />
                    </FormControl>
                  </Box>
                  <Box sx={{ gridColumn: "span 4", width: "200px" }}>
                    <Typography
                      variant="h5"
                      fontWeight="400"
                      color={colors.grey[100]}
                      sx={{ marginBottom: "10px" }}
                    >
                      Birthday
                    </Typography>
                    <InputField
                      type="date"
                      name="birthday"
                      //   defaultValue={new Date().toISOString().slice(0, 10)}
                    />
                  </Box>
                  <InputField
                    name="mobile"
                    label="Mobile"
                    sx={{ gridColumn: "span 2" }}
                  />
                  <FormControl
                    sx={{ width: 200, gridColumn: "span 4" }}
                    size="small"
                    variant="filled"
                  >
                    <Typography
                      variant="h5"
                      fontWeight="400"
                      color={colors.grey[100]}
                      sx={{ marginBottom: "10px" }}
                    >
                      Country
                    </Typography>
                    <SelectCountryField
                      name="country"
                      label="Country"
                      defaultValue="ph"
                    />
                  </FormControl>
                  {/* Philippine Address */}
                  {countryCode === "ph" ? (
                    <FormControl sx={{ gridColumn: "span 4" }}>
                      {regionLoading ? (
                        <Loading />
                      ) : (
                        <FormControl
                          sx={{ marginBottom: "20px" }}
                          size="small"
                          variant="filled"
                        >
                          <Typography
                            variant="h5"
                            fontWeight="400"
                            color={colors.grey[100]}
                            sx={{ marginBottom: "10px" }}
                          >
                            Region
                          </Typography>
                          <SelectRegionField
                            name="region"
                            label="Region"
                            options={regionList}
                          />
                        </FormControl>
                      )}
                      {provinceLoading ? (
                        <Loading />
                      ) : (
                        <FormControl
                          sx={{ marginBottom: "20px" }}
                          size="small"
                          variant="filled"
                        >
                          <Typography
                            variant="h5"
                            fontWeight="400"
                            color={colors.grey[100]}
                            sx={{ marginBottom: "10px" }}
                          >
                            {regionCode.slice(0,9) === "130000000" ? "District" : "Province" } 
                            
                          </Typography>
                          <SelectProvinceField
                            name="province"
                            label="Province"
                            options={provinceList}
              
                          />
                        </FormControl>
                      )}
                      {cityLoading ? (
                        <Loading />
                      ) : (
                        <FormControl
                          sx={{ marginBottom: "20px" }}
                          size="small"
                          variant="filled"
                        >
                          <Typography
                            variant="h5"
                            fontWeight="400"
                            color={colors.grey[100]}
                            sx={{ marginBottom: "10px" }}
                          >
                            City/Municipality
                          </Typography>
                          <SelectCityField
                            name="city"
                            label="City"
                            options={cityList}
                          />
                        </FormControl>
                      )}
                      {barangayLoading ? (
                        <Loading />
                      ) : (
                        <FormControl
                          sx={{ marginBottom: "20px" }}
                          size="small"
                          variant="filled"
                        >
                          <Typography
                            variant="h5"
                            fontWeight="400"
                            color={colors.grey[100]}
                            sx={{ marginBottom: "10px" }}
                          >
                            Barangay
                          </Typography>
                          <SelectBarangayField
                            name="barangay"
                            label="Barangay"
                            options={barangayList}
                          />
                        </FormControl>
                      )}
                      <FormControl
                        sx={{ marginBottom: "20px" }}
                        size="small"
                        variant="filled"
                      >
                        <Typography
                          variant="h5"
                          fontWeight="400"
                          color={colors.grey[100]}
                          sx={{ marginBottom: "10px" }}
                        >
                          Complete Address
                        </Typography>
                        <InputField
                          name="phAddress"
                          label="House #/Blk #/Lot #/ Street #/ Subdivision"
                        />
                      </FormControl>
                      <FormControl
                        sx={{ marginBottom: "20px" }}
                        size="small"
                        variant="filled"
                      >
                        <Typography
                          variant="h5"
                          fontWeight="400"
                          color={colors.grey[100]}
                          sx={{ marginBottom: "10px" }}
                        >
                          Zip Code
                        </Typography>
                        <InputField name="phZipCode" label="Zip Code" />
                      </FormControl>
                    </FormControl>
                  ) : (
                    <FormControl sx={{ gridColumn: "span 4" }}>
                      <FormControl
                        sx={{ marginBottom: "20px" }}
                        size="small"
                        variant="filled"
                      >
                        <Typography
                          variant="h5"
                          fontWeight="400"
                          color={colors.grey[100]}
                          sx={{ marginBottom: "10px" }}
                        >
                          Address(Apartment, unit, or street address number and
                          street name)
                        </Typography>
                        <InputField
                          name="usauAddress"
                          // label="Apartment, unit, or street address number and street name"
                        />
                      </FormControl>
                      <FormControl
                        sx={{ marginBottom: "20px" }}
                        size="small"
                        variant="filled"
                      >
                        <Typography
                          variant="h5"
                          fontWeight="400"
                          color={colors.grey[100]}
                          sx={{ marginBottom: "10px" }}
                        >
                          City, (Province or Territory if outside USA)
                        </Typography>
                        <InputField
                          name="usauCity"
                          // label="City, (Province or Territory if outside USA)"
                        />
                      </FormControl>
                      <FormControl
                        sx={{ marginBottom: "20px" }}
                        size="small"
                        variant="filled"
                      >
                        <Typography
                          variant="h5"
                          fontWeight="400"
                          color={colors.grey[100]}
                          sx={{ marginBottom: "10px" }}
                        >
                          State Code, Territory Code if outside USA.Put NA if
                          not applicable.
                        </Typography>
                        <InputField
                          name="usauState"
                          // label="State Code, Territory Code if outside USA."
                        />
                      </FormControl>
                      <FormControl
                        sx={{ marginBottom: "20px" }}
                        size="small"
                        variant="filled"
                      >
                        <Typography
                          variant="h5"
                          fontWeight="400"
                          color={colors.grey[100]}
                          sx={{ marginBottom: "10px" }}
                        >
                          Zip/Postal Code
                        </Typography>
                        <InputField
                          name="usauZipCode"
                          // label="Zip Postal Code"
                        />
                      </FormControl>
                    </FormControl>
                  )}
                  <FormControl sx={{ gridColumn: "span 4" }}>
                    <Typography
                      variant="h4"
                      fontWeight="600"
                      color={colors.grey[100]}
                      sx={{ gridColumn: "span 4" }}
                    >
                      Shipping Address
                    </Typography>
                    <FormControl
                      sx={{
                        width: 200,
                        gridColumn: "span 4",
                        // marginBottom: "10px",
                      }}
                      size="small"
                      variant="filled"
                    >
                      <CheckBoxField name="sameShippingAddress" />
                      <Typography
                        variant="h5"
                        fontWeight="400"
                        color={colors.grey[100]}
                        sx={{ marginBottom: "10px" }}
                      >
                        Country
                      </Typography>
                      <SelectShippingCountryField
                        name="shippingCountry"
                        label="Country"
                      />
                    </FormControl>
                    <FormControl
                      sx={{ marginBottom: "20px" }}
                      size="small"
                      variant="filled"
                    >
                      <Typography
                        variant="h5"
                        fontWeight="400"
                        color={colors.grey[100]}
                        sx={{ marginTop: "20px", marginBottom: "10px" }}
                      >
                        Ship To (Complete Address)
                      </Typography>
                      <ShippingAddressInputField
                        name="shippingAddress"
                        disabled={false}
                      />
                    </FormControl>
                  </FormControl>
                  {/* End Philippine Address  */}
                </Box>
              </Box>
            </FormStep>
            <FormStep
              stepName="Step 3 | Choose Package"
              onSubmit={() => console.log("step 3 Submit")}
            >
              <Box sx={{ paddingTop: "30px" }}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4,minmax(0,1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  {/* {" "} */}

                  <Typography
                    variant="h4"
                    fontWeight="600"
                    color={colors.grey[100]}
                  >
                    Package
                  </Typography>
                  <FormControl
                    sx={{
                      width: "300px",
                      gridColumn: "span 4",
                      marginX: "auto",
                      alignItems: "center",
                    }}
                    size="small"
                    variant="filled"
                  >
                    <img
                      alt="profile-user"
                      width="240px"
                      src={`/../assets/probiox.png`}
                      style={{ cursor: "pointer", borderRadius: "8px" }}
                    />
                    <FormControl>
                      <Box sx={{ textAlign: "center" }}>
                        <Typography
                          variant="h5"
                          fontWeight="200"
                          marginTop="10px"
                        >
                          Quantity: <span>{hermonPackage}</span> Bottles
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "right",
                          marginBottom: "20px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            paddingRight: "20px",
                          }}
                        >
                          <Typography
                            variant="h5"
                            fontWeight="200"
                            marginTop="10px"
                            textAlign="right"
                          >
                            Amount:
                          </Typography>
                          <Typography
                            variant="h5"
                            fontWeight="200"
                            marginTop="10px"
                            textAlign="right"
                          >
                            Shipping Fee:
                          </Typography>
                          <Typography
                            variant="h5"
                            fontWeight="700"
                            marginTop="10px"
                            textAlign="right"
                          >
                            Total:
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="h5"
                            fontWeight="200"
                            marginTop="10px"
                            textAlign="right"
                          >
                            ${amount}
                          </Typography>
                          <Typography
                            variant="h5"
                            fontWeight="200"
                            marginTop="10px"
                            textAlign="right"
                          >
                            ${shippingFee}
                          </Typography>
                          <Typography
                            variant="h5"
                            fontWeight="700"
                            marginTop="10px"
                            textAlign="right"
                            color="green"
                          >
                            ${totalAmount}
                          </Typography>
                        </Box>
                      </Box>
                    </FormControl>
                    <SelectPackageField name="package" label="Package" />
                  </FormControl>
                  {user.isAdmin && (
                    <FormControl
                      sx={{
                        width: "300px",
                        gridColumn: "span 4",
                        marginX: "auto",
                      }}
                      size="small"
                      variant="filled"
                    >
                      <Typography
                        variant="h5"
                        fontWeight="400"
                        color={colors.grey[100]}
                        sx={{ marginBottom: "10px" }}
                      >
                        Enrollment Date
                      </Typography>
                      <InputField type="date" name="enrollmentDate" />
                    </FormControl>
                  )}
                  <FormControl
                    sx={{ gridColumn: "span 4", marginX: "auto" }}
                    size="small"
                    variant="filled"
                  >
                    <Typography
                      variant="h5"
                      fontWeight="400"
                      color={colors.grey[100]}
                    >
                      Please double check all information. Once submitted for
                      payment, the form will be reset and all values will be
                      deleted.
                    </Typography>
                  </FormControl>
                </Box>
              </Box>
            </FormStep>
          </MultiStepForm>
        </Box>
      </Box>
    </Box>
  );
};

export default AddEditUser;
