import { Box, Typography, useTheme, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
interface IWalletSummary {
  walletBalance: string;
  totalPayout: number;
  currentBalance: number;
  pendingRequest: string;
  transferredFundsTotal: number;
}
const WalletSummary: React.FC<{ wallet: IWalletSummary }> = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <>
      <Box display="flex" justifyContent="right">
        <Typography
          variant="h5"
          sx={{ color: colors.grey[1300], paddingTop: "2px" }}
        >
          Total Earning:
        </Typography>
        <Typography
          variant="h3"
          fontWeight="bold"
          sx={{
            color: colors.grey[900],
            textAlign: "right",
            minWidth: "140px",
          }}
        >
          ${props.wallet.walletBalance}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="right">
        <Typography
          variant="h5"
          sx={{ color: colors.grey[1300], paddingTop: "2px" }}
        >
          Total Withdrawal:
        </Typography>
        <Typography
          variant="h3"
          fontWeight="bold"
          sx={{
            color: colors.grey[900],
            textAlign: "right",
            minWidth: "140px",
          }}
        >
          ${props.wallet.totalPayout.toFixed(2)}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="right">
        <Typography
          variant="h5"
          sx={{ color: colors.grey[1300], paddingTop: "2px" }}
        ></Typography>
        <Typography>-------------------------</Typography>
      </Box>
      <Box display="flex" justifyContent="right">
        <Typography
          variant="h5"
          sx={{ color: colors.grey[1300], paddingTop: "2px" }}
        >
          Current Balance:
        </Typography>
        <Typography
          variant="h3"
          fontWeight="bold"
          sx={{
            color: colors.greenAccent[1000],
            textAlign: "right",
            minWidth: "140px",
            // marginBottom: "4px",
          }}
        >
          ${props.wallet.currentBalance.toFixed(2)}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="right">
        <Typography
          variant="h5"
          sx={{ color: colors.grey[1300], paddingTop: "2px" }}
        >
          Pending Request:
        </Typography>
        <Typography
          variant="h3"
          fontWeight="bold"
          sx={{
            color: colors.grey[1300],
            textAlign: "right",
            minWidth: "140px",
            // marginBottom: "12px",
          }}
        >
          $
          {Number(props.wallet.pendingRequest) > 0
            ? props.wallet.pendingRequest
            : "0.00"}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="right">
        <Typography
          variant="h5"
          sx={{ color: colors.grey[1300], paddingTop: "2px" }}
        >
          Total Transferred:
        </Typography>
        <Typography
          variant="h3"
          fontWeight="bold"
          sx={{
            color: colors.grey[1300],
            textAlign: "right",
            minWidth: "140px",
            // marginBottom: "12px",
          }}
        >
          $
          {props.wallet.transferredFundsTotal > 0
            ? props.wallet.transferredFundsTotal
            : "0.00"}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="right">
        <Typography
          variant="h5"
          sx={{ color: colors.grey[1300], paddingTop: "2px" }}
        ></Typography>
        <Typography>-------------------------</Typography>
      </Box>
      <Box display="flex" justifyContent="right" sx={{ marginBottom: "16px" }}>
        <Typography
          variant="h5"
          sx={{ color: colors.grey[1300], paddingTop: "2px" }}
        >
          Total Remaining:
        </Typography>
        <Typography
          variant="h3"
          fontWeight="bold"
          sx={{
            color: "red",
            textAlign: "right",
            minWidth: "140px",
            // marginBottom: "12px",
          }}
        >
          $
          {(
            props.wallet.currentBalance -
            Number(props.wallet.pendingRequest) -
            Number(props.wallet.transferredFundsTotal)
          ).toFixed(2)}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="right">
        {props.wallet.currentBalance -
          Number(props.wallet.pendingRequest) -
          Number(props.wallet.transferredFundsTotal) >
          0 && (
          <Box
            paddingRight={
              props.wallet.currentBalance -
                Number(props.wallet.pendingRequest) -
                Number(props.wallet.transferredFundsTotal) >=
              100
                ? "12px"
                : "0px"
            }
          >
            <Link to="/transfer">
              <Button variant="contained" className="multiStepButton">
                transfer
              </Button>
            </Link>
          </Box>
        )}
        {props.wallet.currentBalance -
          Number(props.wallet.pendingRequest) -
          Number(props.wallet.transferredFundsTotal) >=
          100 && (
          <Link to="/withdraw">
            <Button variant="contained" className="multiStepButton">
              withdraw
            </Button>
          </Link>
        )}
      </Box>
    </>
  );
};

export default WalletSummary;
