import { useState, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import InnerCalendarBox from "./InnerCalendarBox";
import CycleBox from "./CycleBox";

const CalendarCard: React.FC<{
  weekNumber: string;
  dateFrom: string;
  dateTo: string;
  businessVolumes: any;
  weekCounter: number;
}> = (props) => {
  console.log("props here");
  console.log(props);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [weeklyVolume, setWeeklyVolume] = useState<any>({});

  useEffect(() => {
    let left = 0;
    let right = 0;
    let cycle = 0;
    let maxPayableCycle = 0;
    let spillOverLeft = 0;
    let spillOverRight = 0;
    for (const volume of props.businessVolumes) {
      if (volume.counter === props.weekCounter) {
        left = volume.left;
        right = volume.right;
        cycle = volume.cycle.cycleCount ? parseInt(volume.cycle.cycleCount) : 0;
        maxPayableCycle = volume.cycle.maxPayableCycle
          ? parseInt(volume.cycle.maxPayableCycle)
          : 0;
        spillOverLeft = volume.spill.businessVolumeLeft
          ? parseInt(volume.spill.businessVolumeLeft)
          : 0;
        spillOverRight = volume.spill.businessVolumeRight
          ? parseInt(volume.spill.businessVolumeRight)
          : 0;
      }
    }
    setWeeklyVolume({
      left: left,
      right: right,
      cycle: cycle,
      maxPayableCycle: maxPayableCycle,
      spillOverLeft: spillOverLeft,
      spillOverRight: spillOverRight,
    });
  }, [props.businessVolumes, props.weekCounter]);
  return (
    <Box
      gridColumn={{ xs: "span 12", md: "span 6", lg: "span 3" }}
      gridRow="span 3"
      color={colors.grey[100]}
      borderRadius="8px"
      p="0 12px"
    >
      <Typography
        variant="h3"
        fontWeight="bold"
        textAlign="center"
        pt="12px"
        color={colors.grey[900]}
      >
        {props.weekNumber}
      </Typography>
      <Typography
        textAlign="center"
        paddingBottom="12px"
        color={colors.grey[1300]}
      >
        {props.dateFrom} - {props.dateTo}
      </Typography>
      <InnerCalendarBox
        innerLeftRight={{ left: "Left", right: "Right" }}
        value={{ left: -1, right: -1 }}
        withRadius={true}
      />
      <InnerCalendarBox
        innerLeftRight={{ left: "Carry", right: "Carry" }}
        value={{
          left: weeklyVolume.spillOverLeft,
          right: weeklyVolume.spillOverRight,
        }}
        withRadius={false}
      />
      <InnerCalendarBox
        innerLeftRight={{ left: "Group Volume", right: "Group Volume" }}
        value={{ left: weeklyVolume.left, right: weeklyVolume.right }}
        withRadius={false}
      />
      <InnerCalendarBox
        innerLeftRight={{ left: "Total", right: "Total" }}
        value={{ left: weeklyVolume.left + weeklyVolume.spillOverLeft, right: weeklyVolume.right + weeklyVolume.spillOverRight }}
        withRadius={false}
      />
      <CycleBox
        center="Cycle"
        value={
          weeklyVolume.cycle > weeklyVolume.maxPayableCycle
            ? weeklyVolume.maxPayableCycle
            : weeklyVolume.cycle
        }
      />
    </Box>
  );
};

export default CalendarCard;
