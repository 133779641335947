import React, {useState, useEffect} from "react";
import { useParams } from 'react-router-dom';
import { Box, Typography, useTheme} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
const Error = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { type } = useParams();
    const [message, setMessage] = useState<string>("");

    useEffect(()=>{
        if(type === 'enrollee' || type === ''){
            setMessage('Oops! Something went wrong! Please try again later or contact your Administrator.');
        }else if(type === 'upgrade'){
            setMessage('Oops! Upgrade not successful! Please try again later or contact your Administrator.');
        }
    },[type])
        return (
            <Box m="20px">
                {/* HEADER */}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title="ERROR" subtitle="Oops" />
                </Box>
                <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gridAutoRows="140px"
                    gridTemplateRows="auto auto 1fr 1fr 1fr auto auto"
                    gap="20px"
                >
                    <Box
                        gridColumn={{ xs: "span 12", md: "span 12" }}
                        bgcolor={colors.primary[400]}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="8px"
                        paddingX="40px"
                        height="100px"
                    >
                        <Typography
                                    variant="h3"
                                    fontWeight="700"
                                    color="red"
                                >
                                       {message}
                                    </Typography>
                    </Box>
                </Box>
            </Box>)
    
}

export default Error;