import { useEffect } from "react";
import { Select, Typography } from "@mui/material";
import { useField, useFormikContext } from "formik";
import MenuItem from "@mui/material/MenuItem";


const SelectShippingCountryField = ({ label, ...props }: any) => {
  
  const { values, setFieldValue} = useFormikContext<any>();
  
  // const options = props.options;
  const [field, meta] = useField(props);
  useEffect(()=>{
    if(values.sameShippingAddress){
      setFieldValue(field.name, values.country)
    }else{
      setFieldValue(field.name, '')
    }
  },[values.sameShippingAddress, values.country, field.name, setFieldValue])
  
  
  return (
    <>
      <Select
        label={label}
        {...field}
        error={meta.touched && Boolean(meta.error)}
      >
        <MenuItem value="">--Select Country--</MenuItem>
        <MenuItem value="au">Australia</MenuItem>
        <MenuItem value="cnd">Canada</MenuItem>
        <MenuItem value="usa">United States</MenuItem>
        <MenuItem value="ph">Philippines</MenuItem>
        <MenuItem value="sg">Singapore</MenuItem>
      </Select>
      {meta.touched && Boolean(meta.error) && (
        <Typography
          fontSize={10}
          color="#f44336"
          sx={{ marginTop: "5px", marginLeft: "15px" }}
        >
          required
        </Typography>
      )}
    </>
  );
};

export default SelectShippingCountryField;
