import {FormControlLabel, Checkbox} from '@mui/material';
import { useField } from "formik";
import { useFormikContext } from "formik";
import { useEffect } from 'react';

const CheckBoxField = ({ label, ...props }: any) => {
    const {values, setFieldValue} = useFormikContext<any>();
    const [field] = useField(props)
    
    const handleChange = (e: any) => {
        if(e.target.checked){
            setFieldValue(field.name, true)
        }else{
            setFieldValue(field.name, false)
        }
    }

    useEffect(() =>{
        setFieldValue(field.name, false)
    },[values.country, setFieldValue, field.name])
    return <FormControlLabel
    control={<Checkbox
    checked={values.sameShippingAddress ? true: false}
    />}
     label="Same as address above"
     onChange={handleChange}
  />
}

export default CheckBoxField