import {Box, Typography, useTheme} from '@mui/material';
import { tokens } from '../theme';
const ActiveCounter: React.FC<{activeDays: any}> = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let signalColor = '';
  if(props.activeDays <= 7 && props.activeDays > 3){
    signalColor = "#FCB728";
  }else if(props.activeDays <= 3 && props.activeDays > 1){
    signalColor = "#F7821B"
  }
  else if(props.activeDays <= 1){
    signalColor = "#F23D44";
  }
  else{
    signalColor = colors.greenAccent[1000];
  }
    return (
        <Box
                sx={{
                  bgcolor: signalColor,
                  width: "70%",
                  marginX: "auto",
                  marginTop: "20px",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingY:"5px"
                }}
              >
                <Typography variant="h6" color="white">
                  {props.activeDays > 0 ? 'Active Days' : 'Inactive'}
                </Typography>
                <Typography variant="h1" color="white" fontWeight="700">
                  {props.activeDays > 0 ? props.activeDays : '0'}
                </Typography>
              </Box>
    )
}

export default ActiveCounter;