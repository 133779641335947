import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../store/user-context";
import { Box, Button, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import PaymentIcon from "@mui/icons-material/Payment";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import MonthlyPV from "../../components/monthly-pv/MonthlyPV";
import MonthlyMaintenance from "../../components/MonthlyMaintenance";
import useCurrentRank from "../../hooks/use-current-rank";
import CashingStatBox from "../../components/wallet/CashingStatBox";
import RetailStoreComponent from "../../components/retail-store/RetailStoreComponent";

const UserDashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { user } = useContext(UserContext);
  const currentRank = useCurrentRank(user.currentPackage, user.goodLifeRank);
  // const currentVolume = useVolume(user.currentPackage);

  console.log("usercontext at userdashboard");
  console.log(user);
  //states

  //others

  //handlers
  // ...code here

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

        {/* <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box> */}
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn={{ xs: "span 12", md: user.currentPackage !== "Retailer" ? "span 6" : "span 12", lg: user.currentPackage !== "Retailer" ? "span 3" : "span 6" }}
          bgcolor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="8px"
        >
          <StatBox
            title={user.currentPackage !== "Retailer" ? user.currentPackage : "Customer Retail Program"}
            // title={"Customer Retail Program"}
            subtitle="Current Package"
            definition= "PACKAGE"
            increase="+14%"
            icon={
              <BusinessCenterIcon
                sx={{ color: colors.grey[900], fontSize: "26px" }}
              />
            }
          />
        </Box>
        {user.currentPackage !== "Retailer" && (
          <Box
            gridColumn={{ xs: "span 12", md: "span 6", lg: "span 3" }}
            bgcolor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="8px"
          >
            <StatBox
              title={
                user.username === "hermoncorporation"
                  ? "400"
                  : user.currentPackage === "Retailer"
                  ? "N/A"
                  : user.currentVolume
              }
              subtitle="My Current Volumes"
              definition="PERSONAL VOLUME"
              increase="+21%"
              icon={
                <PaymentIcon
                  sx={{ color: colors.grey[900], fontSize: "26px" }}
                />
              }
            />
          </Box>
        )}
        {user.currentPackage !== "Retailer" && (
          <Box
            gridColumn={{ xs: "span 12", md: "span 6", lg: "span 3" }}
            bgcolor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="8px"
          >
            <StatBox
              title={user.currentPackage === "Retailer" ? "N/A" : currentRank}
              subtitle="My Current Rank"
              definition="RANK"
              increase="+5%"
              icon={
                <WorkspacePremiumIcon
                  sx={{ color: colors.grey[900], fontSize: "26px" }}
                />
              }
            />
          </Box>
        )}
        <Box
          gridColumn={{ xs: "span 12",  md: user.currentPackage !== "Retailer" ? "span 6" : "span 12", lg: user.currentPackage !== "Retailer" ? "span 3" : "span 6" }}
          bgcolor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="8px"
        >
          <CashingStatBox
            walletBalance={user.walletBalance}
            totalPayout={user.totalPayout}
            subtitle="Overall Cachings!"
            definition="Total Earning"
            increase="+43%"
            icon={
              <ThumbUpOffAltIcon
                sx={{ color: colors.grey[900], fontSize: "26px" }}
              />
            }
          />
        </Box>
        {/* <MonthlyMaintenance /> */}
        {/* Row Calendar */}
        {user.currentPackage !== "Retailer" && <MonthlyPV />}

        {/* end Row Calendar */}
      </Box>
      {user.currentPackage === "Retailer" && <RetailStoreComponent />}
    </Box>
  );
};

export default UserDashboard;
