import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { EnrolleeContext } from "../../store/enrolle-context";
import { SalesContext } from "../../store/sales-context";
import { checkStatus, clearStorage } from "../../utils/paymongo";
const Processing = () => {
  const { enrollee, setEnrollee } = useContext(EnrolleeContext);
  const { setSale } = useContext(SalesContext);
  const navigate = useNavigate();

  useEffect(() => {
    const uPaymentIntentId = localStorage.getItem("paymentIntentId");
    const uClientKey = localStorage.getItem("clientKey");
    const uPaymentMethodId = localStorage.getItem("paymentMethodId");
    let oStatus = "";

    const enrolleeString = localStorage.getItem("enrollee");
    if (enrolleeString !== null) {
      setEnrollee(JSON.parse(enrolleeString));
    }

    const saleString = localStorage.getItem("sale");
    if (saleString !== null) {
      setSale(JSON.parse(saleString));
    }

    if (
      uPaymentIntentId !== "" &&
      uClientKey !== "" &&
      uPaymentMethodId !== ""
    ) {
      // double check if succedded
      async function checkStatus() {
        const statusResponse = await fetch(
          "https://api.paymongo.com/v1/payment_intents/" +
            uPaymentIntentId +
            "?client_key=" +
            uClientKey,
          {
            method: "GET",
            headers: {
              authorization:
                `Basic ${process.env.REACT_APP_PAYMONGO_API_KEY}`, //encoded in base64 test public key
              //  "Basic cGtfbGl2ZV9FRnZEcFl1RUFaOHRRalNGZ1pmdDZ4YkQ=", //encoded in base64 live public key
            },
          }
        );

        const jsonStatushResponse = await statusResponse.json();
        oStatus = jsonStatushResponse.data.attributes.status;
        if (oStatus === "succeeded") {
          if (localStorage.getItem("enrollee") === "{}") {
            clearStorage();
            return navigate("/decider/upgrade");
          } else {
            clearStorage();
            return navigate("/decider/enrollee");
          }
        } else if (oStatus === "awaiting_payment_method") {
          clearStorage();
          return navigate("/error/enrollee");
        } else if (oStatus === "processing") {
          const checkTransStatus = async () => {
            const transitoryStatus: any = await checkStatus();
            const statusX = transitoryStatus.data.attributes.status;
            if (statusX === "succeeded") {
              if (localStorage.getItem("enrollee") === "{}") {
                clearStorage();
                return navigate("/decider/upgrade");
              } else {
                clearStorage();
                return navigate("/decider/enrollee");
              }
            } else if (statusX === "awaiting_payment_method") {
              clearStorage();
              return navigate("/error/enrollee");
            }
          };
          let delayTimer = setTimeout(checkTransStatus, 2000);
          setTimeout(() => {
            clearTimeout(delayTimer);
          }, 2000);
        }
      }
      checkStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <h1>Processing Please Wait...</h1>;
};

export default Processing;
