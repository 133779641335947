export const userObj = (obj: any) => {
  const user = {
    id: obj._id,
    isAdmin: obj.isAdmin,
    firstName: obj.firstName,
    lastName: obj.lastName,
    username: obj.userName,
    gender: obj.gender,
    currentPackage: obj.currentPackage,
    goodLifeRank: obj.goodLifeRank,
    country: obj.country,
    shippingCountry: obj.shippingCountry,
    shippingAddress: obj.shippingAddress,
    lastPurchased: obj.lastPurchased,
    dateNow: obj.dateNow,
    currentVolume: obj.currentVolume,
    hasPendingFTComm: obj.hasPendingFTComm,
    walletBalance: obj.walletBalance,
    totalPayout: obj.totalPayout,
    pendingWithdrawalRequest: obj.pendingWithdrawalRequest,
    transferredFundsTotal: obj.transferredFundsTotal,
    activeUntil: obj.activeUntil
  };

  return user;
};

export const getUserDetails = async () => {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/users/details`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const jsonResponse = await response.json();
  return jsonResponse;
};
