import { useState, useContext } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup";

import Header from "../../components/Header";
import UserContext from "../../store/user-context";
import Loading from "../../components/ui/RingLoader";
import { userObj, getUserDetails } from "../../utils/user-object";

type FormValues = {
  receiverUserName: string;
  amount: string;
  rem: string;
};

const amountRegExp =
/^(?!0+(\.0+)?$)(?=.*[1-9]\d*)(?:\d+|\d{1,3}(?:,\d{3})+)?(?:\.\d{1,2})?$/;

const userSchema = yup.object().shape({
  receiverUserName: yup.string().required("required"),
  amount: yup
    .string()
    .required("Amount required. Minimum of $100.00")
    .matches(amountRegExp, "Amount is not valid."),
});

const Transfer = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user, setUser } = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(false);

  const handleFormSubmit = async (values: FormValues) => {
    setLoading(true);

    if (
      Number(values.amount) >
      Number(user.walletBalance) -
        Number(user.totalPayout) -
        Number(user.pendingWithdrawalRequest) - 
        Number(user.transferredFundsTotal)
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Amount cannot be greater than current wallet balance",
      });
      setLoading(false);
      return;
    }
    const token = localStorage.getItem("token");

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/payout/transfer`,
      {
        method: "POST",
        headers: {
          authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        body: JSON.stringify({
          ...values,
          senderUserName: user.username,
          senderFullName: user.firstName + " " + user.lastName,
        }),
      }
    );

    const jsonResponse = await response.json();
    if (jsonResponse.userNotFound) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "User not Found!Please try again",
      });
      setLoading(false);
      return;
    }
    if (jsonResponse.requestTransferred) {
      Swal.fire("Fund Transfer Successfully Sent!");
      setLoading(false);
      const fetchUserDetails = await getUserDetails();
      setUser(userObj(fetchUserDetails));
      navigate("/wallet");
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!Please try again",
      });
      navigate("/wallet");
    }
    setLoading(false);
  };

  const initialValues = {
    receiverUserName: "",
    amount: (
      (user as { walletBalance: number }).walletBalance -
      (user as { totalPayout: number }).totalPayout -
      (user as { pendingWithdrawalRequest: number }).pendingWithdrawalRequest -
      (user as {transferredFundsTotal: number}).transferredFundsTotal
    )
      .toFixed(2)
      .toString(),
    rem: "",
  };

  return (
    <Box m="20px">
      <Header title="TRANSFER" subtitle="Transfer funds to your network members" />

      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gridTemplateRows="auto auto 1fr 1fr 1fr auto auto"
        gap="20px"
      >
        <Box
          gridColumn={{ xs: "span 12", md: "span 12" }}
          bgcolor={colors.primary[400]}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          borderRadius="8px"
          paddingTop="20px"
        >
          {loading ? (
            <Loading />
          ) : (
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={userSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box sx={{ marginX: "10px" }}>
                    <Typography
                      variant="h5"
                      fontWeight="400"
                      color={colors.grey[100]}
                      sx={{ marginBottom: "10px", marginTop: "10px" }}
                    >
                      User Info
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Username"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.receiverUserName}
                      name="receiverUserName"
                      error={
                        !!touched.receiverUserName && !!errors.receiverUserName
                      }
                      helperText={
                        touched.receiverUserName && errors.receiverUserName
                      }
                      sx={{
                        gridColumn: "span 4",
                        marginBottom: "20px",
                      }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Amount(in USD)"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.amount}
                      name="amount"
                      error={!!touched.amount && !!errors.amount}
                      helperText={touched.amount && errors.amount}
                      sx={{
                        gridColumn: "span 4",
                        marginBottom: "20px",
                      }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Remarks"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.rem}
                      name="rem"
                      error={!!touched.rem && !!errors.rem}
                      helperText={touched.rem && errors.rem}
                      sx={{
                        gridColumn: "span 4",
                        // marginBottom: "40px",
                      }}
                    />
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    mt="20px"
                    mb="20px"
                    sx={{ marginX: "10px" }}
                  >
                    {/* <Button type="submit" color="secondary" variant="contained">
                SUBMIT
              </Button> */}
                    <Link to="/wallet">
                      <Button variant="contained" className="multiStepButton">
                        Cancel
                      </Button>
                    </Link>
                    <Button
                      type="submit"
                      color="primary"
                      className="multiStepButton"
                      variant="contained"
                    >
                      SUBMIT
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Transfer;
