import { useState, useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";

import { LATime, LAStandardDate } from "../../utils/LA-time";
import { DataGrid } from "@mui/x-data-grid";


type calendarReturn = {
  _id: string;
  monthCounter: number;
  weekCounter: number;
  dateFrom: string;
  dateTo: string;
  year: number;
  __v: number;
};
const yearsFrom2021 = () => {
  const d = LATime();
  const currentYear = d.getFullYear();
  const accuYears: number[] = [];
  for (let x = 2021; x <= currentYear; x++) {
    accuYears.push(x);
  }
  return accuYears;
};

interface IWithdrawList{
  _id: string;
  requestorUserName: string;
  requestorPaymentGateWay: string;
  requestorAccountMobileNo: string;
  status: string;
  reference: string;
  amountRequested: number;
  amountPaid: number;
  dateRequested: string;
  dateReleased: string | null;
}
const WithdrawList: React.FC<{widthdrawList: IWithdrawList[]}> = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  //states
  const [years, setYears] = useState<number[]>(yearsFrom2021());
  const [currentYearDropdown, setCurrentYearDropdown] = useState<number>(
    parseInt(LAStandardDate().slice(0, 4))
  );
  const [nodeCurrentYear, setNodeCurrentYear] = useState<calendarReturn[]>([]);
  const [months, setMonths] = useState<number[]>([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
  ]);
  const [currentMonthDropdown, setCurrentMonthDropdown] = useState<number>(1);
  const [weeksOfMonths, setWeeksOfMonths] = useState<calendarReturn[]>([]);

  const [volumes, setVolumes] = useState<any>([]);
  const [members, setMembers] = useState<any>([]);
  const [loading, setIsLoading] = useState<boolean>(false);


  useEffect(() => {
    const getCurrentMonth = (response: calendarReturn[]) => {
      const now = LAStandardDate();
      for (const week of response) {
        if (
          now >= week.dateFrom.slice(0, 10) &&
          now <= week.dateTo.slice(0, 10)
        ) {
          return setCurrentMonthDropdown(week.monthCounter);
        }
      }
    };

    const getCurrentYearSequence = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/calendar/yearSequence`,

        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            year: currentYearDropdown,
          }),
        }
      );
      const jsonResponse = await response.json();
      getCurrentMonth(jsonResponse);
      setNodeCurrentYear(jsonResponse);
    };

    getCurrentYearSequence();
    console.log("1 shot");
  }, [currentYearDropdown]);

  useEffect(() => {
    const weekArr: calendarReturn[] = [];

    for (let week of nodeCurrentYear) {
      if (weekArr.length === 4) {
        break;
      }
      if (week.monthCounter === currentMonthDropdown) {
        weekArr.push(week);
      }
    }

    console.log(weekArr);
    setWeeksOfMonths(weekArr);
    //get volumes
    if (weekArr.length > 0) {
      const getVolumes = async () => {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/volumes/get`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "content-type": "application/json",
            },
            body: JSON.stringify(weekArr),
          }
        );
        const jsonResponse = await response.json();
        console.log("volumes");
        console.log(jsonResponse);
        setVolumes(jsonResponse);
      };
      getVolumes();
    }
  }, [currentMonthDropdown, nodeCurrentYear]);

  const columns = [
    {
      field: "_id",
      headerName: "Payout ID",
      flex: 1,
    },
    {
      field: "requestorUserName",
      headerName: "Receiver",
      flex: 1,
    },
    {
      field: "paymentGateway",
      headerName: "Bank/E-Wallet",
      flex: 1,
    },
    {
      field: "account",
      headerName: "Account",
      flex: 1,
    },
    {
      field: "amountRequested",
      headerName: "Amount Requested",
      flex: 1,
    },
    {
      field: "amountPaid",
      headerName: "Amount Paid",
      flex: 1,
    },
    {
      field: "dateRequested",
      headerName: "Date Requested",
      flex: 1,
    },
    {
      field: "dateReleased",
      headerName: "Date Released",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params: any) => (
        <span style={{ color: params.value === 'paid' ? 'green' : 'orange' }}>
          {params.value}
        </span>
      ),
    },
    {
      field: "referrence",
      headerName: "Referrence",
    },
  ];
  
  return (
    <Box
    m="40px 0 0 0"
    height="75vh"
    sx={{
      "& .MuiDataGrid-root": {
        border: "none", //outer border
      },
      "& .MuiDataGrid-cell": {
        // borderBottom: "none",
        borderX:`0.5px solid ${colors.grey[800]}`,
        
      },
      "& .name-column--cell": {
        // color: colors.greenAccent[300]
        color: "#de9728",
        
      },
      "& .MuiDataGrid-columnHeaders": {
        // backgroundColor: colors.blueAccent[700],
        backgroundColor: `${colors.grey[800]}`,
        borderBottom: "none",
      },
      "& .MuiDataGrid-virtualScroller": {
        backgroundColor: colors.primary[400],
      },
      "& .MuiDataGrid-footerContainer": {
        borderTop: "none",
        // backgroundColor: colors.blueAccent[700],
        backgroundColor: `${colors.grey[1200]}`,
      },
    }}
    textAlign="center"
  >
  
    <DataGrid
      rows={props.widthdrawList.map(
        (row: any) =>
          ({
            ...row,
            id: row._id,
            userName: row.requestorUserName,
            paymentGateway: row.requestorPaymentGateWay,
            account: row.requestorAccountMobileNo,
            amountRequested: '$'+ row.amountRequested.toFixed(2),
            amountPaid: '$'+ row.amountPaid.toFixed(2),
            dateRequested: row.dateRequested,
            dateReleased: row.dateReleased,
            status: row.status,
            referrence: row.reference,
          } as IWithdrawList)
      )}
      columns={columns}
      
    ></DataGrid>
  </Box>
  );
};

export default WithdrawList;
