import { useState, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import CalendarCard from "../../components/monthly-pv/cardCalendar/CalendarCard";
import { LATime, LAStandardDate } from "../../utils/LA-time";

type calendarReturn = {
  _id: string;
  monthCounter: number;
  weekCounter: number;
  dateFrom: string;
  dateTo: string;
  year: number;
  __v: number;
};
const yearsFrom2021 = () => {
  const d = LATime();
  const currentYear = d.getFullYear();
  const accuYears: number[] = [];
  for (let x = 2021; x <= currentYear; x++) {
    accuYears.push(x);
  }
  return accuYears;
};

const MonthlyPV = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  //states
  const [years, setYears] = useState<number[]>(yearsFrom2021());
  const [currentYearDropdown, setCurrentYearDropdown] = useState<number>(
    parseInt(LAStandardDate().slice(0, 4))
  );
  const [nodeCurrentYear, setNodeCurrentYear] = useState<calendarReturn[]>([]);
  const [months, setMonths] = useState<number[]>([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
  ]);
  const [currentMonthDropdown, setCurrentMonthDropdown] = useState<number>(1);
  const [weeksOfMonths, setWeeksOfMonths] = useState<calendarReturn[]>([]);

  const [volumes, setVolumes] = useState<any>([]);

  useEffect(() => {
    const getCurrentMonth = (response: calendarReturn[]) => {
      const now = LAStandardDate();
      for (const week of response) {
        if (
          now >= week.dateFrom.slice(0, 10) &&
          now <= week.dateTo.slice(0, 10)
        ) {
          return setCurrentMonthDropdown(week.monthCounter);
        }
      }
    };

    const getCurrentYearSequence = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/calendar/yearSequence`,

        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            year: currentYearDropdown,
          }),
        }
      );
      const jsonResponse = await response.json();
      getCurrentMonth(jsonResponse);
      setNodeCurrentYear(jsonResponse);
    };

    getCurrentYearSequence();
    console.log("1 shot");
  }, [currentYearDropdown]);

  useEffect(() => {
    const weekArr: calendarReturn[] = [];

    for (let week of nodeCurrentYear) {
      if (weekArr.length === 4) {
        break;
      }
      if (week.monthCounter === currentMonthDropdown) {
        weekArr.push(week);
      }
    }

    console.log(weekArr);
    setWeeksOfMonths(weekArr);
    //get volumes
    if (weekArr.length > 0) {
      const getVolumes = async () => {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/volumes/get`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "content-type": "application/json",
            },
            body: JSON.stringify(weekArr),
          }
        );
        const jsonResponse = await response.json();
        console.log("volumes");
        console.log(jsonResponse);
        setVolumes(jsonResponse);
      };
      getVolumes();
    }
  }, [currentMonthDropdown, nodeCurrentYear]);
  return (
    <Box
      gridColumn="span 12"
      // gridRow="span 4"
      gridRow={{ xs: "span 13", md: "span 7", lg: "span 4" }}
      bgcolor={colors.primary[400]}
      borderRadius="8px"
    >
      <Box
        mt="25px"
        p="0 30px"
        display="flex "
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Typography variant="h4" fontWeight="bold" color={colors.grey[100]}>
            Monthly PV
          </Typography>
          <Typography color={colors.grey[1300]}>
            Box Representation of your Weekly PV per month.
          </Typography>
        </Box>
        <Box>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              labelId="selectMonth"
              id="monthDropdown"
              value={currentMonthDropdown}
              label="Month"
              onChange={(event) => setCurrentMonthDropdown(+event.target.value)}
            >
              {months.map((month) => (
                <MenuItem value={month} key={month}>
                  Month {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small">Year</InputLabel>
            <Select
              labelId="selectYear"
              id="yearDropdown"
              value={currentYearDropdown}
              label="Year"
              onChange={(event) => setCurrentYearDropdown(+event.target.value)}
            >
              {years.map((year) => (
                <MenuItem value={year} key={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      <Box
        mt="25px"
        p="0 30px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {weeksOfMonths.map((week) => (
          <CalendarCard
            weekNumber={`Week ${week.weekCounter}`}
            dateFrom={
              new Date(week.dateFrom).toUTCString().slice(8, 11) +
              " " +
              new Date(week.dateFrom).toUTCString().slice(5, 7)
            }
            dateTo={
              new Date(week.dateTo).toUTCString().slice(8, 11) +
              " " +
              new Date(week.dateTo).toUTCString().slice(5, 7)
            }
            businessVolumes = {volumes}
            weekCounter = {week.weekCounter}
            key={week.weekCounter}
          />
        ))}
      </Box>
    </Box>
  );
};

export default MonthlyPV;
