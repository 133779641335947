import * as yup from "yup";
const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

export const ValidationUserSchema = yup.object().shape({
    firstName: yup.string().required("required"),
    middleName: yup.string().required("required"),
    lastName: yup.string().required("required"),
    userName: yup.string().required("required"),
    email: yup.string().email().required("required"),
    password: yup.string().min(8, "Password must be at least 8 characters").required("required"),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match').required("required"),
    gender: yup.string().required("required"),
    birthday: yup.date().required("required"),
    mobile: yup
        .string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("required"),
    country: yup.string().required("required"),
    region: yup.string()
        .when('country', {
            is: (val: any) => val !== 'ph',
            then: yup.string().notRequired(),
            otherwise: yup.string().required("required")
        }),
    province: yup.string()
        .when('country', {
            is: (val: any) => val !== 'ph',
            then: yup.string().notRequired(),
            otherwise: yup.string().required("required")
        }),
    city: yup.string()
        .when('country', {
            is: (val: any) => val !== 'ph',
            then: yup.string().notRequired(),
            otherwise: yup.string().required("required")
        }),
    barangay: yup.string()
        .when('country', {
            is: (val: any) => val !== 'ph',
            then: yup.string().notRequired(),
            otherwise: yup.string().required("required")
        }),
    phAddress: yup.string()
        .when('country', {
            is: (val: any) => val !== 'ph',
            then: yup.string().notRequired(),
            otherwise: yup.string().required("required")
        }),
        shippingAddress:yup.string().required("required"),
        shippingCountry:yup.string().required("required"),
    phZipCode: yup.string()
        .when('country', {
            is: (val: any) => val !== 'ph',
            then: yup.string().notRequired(),
            otherwise: yup.string().required("required")
        }),
    usauAddress: yup.string()
        .when('country', {
            is: (val: any) => val !== 'ph',
            then: yup.string().required("required"),
            otherwise: yup.string().notRequired()
        }),
    usauCity: yup.string()
        .when('country', {
            is: (val: any) => val !== 'ph',
            then: yup.string().required("required"),
            otherwise: yup.string().notRequired()
        }),
    usauState: yup.string()
        .when('country', {
            is: (val: any) => val !== 'ph',
            then: yup.string().required("required"),
            otherwise: yup.string().notRequired()
        }),
    usauZipCode: yup.string()
        .when('country', {
            is: (val: any) => val !== 'ph',
            then: yup.string().required("required"),
            otherwise: yup.string().notRequired()
        }),
    package: yup.string().required("required"),
    enrollmentDate: yup.date().required("required"),

});


export const ValidationSponsorSchema = yup.object().shape({
    directSponsorUserName: yup.string().required("required"),
    upLineUserName: yup.string().required("required"),
    position: yup.string().required("required")
});