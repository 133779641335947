import { useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import UserContext from "../store/user-context";
import NodeContext from "../store/node-context";
import { RegionContext } from "../store/region-context";
import { ProvinceContext } from "../store/province-context";
import { CityContext } from "../store/city-context";
import { BarangayContext } from "../store/barangay-context";
import { CountryContext } from "../store/country-context";
import { HermonPackageContext } from "../store/hermon-package-context";
import { EnrolleeContext } from "../store/enrolle-context";
import { PackageContext } from "../store/package-context";
import { SalesContext } from "../store/sales-context";
const Logout = () => {
  const { setUser } = useContext(UserContext);
  const { setNodeId } = useContext(NodeContext);
  const { setRegionCode } = useContext(RegionContext);
  const { setProvinceCode } = useContext(ProvinceContext);
  const { setCityCode } = useContext(CityContext);
  const { setBarangayCode } = useContext(BarangayContext);
  const { setCountryCode } = useContext(CountryContext);
  const { setHermonPackage } = useContext(HermonPackageContext);
  const { setEnrollee } = useContext(EnrolleeContext);
  const { setCurrentPackage } = useContext(PackageContext);
  const { setSale, setShippingFee } = useContext(SalesContext);
  useEffect(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("paymentMethodId");
    localStorage.removeItem("clientKey");
    localStorage.removeItem("paymentIntentId");
    setUser({
      id: "",
      isAdmin: false,
      firstName: "",
      lastName: "",
      gender: "",
      username: "",
    });
    setNodeId("");
    setRegionCode("");
    setProvinceCode("");
    setCityCode("");
    setBarangayCode("");
    setCountryCode("");
    setHermonPackage("");
    setEnrollee({});
    setCurrentPackage("");
    setSale({});
    setShippingFee("");
  });
  // [
  //   setUser,
  //   setNodeId,
  //   setRegionCode,
  //   setProvinceCode,
  //   setCityCode,
  //   setBarangayCode,
  //   setCountryCode,
  //   setHermonPackage,
  //   setEnrollee,
  //   setCurrentPackage,
  //   setSale,
  //   setShippingFee,
  // ]
  return <Navigate to="/login" />;
};

export default Logout;
