import React, {useState, useEffect} from "react";
import { useContext } from "react";
import { Box, Typography, useTheme} from "@mui/material";
import { useParams } from 'react-router-dom';
import UserContext from "../../store/user-context";


import { tokens } from "../../theme";
import Header from "../../components/Header";

const Success = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { type } = useParams();
    const {user} = useContext(UserContext);
    const [message, setMessage] = useState<string>("")
    
    useEffect(()=>{
        if(type === 'enrollee'){
            setMessage('Congratulations Enrollment Successful!');
        }else if(type === 'upgrade' && user.currentPackage !== "Legacy"){
            setMessage('Congratulations Upgrade Successful!');
        }else{
            setMessage('Congratulations Purchase Successful!');
        }

    },[type])
        return (
            <Box m="20px">
                {/* HEADER */}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title="CONGRATULATIONS" subtitle="Successful" />
                </Box>
                <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gridAutoRows="140px"
                    gridTemplateRows="auto auto 1fr 1fr 1fr auto auto"
                    gap="20px"
                >
                    <Box
                        gridColumn={{ xs: "span 12", md: "span 12" }}
                        bgcolor={colors.primary[400]}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="8px"
                        paddingX="40px"
                        height="100px"
                    >
                        <Typography
                                    variant="h3"
                                    fontWeight="700"
                                    color="green"
                                >
                                        {message}
                                    </Typography>
                    </Box>
                </Box>
            </Box>)
    
}

export default Success;