import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../theme";

const Header: React.FC<{ title: string; subtitle: string }> = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box mb="30px">
      <Typography
        variant="h2"
        color={colors.grey[100]}
        fontWeight= "100"
        sx={{ mb: "5px" }}
      >
        {props.title}
      </Typography>
      <Typography variant="h5" color={colors.grey[1300]}>
        {props.subtitle}
      </Typography>
    </Box>
  );
};

export default Header;
