import { useState} from 'react';
import { Box, Stepper, Step, StepLabel } from "@mui/material";
import { Formik, Form, FormikConfig, FormikValues, FormikHelpers} from "formik";


import React from "react";

import FormNavigation from './FormNavigation';



interface Props extends FormikConfig<FormikValues> {
    children: React.ReactNode;
}

const MultiStepForm = ({ children, initialValues, onSubmit }: Props) => {

    const [stepNumber, setStepNumber] = useState(0);
    const steps = React.Children.toArray(children) as React.ReactElement[];


    const [snapshot, setSnapshot] = useState(initialValues);

    const step = steps[stepNumber];
    const totalSteps = steps.length;
    const isLastStep = stepNumber === totalSteps - 1;

    const next = (values: FormikValues) => {
        setSnapshot(values);
        setStepNumber((current) => current + 1);
    }

    const previous = (values: FormikValues) => {
        setSnapshot(values);
        setStepNumber(current => current - 1);
    }

    const handleFormSubmit = async (values: FormikValues, actions: FormikHelpers<FormikValues>) => {
        if (step.props.onSubmit) {
            await step.props.onSubmit(values);
        }

        if (isLastStep) {
            return onSubmit(values, actions);
        } else {
            actions.setTouched({});
            next(values);
        }
    }
   
    return (<Box m="40px">
        <Formik initialValues={snapshot} onSubmit={handleFormSubmit} validationSchema={step.props.validationSchema} >
            {formik => <Form>
                <Stepper activeStep={stepNumber}>
                    {steps.map((currentStep) => {
                        const label = currentStep.props.stepName;

                        return <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    })}
                </Stepper>
                {step}
                <FormNavigation
                    isLastStep={isLastStep}
                    hasPrevious={stepNumber > 0}
                    onBackClick={() => previous(formik.values)}
                />
            </Form>}
        </Formik>
    </Box>)
}

export default MultiStepForm;

export const FormStep = ({ stepName = '', children }: any) => children;

