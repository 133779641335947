import { useState, useEffect, useContext, FormEvent, useRef } from "react";
import { Link } from "react-router-dom";
import UserContext from "../../store/user-context";
import { Box, useTheme, Button, Typography, TextField } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import "./Members.css";
import Loading from "../../components/ui/RingLoader";
import NodeContext from "../../store/node-context";

const Members = () => {
  //  useUserContext();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useContext(UserContext);
  const { nodeId, setNodeId } = useContext(NodeContext);
  const [userName, setUserName] = useState<string>("");
  const [userNotFound, setUserNotFound] = useState<boolean>(false);
  const [members, setMembers] = useState<{}>({});
  // const [nodeId, setNodeId] = useState<string>(user.id); //id of the current user loggedin
  const [upLineId, setUpLineId] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //states

  //others

  //effects
  useEffect(() => {
    setIsLoading(true);

    async function getGenealogy() {
      const url = process.env.REACT_APP_API_URL + "/genealogy";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          userId: nodeId,
        }),
      });
      const jsonResponse = await response.json();

      setMembers(jsonResponse);

      if (jsonResponse.upLineId) {
        setUpLineId(jsonResponse.upLineId);
      }
      setIsLoading(false);
    }
    getGenealogy();
    // setIsLoading(false);
  }, [nodeId]);

  //handlers
  const handleNodeId = (userId: string, upLineId: string, userName: string) => {
    setNodeId(userId);
    setUpLineId(upLineId);
  };

  const handlemoveUp = () => {
    setNodeId(upLineId);
  };

  const handleSearchSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (userName.trim() === "") {
      return;
    }
    setIsLoading(true);

    async function searchGenealogy() {
      const url = process.env.REACT_APP_API_URL + "/genealogy/userSearch";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          userId: user.id,
          userName: userName,
        }),
      });
      const jsonResponse = await response.json();

      if (jsonResponse) {
        setNodeId(jsonResponse.userId);
        setUserNotFound(false);
      } else {
        setUserNotFound(true);
      }
      setIsLoading(false);
    }
    searchGenealogy();
    // setIsLoading(false);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target?.value);
  };
  // ...code here
  const renderNode = (node: any, level: number = 0) => {
    if (!node || level > 3) {
      return null;
    }

    return (
      <li value={node.userId}>
        <span
          onClick={() =>
            handleNodeId(node.userId, node.upLineId, node.userName)
          }
        >
          <div className="treeUserProfile">
            <div>
              <img
                alt="boy or girl"
                src="../../assets/default_avatar.png"
                className="treeImg"
              />
            </div>
            <div>
              <div>{`${node.firstName} ${node.lastName}`}</div>
              <div>({node.userName})</div>
            </div>
          </div>
        </span>

        {level < 3 && (
          <ul>
            {renderNode(node.left, level + 1) ? (
              <li>{renderNode(node.left, level + 1)}</li>
            ) : (
              <li>
                <span className="linkSpan">
                  <Link
                    to={`/addedituser/${node.userName}/left/${user.username}`}
                    className="addLink"
                  >
                    Add
                  </Link>
                </span>
              </li>
            )}
            {renderNode(node.right, level + 1) ? (
              <li>{renderNode(node.right, level + 1)}</li>
            ) : (
              <li>
                <span className="linkSpan">
                  <Link
                    to={`/addedituser/${node.userName}/right/${user.username}`}
                    className="addLink"
                  >
                    Add
                  </Link>
                </span>
              </li>
            )}
          </ul>
        )}
      </li>
    );
  };

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="MEMBERS" subtitle="Welcome to your members tree" />
      </Box>
      {/* GRID & CHARTS */}
      <Box
        display="grid"
        // gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="900px"
        overflow="auto"
        gap="20px"
      >
        {/* ROW 1 */}

        <Box
          gridColumn={{ lg: "span 12" }}
          bgcolor={colors.primary[400]}
          display="flex"
          alignItems="top"
          justifyContent="center"
          borderRadius="8px"
          sx={{ minWidth: "1300px", minHeight: "1050px", paddingTop: "20px" }}
        >
          {isLoading ? (
            <Loading />
          ) : (
            <Box className="tree" textAlign="center" sx={{ padding: "10px" }}>
              <Box
                display="flex"
                sx={{
                  width: "700px",
                  alignItems: "center",
                  justifyContent:"space-between",
                  marginX: "auto",
                  marginBottom:"20px"
                }}
              >
                <Typography variant="h5" fontWeight="700" color={colors.grey[300]}>
                  LEFT
                </Typography>
                <Typography variant="h5" fontWeight="700" color={colors.grey[300]}>
                  RIGHT
                </Typography>
              </Box>
              <Box
                display="flex"
                sx={{
                  flexDirection: "column",
                  maxWidth: "350px",
                  alignItems: "center",
                  marginX: "auto",
                }}
              >
                {userNotFound && (
                  <Typography variant="h5" fontWeight="400" color="red">
                    Username not found
                  </Typography>
                )}
                <form onSubmit={handleSearchSubmit}>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Username"
                    name="userName"
                    value={userName}
                    onChange={handleChange}
                  />
                  <Button
                    type="submit"
                    color="primary"
                    className="multiStepButton"
                    variant="contained"
                    sx={{ marginY: "20px" }}
                  >
                    Search
                  </Button>
                </form>
              </Box>
              {nodeId !== user.id && (
                <ArrowCircleUpIcon
                  sx={{ fontSize: "40px" }}
                  className="arrowUp"
                  onClick={handlemoveUp}
                />
              )}
              <ul>{renderNode(members)}</ul>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Members;
