import { useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Maintenance from "../../components/forms/Maintenance";

import RetailStoreComponent from "../../components/retail-store/RetailStoreComponent";
const RetailStore = () => {
const location = useLocation();

   console.log('Current route:', location.pathname);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(()=>{
    if(localStorage.getItem("token") && location.pathname !== "/insideRetailStore"){
      navigate("/login");
    }
  },[])
  return <RetailStoreComponent />
  // return <Maintenance />
}

export default RetailStore;