import { useContext } from "react";
import { Typography } from "@mui/material";
import { Navigate } from "react-router-dom";
import UserContext from "../../store/user-context";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import "./Login.css";
import LoginForm from "../../components/forms/LoginForm";
import Maintenance from "../../components/forms/Maintenance";
const Login = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useContext(UserContext);

  return user.id !== "" && !user.isAdmin ? (
    //   <Navigate to="/" /> :
    //   (user.id !== '' && !user.isAdmin) ?
    <Navigate to="/user" />
  ) : (
    <Box sx={{ backgroundImage: "url(../../assets/mountain.jpg)" }}>
      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        // gap="20px"
        height="100vh"
      >
        {/* ROW 1 */}
        <Box
          gridColumn={{ lg: "span 7", md: "span 5" }}
          className="hermonLoginPicture"
          bgcolor="white"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ opacity: "0%" }}
          height="100vh"
        >
          <div className="loginLogo">
            {/* <img src="../../assets/hermonLogo.png" alt="Logo" /> */}
          </div>
        </Box>
        
        <Box
          gridColumn={{
            lg: "span 5",
            md: "span 7",
            sm: "span 12",
            xs: "span 12",
          }}
          bgcolor={colors.primary[400]}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          // sx={{ opacity: "90%" }}
          height="100vh"
        >
          <Box sx={{ paddingBottom: "210px" }}>

          </Box>  
          {/* <LoginForm /> */}
          <Maintenance />

          <Typography variant="h5" fontWeight="200" color="#8C8C8C" sx={{ paddingTop:"300px" }}>
            Copyright &#169; 2022 Hermon Holistic Distribution Inc.
          </Typography>
          <Typography variant="h5" fontWeight="200" color="#8C8C8C">
            All rights reserved.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
