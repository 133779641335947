import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../theme";

const InnerCalendarBox: React.FC<{
  innerLeftRight: { left: string; right: string },
  value: {left: number, right: number},
  withRadius: boolean
}> = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{ p: "12px 0" }}
      bgcolor={colors.grey[1000]}
      // borderRadius="8px"
      // marginBottom="12px"
      borderRadius={ props.withRadius ? '6px 6px 0 0': '0 0 0 0'}
    >
      <Box width="50%">
        <Typography textAlign="center" variant="h5">
          {props.innerLeftRight.left && props.innerLeftRight.left}
        </Typography>
        {props.value.left >= 0 ? <Typography textAlign="center" variant="h4" fontWeight="bold">
          {props.value.left}
        </Typography>: ""}
      </Box>
      <Box width="50%" textAlign="center">
        <Typography textAlign="center" variant="h5">
          {props.innerLeftRight.right && props.innerLeftRight.right}
        </Typography>
        {props.value.right >= 0 ? <Typography textAlign="center" variant="h4" fontWeight="bold">
          {props.value.right}
        </Typography>: ""}
      </Box>
    </Box>
  );
};

export default InnerCalendarBox;
