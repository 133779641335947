import { useEffect, useState, useContext } from "react";
import UserContext from "../../store/user-context";
import { Box, useTheme, TextField, Typography, Button } from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import Loading from "../../components/ui/RingLoader";
import moment from "moment-timezone";

export const SalesReportRetail = () => {
  const [token, setToken] = useState<any>(localStorage.getItem("token"));
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [sales, setSales] = useState<any>([]);
  const [loading, setIsLoading] = useState<boolean>(false);
  const { user } = useContext(UserContext);
  const[dateFrom, setDateFrom] = useState<string>("");
  

  const handleSearch = () => {
    console.log("HELLO WORLD");
  }
  const handleDateFrom = (event: React.ChangeEvent<HTMLInputElement>) => {
    
    const value = event.target.value;
    console.log(value);
    setDateFrom(value);
  };
  useEffect(() => {
    setIsLoading(true);
    if (token) {
      async function fetchData() {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/report`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              dateFrom: "2023-06-01",
              dateTo: "2023-06-30",
              isStoreManager: false,
            }),
          }
        );

        const jsonResponse = await response.json();
        setSales(jsonResponse);
        setIsLoading(false);
      }
      fetchData();
    }
  }, []);
  const columns = [
    {
      field: "datePurchased",
      headerName: "Date Purchased",
      flex: 2,
      renderCell: (params: any) => {
        return (
          <div
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              textAlign: "left",
            }}
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "Buyer",
      flex: 2,
      renderCell: (params: any) => {
        return (
          <div
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              textAlign: "left",
            }}
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
      renderCell: (params: any) => {
        return (
          <div
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              textAlign: "left",
            }}
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "shippingAddress",
      headerName: "Address",
      flex: 2,
      renderCell: (params: any) => {
        return (
          <div
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              textAlign: "left",
            }}
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "contactNo",
      headerName: "Contact #",
      flex: 2,
      renderCell: (params: any) => {
        return (
          <div
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              textAlign: "left",
            }}
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "product",
      headerName: "Product",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              textAlign: "left",
            }}
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "afiliate",
      headerName: "Afiliate Fr",
      flex: 2,
      renderCell: (params: any) => {
        return (
          <div
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              textAlign: "left",
            }}
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "accountType",
      headerName: "Account Type",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              textAlign: "left",
            }}
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "userName",
      headerName: "User Name",
      flex: 2,
      renderCell: (params: any) => {
        return (
          <div
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              textAlign: "left",
            }}
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "quantity",
      headerName: "Qty(in bottles)",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              textAlign: "left",
            }}
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "productCost",
      headerName: "Product Cost",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              textAlign: "left",
            }}
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "shippingFee",
      headerName: "Shipping Fee",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              textAlign: "left",
            }}
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "accountCost",
      headerName: "Account Cost",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              textAlign: "left",
            }}
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "total",
      headerName: "Total",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              textAlign: "left",
            }}
          >
            {params.value}
          </div>
        );
      },
    },
  ];
  interface ft {
    _id: string;
    description: string;
    date: string;
    status: string;
  }
  return (
    <Box m="20px">
      <Header
        title="SALES REPORT RETAIL PROGRAM"
        subtitle="Collection of purchased history and sales report"
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Box>
            <Typography
              variant="h5"
              marginTop="10px"
              fontWeight="bold"
              sx={{ color: colors.grey[500] }}
            >
              From
            </Typography>
            <TextField type="date" id="dateFrom" name="dateFrom" value={dateFrom} onChange={handleDateFrom} />
          </Box>

          <Box sx={{ marginLeft: "20px" }}>
            <Typography
              variant="h5"
              marginTop="10px"
              fontWeight="bold"
              sx={{ color: colors.grey[500] }}
            >
              To
            </Typography>
            <TextField type="date" id="dateTo" name="dateTo" />
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end", marginTop: "10px" }}>
          <Button
            type="button"
            variant="contained"
            className="multiStepButton"
            onClick={handleSearch}
            sx={{ marginTop: "16px" }}
          >
            SEARCH
          </Button>
        </Box>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none", //outer border
          },
          "& .MuiDataGrid-cell": {
            borderX: `0.5px solid ${colors.grey[800]}`,
          },
          "& .name-column--cell": {
            color: "#de9728",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: `${colors.grey[800]}`,
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            // backgroundColor: colors.blueAccent[700],
            backgroundColor: `${colors.grey[1200]}`,
          },
        }}
        textAlign="center"
      >
        {loading && <Loading />}
        <DataGrid
          rows={sales.map(
            (row: any) =>
              ({
                ...row,
                id: row._id,
                datePurchased: moment(row.datePurchased).format("MMM DD, YYYY"),
                name: row.senderFullName.toUpperCase(),
                email: row.transactionEmail,
                status: row.status,
                shippingAddress: row.shippingAddress,
                shippingCountry: row.shippingCountry,
                contactNo: row.contactNumberSales
                  ? row.contactNumberSales
                  : row.contactNumberUser,
                quantity: row.quantity,
                product: row.product,
                afiliate: row.receiverUserName,
                paymentMode: row.paymentMode,
                accountType: row.accountType,
                paymentGateway: row.paymentGateway,
                userName: row.userName,
                productCost: row.productCost + "$",
                shippingFee: row.shippingFee + "$",
                accountCost: row.enrollmentFee + "$",
                total: row.total + "$",
              } as ft)
          )}
          columns={columns}
        ></DataGrid>
      </Box>
    </Box>
  );
};

export default SalesReportRetail;
