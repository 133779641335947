import { useEffect, useContext, useState } from "react";
import { PackageContext } from "../store/package-context";


const usePackage = () => {
  console.log('did it change here?')
    const {currentPackage} = useContext(PackageContext);
    const [hermonPackage, setHermonPackage] = useState<string>("");
    useEffect(()=>{
        if(currentPackage === 1){
            setHermonPackage('Starter')
          }else if(currentPackage === 3){

            setHermonPackage('Business')
          }else if(currentPackage === 6){
            setHermonPackage('Premiere')
          }else if(currentPackage === 10){
            setHermonPackage('Achiever')
          }else if(currentPackage === 20){
            setHermonPackage('Legacy')
          }
    },[currentPackage])

    return hermonPackage;
}

export default usePackage;