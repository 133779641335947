import { Box, Typography, useTheme } from "@mui/material";
import {
  RingLoader,
  CircleLoader,
  DotLoader,
  ClockLoader,
  BarLoader,
} from "react-spinners";
import { tokens } from "../../theme";
import "./RingLoader.css";

const Loading = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    //   <Box textAlign="center" sx =  {{ display: "flex", flexDirection: "column", alignItems: "center" }}>
    //   <CircleLoader size={120} color={"#de9728"} loading={true} />
    //   {/* <BarLoader  color={"#de9728"} loading={true} /> */}

    //   <Typography
    //     variant="h5"
    //     fontWeight="600"
    //     color={colors.grey[100]}
    //   >
    //     Please Wait...
    //   </Typography>
    // </Box>
    <div className="lds-ring" >
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Loading;
