import { useState, useEffect, useContext } from "react";
import { Button, useTheme, Typography } from "@mui/material";
import { tokens } from "../../theme";
import { useNavigate, useParams, Link } from "react-router-dom";
import RetailStoreContext from "../../store/retail-store-context";
import UserContext from "../../store/user-context";
import "./RetailStore.css";
import Swal from "sweetalert2";
import { dollarPesoConversion } from "../../utils/dollar-peso-conversion";

const RetailStoreComponent = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const currentUrl = window.location.href;
  const retailMode = currentUrl.split("/");
  const navigate = useNavigate();
  const { id } = useParams();

  const [probioXTotal, setProbioXTotal] = useState<number>(0);
  const [probioxQty, setProbioxQty] = useState<number>(0);
  const [metaXTotal, setMetaXTotal] = useState<number>(0);
  const [metaxQty, setMetaxQty] = useState<number>(0);
  const [enrollmentFee, setEnrollmentFee] = useState<number>(19.95);
  const [shippingFee, setShippingFee] = useState<number>(0);
  const [showPassword, setShowPassword] = useState(false);
  const [freeShipping, setFreeShipping] = useState(false);
  const [promoCodeFound, setPromoCodeFound] = useState(false);
  const [promoButtonEnabled, setPromoButtonEnabled] = useState(false);
  const handleTogglePassword = () => setShowPassword(!showPassword);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
    confirmPassword: "",
    mobileNo: "",
    email: "",
    shippingCountry: "",
    address: "",
    city: "",
    stateCode: "",
    zipCode: "",
    paypalAccount: "",
    promoCode: "",
  });
  const { setRetail } = useContext(RetailStoreContext);
  const { user } = useContext(UserContext);

  const handleProbioX = (isAdd: boolean) => {
    if (isAdd) {
      setProbioXTotal((val) => val + 32.5);
      setProbioxQty((val) => val + 1);
    } else {
      if (probioxQty - 1 < 0) {
        return;
      }
      setProbioXTotal((val) => val - 32.5);
      setProbioxQty((val) => val - 1);
    }
  };

  const handleMetaX = (isAdd: boolean) => {
    if (isAdd) {
      setMetaXTotal((val) => val + 32.5);
      setMetaxQty((val) => val + 1);
    } else {
      if (metaxQty - 1 < 0) {
        return;
      }
      setMetaXTotal((val) => val - 32.5);
      setMetaxQty((val) => val - 1);
    }
  };

  const handlePromoCode = async () => {
    if (!promoButtonEnabled) {
      return;
    }
    if (formData.shippingCountry === "PH") {
      let url = `${process.env.REACT_APP_API_URL}/sale/getpromo`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          promoCode: formData.promoCode,
        }),
      });
      const jsonResponse = await response.json();
      console.log(jsonResponse);
      if (jsonResponse.freeShipping) {
        setShippingFee(0);
        setFreeShipping(true);
        setPromoCodeFound(true);
      } else {
        setFreeShipping(false);
      }
    } else {
      //other codes here when there are additional promo codes
      setFreeShipping(false);
      setPromoCodeFound(false);
    }
  };

  useEffect(() => {
    const country = formData.shippingCountry;

    if (country !== "PH") {
      if (probioxQty + metaxQty === 0 || freeShipping) {
        setShippingFee(0);
      } else if (probioxQty + metaxQty > 0 && probioxQty + metaxQty <= 2) {
        setShippingFee(13.25);
      } else if (probioxQty + metaxQty >= 3 && probioxQty + metaxQty <= 6) {
        setShippingFee(17.25);
      } else if (probioxQty + metaxQty >= 7 && probioxQty + metaxQty <= 10) {
        setShippingFee(25.15);
      }
    } else {
      const conversion = async () => {
        const dollarToPesos: number = await dollarPesoConversion();
        let toShipFee = 0;
        if (probioxQty + metaxQty === 0 || freeShipping) {
          setShippingFee(0);
        } else if (
          probioxQty + metaxQty > 0 &&
          probioxQty + metaxQty <= 3 &&
          !freeShipping
        ) {
          toShipFee = parseFloat((120 / dollarToPesos).toFixed(2));
          console.log("TO SHIP FEE");
          console.log(dollarToPesos);
        } else if (
          probioxQty + metaxQty >= 4 &&
          probioxQty + metaxQty <= 6 &&
          !freeShipping
        ) {
          toShipFee = Number((190 / dollarToPesos).toFixed(2));
        } else if (
          probioxQty + metaxQty >= 7 &&
          probioxQty + metaxQty <= 10 &&
          !freeShipping
        ) {
          toShipFee = Number((300 / dollarToPesos).toFixed(2));
        }

        setShippingFee(toShipFee);
      };
      conversion();
    }
  }, [probioxQty, metaxQty, formData.shippingCountry]);

  useEffect(() => {
    if (retailMode[4] !== "enroll") {
      if (
        formData.firstName.trim() !== "" &&
        formData.lastName.trim() !== "" &&
        formData.mobileNo.trim() !== "" &&
        formData.email.trim() !== "" &&
        formData.shippingCountry.trim() !== "" &&
        formData.address.trim() !== "" &&
        formData.city.trim() !== "" &&
        formData.stateCode.trim() !== "" &&
        formData.zipCode.trim() !== ""
      ) {
        setPromoButtonEnabled(true);
      } else {
        setPromoButtonEnabled(false);
      }
    } else {
      if (
        formData.firstName.trim() !== "" &&
        formData.lastName.trim() !== "" &&
        formData.userName.trim() !== "" &&
        formData.password.trim() !== "" &&
        formData.confirmPassword.trim() !== "" &&
        formData.mobileNo.trim() !== "" &&
        formData.email.trim() !== "" &&
        formData.shippingCountry.trim() !== "" &&
        formData.address.trim() !== "" &&
        formData.city.trim() !== "" &&
        formData.stateCode.trim() !== "" &&
        formData.zipCode.trim() !== "" &&
        formData.paypalAccount.trim() !== ""
      ) {
        setPromoButtonEnabled(true);
      } else {
        setPromoButtonEnabled(false);
      }
    }
  }, [formData, retailMode]);

  const handleSubmitLoggedIn = (event: any) => {
    event.preventDefault();
    //  check minimum quantity of 1
    if (probioxQty + metaxQty === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Quantity should be atleast 1",
      });
      return;
    }
    async function checkUser() {
      let url = `${process.env.REACT_APP_API_URL}/retailStore/checkUser/${user.username}`;
      const response = await fetch(url);
      const jsonResponse = await response.json();
      if (jsonResponse.userDontExist) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Retail store don't exist. Please coordinate with your reseller and get the right link",
        });
        return;
      }
      setRetail({
        //   ...formData,
        totalAmount:
          retailMode[4] === "enroll"
            ? (probioXTotal + metaXTotal + shippingFee + enrollmentFee).toFixed(
                2
              )
            : (probioXTotal + metaXTotal + shippingFee).toFixed(2),
        storeType: retailMode[4] === "enroll" ? "retail-enroll" : "retail",
        probioXQty: probioxQty,
        metaXQty: metaxQty,
        probioXamount: probioXTotal,
        metaXAmount: metaXTotal,
        shippingFee: shippingFee,
      });
      navigate(`/package/payment/${user.username}`);
    }
    checkUser();
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    //check all required fields for buyer
    if (
      (formData.firstName === "" ||
        formData.lastName === "" ||
        formData.email === "" ||
        formData.mobileNo === "" ||
        formData.shippingCountry === "" ||
        formData.address === "" ||
        formData.city === "" ||
        formData.stateCode === "" ||
        formData.zipCode === "") &&
      retailMode[4] !== "enroll"
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill-up all required fields",
      });
      return;
    }
    //check all required fields for enrollment
    if (
      (formData.firstName === "" ||
        formData.lastName === "" ||
        formData.email === "" ||
        formData.mobileNo === "" ||
        formData.shippingCountry === "" ||
        formData.address === "" ||
        formData.city === "" ||
        formData.stateCode === "" ||
        formData.zipCode === "" ||
        formData.userName === "" ||
        formData.password === "" ||
        formData.confirmPassword === "" ||
        formData.paypalAccount === "") &&
      retailMode[4] === "enroll"
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill-up all required fields",
      });
      return;
    }
    //check if username is valid in enroll
    if (formData.userName.includes(" ") && retailMode[4] === "enroll") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Username should have no spaces",
      });
      return;
    }
    //check if password is valid
    if (
      formData.password.trim() !== formData.confirmPassword.trim() &&
      retailMode[4] === "enroll"
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Password did not matched. Please double check",
      });
      return;
    }
    //check if email exist

    //check minimum quantity of 1
    if (probioxQty + metaxQty === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Quantity should be atleast 1",
      });
      return;
    }

    async function checkUser() {
      let url = `${process.env.REACT_APP_API_URL}/retailStore/checkUser/${id}`;
      if (!id) {
        url = `${process.env.REACT_APP_API_URL}/retailStore/checkUser`;
      }
      const response = await fetch(url);
      const jsonResponse = await response.json();
      if (jsonResponse.userDontExist) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Retail store don't exist. Please coordinate with your reseller and get the right link",
        });
        return;
      }
      //here is the new async
      if (retailMode[4] === "enroll") {
        let url = `${process.env.REACT_APP_API_URL}/retailStore/checkEmailUserName`;
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            email: formData.email,
            userName: formData.userName,
          }),
        });
        const jsonResponse = await response.json();
        if (jsonResponse.emailExist) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Email exists on our database. Please use a different email",
          });
          return;
        }
        if (jsonResponse.userNameExist) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Username exists on our database. Please use a different username",
          });
          return;
        }
      }

      //end here
      setRetail({
        ...formData,
        totalAmount:
          retailMode[4] === "enroll"
            ? (probioXTotal + metaXTotal + shippingFee + enrollmentFee).toFixed(
                2
              )
            : (probioXTotal + metaXTotal + shippingFee).toFixed(2),
        storeType: retailMode[4] === "enroll" ? "retail-enroll" : "retail",
        probioXQty: probioxQty,
        metaXQty: metaxQty,
        probioXamount: probioXTotal,
        metaXAmount: metaXTotal,
        shippingFee: shippingFee,
      });

      if (id) {
        navigate(`/package/payment/${id}`);
      } else {
        navigate("/package/payment");
      }
    }
    checkUser();
  };
  const handleInputChange = (event: any) => {
    // Update the form data state when an input value changes
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <section
      className={!localStorage.getItem("token") ? "h-40" : "h-40 mt-4"}
      style={{ backgroundColor: colors.primary[400] }}
    >
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-40">
          <div className="col">
            <div className="card">
              <div className="card-body p-4">
                <div className="row">
                  <div className="col-lg-7">
                    {!localStorage.getItem("token") && (
                      <img
                        src="/assets/logoMountain.png"
                        className="img-fluid rounded-3"
                        alt="Shopping item"
                      />
                    )}
                    <h5 className="mb-3">
                      {localStorage.getItem("token") ? "My Store" : "Products"}
                    </h5>
                    {metaxQty + probioxQty >= 10 && (
                      <Typography
                        variant="h5"
                        marginTop="10px"
                        fontWeight="bold"
                        sx={{ color: colors.redAccent[500] }}
                      >
                        Maximum Quantity Reached.
                      </Typography>
                    )}
                    <hr />
                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex flex-row align-items-center">
                            <div>
                              <img
                                src="/assets/Group20.png"
                                className="img-fluid rounded-3"
                                alt="ProbioX bottle"
                                style={{ width: "65px" }}
                              />
                            </div>
                            <div className="ms-3">
                              <h5>ProbioX</h5>
                              <p className="small mb-0">$32.50/Bottle</p>
                            </div>
                          </div>

                          <div className="d-flex flex-column justify-content-center">
                            <div
                              className="d-flex"
                              style={{ marginBottom: "8px" }}
                            >
                              <button
                                type="button"
                                className="btn btn-dark btn-xs"
                                style={{ alignSelf: "center" }}
                                onClick={() => handleProbioX(false)}
                              >
                                -
                              </button>
                              <h5
                                className="mb-0"
                                style={{
                                  alignSelf: "center",
                                  marginLeft: "6px",
                                  marginRight: "6px",
                                }}
                              >
                                {probioxQty}
                              </h5>
                              <button
                                type="button"
                                className="btn btn-dark"
                                style={{ alignSelf: "center" }}
                                onClick={() => handleProbioX(true)}
                                disabled={
                                  probioxQty + metaxQty >= 10 ? true : false
                                }
                              >
                                +
                              </button>
                            </div>
                            <h5 className="mb-0">${probioXTotal.toFixed(2)}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex flex-row align-items-center">
                            <div>
                              <img
                                src="/assets/Group21.png"
                                className="img-fluid rounded-3"
                                alt="MetaX bottle"
                                style={{ width: "65px" }}
                              />
                            </div>
                            <div className="ms-3">
                              <h5>MetaX(Philippines Only)</h5>
                              <p className="small mb-0">$32.50/Bottle</p>
                            </div>
                          </div>

                          <div className="d-flex flex-column justify-content-center">
                            <div
                              className="d-flex"
                              style={{ marginBottom: "8px" }}
                            >
                              <button
                                type="button"
                                className="btn btn-dark btn-xs"
                                style={{ alignSelf: "center" }}
                                onClick={() => handleMetaX(false)}
                              >
                                -
                              </button>
                              <h5
                                className="mb-0"
                                style={{
                                  alignSelf: "center",
                                  marginLeft: "6px",
                                  marginRight: "6px",
                                }}
                              >
                                {metaxQty}
                              </h5>
                              <button
                                type="button"
                                className="btn btn-dark"
                                style={{ alignSelf: "center" }}
                                onClick={() => handleMetaX(true)}
                                disabled={
                                  probioxQty + metaxQty >= 10 ? true : false
                                }
                              >
                                +
                              </button>
                            </div>
                            <h5 className="mb-0">${metaXTotal.toFixed(2)}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!localStorage.getItem("token") && (
                      <Link to={id ? `/retailStore/${id}` : `/retailStore/`}>
                        <Button
                          type="submit"
                          variant="contained"
                          className="multiStepButton"
                        >
                          CANCEL
                        </Button>
                      </Link>
                    )}
                  </div>
                  <div className="col-lg-5">
                    <div className="card bg-primary text-white rounded-3">
                      <div className="card-body">
                        <p style={{ textAlign: "center" }}>
                          Please double check. Once checked out, your cart will
                          be emptied.
                        </p>
                        <hr className="my-4" />
                        <form
                          className="mt-4"
                          onSubmit={
                            !localStorage.getItem("token")
                              ? handleSubmit
                              : handleSubmitLoggedIn
                          }
                        >
                          {!localStorage.getItem("token") && (
                            <>
                              <div className="row mb-4">
                                <div className="col-md-6">
                                  <div className="form-outline form-white">
                                    <label
                                      className="form-label"
                                      htmlFor="firstName"
                                    >
                                      First Name*
                                    </label>
                                    <input
                                      type="text"
                                      id="firstName"
                                      name="firstName"
                                      value={formData.firstName}
                                      className="form-control"
                                      placeholder="First Name"
                                      onChange={handleInputChange}
                                      disabled={freeShipping ? true : false}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <label
                                    className="form-label col-md-6"
                                    htmlFor="lastName"
                                  >
                                    Last Name*
                                  </label>
                                  <input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    value={formData.lastName}
                                    className="form-control"
                                    placeholder="Last Name"
                                    onChange={handleInputChange}
                                    disabled={freeShipping ? true : false}
                                  />
                                </div>
                              </div>

                              <div className="form-outline form-white mb-4"></div>
                              <div className="row mb-4">
                                {retailMode[4] === "enroll" && (
                                  <div className="col-md-6">
                                    <div className="form-outline form-white">
                                      <label
                                        className="form-label"
                                        htmlFor="userName"
                                      >
                                        Preferred Username*
                                      </label>
                                      <input
                                        type="text"
                                        id="userName"
                                        name="userName"
                                        value={formData.userName}
                                        className="form-control"
                                        placeholder="Username"
                                        onChange={handleInputChange}
                                        disabled={freeShipping && retailMode[4] === "enroll" ? true : false}
                                      />
                                    </div>
                                  </div>
                                )}
                                <div className="col-md-6">
                                  <label
                                    className="form-label col-md-6"
                                    htmlFor="mobile No"
                                  >
                                    Mobile No*
                                  </label>
                                  <input
                                    type="number"
                                    id="mobileNo"
                                    name="mobileNo"
                                    value={formData.mobileNo}
                                    className="form-control"
                                    placeholder="MobileNo"
                                    onChange={handleInputChange}
                                    disabled={freeShipping ? true : false}
                                  />
                                </div>
                              </div>
                              {retailMode[4] === "enroll" && (
                                <div className="row mb-4">
                                  <div className="col-md-6">
                                    <div className="form-outline form-white">
                                      <label
                                        className="form-label"
                                        htmlFor="password"
                                      >
                                        PW*
                                      </label>

                                      <input
                                        type="password"
                                        id="password"
                                        name="password"
                                        value={formData.password}
                                        className="form-control"
                                        placeholder="Password"
                                        onChange={handleInputChange}
                                        disabled={freeShipping && retailMode[4] === "enroll" ? true : false}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <label
                                      className="form-label col-md-6"
                                      htmlFor="confirmPassword"
                                    >
                                      Confirm PW*
                                    </label>
                                    <input
                                      type="password"
                                      id="confirmPassword"
                                      name="confirmPassword"
                                      value={formData.confirmPassword}
                                      className="form-control"
                                      placeholder="Confirm Password"
                                      onChange={handleInputChange}
                                      disabled={freeShipping && retailMode[4] === "enroll" ? true : false}
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="row mb-4">
                                <div className="col-md-6">
                                  <div className="form-outline form-white">
                                    <label
                                      className="form-label"
                                      htmlFor="email"
                                    >
                                      Email*
                                    </label>
                                    <input
                                      type="email"
                                      id="email"
                                      name="email"
                                      value={formData.email}
                                      className="form-control"
                                      placeholder="Email"
                                      onChange={handleInputChange}
                                      disabled={freeShipping ? true : false}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <label
                                    className="form-label"
                                    htmlFor="shippingCountry"
                                  >
                                    Shipping Country*
                                  </label>
                                  <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    value={formData.shippingCountry}
                                    name="shippingCountry"
                                    onChange={handleInputChange}
                                    disabled={freeShipping ? true : false}
                                  >
                                    <option value="" selected>
                                      Please Select
                                    </option>
                                    <option value="AU">Australia</option>
                                    <option value="BH">Bahrain</option>
                                    <option value="CA">Canada</option>
                                    <option value="DK">Denmark</option>
                                    <option value="DE">Germany</option>
                                    <option value="GR">Greece</option>
                                    <option value="IN">India</option>
                                    <option value="IE">Ireland</option>
                                    <option value="IT">Italy</option>
                                    <option value="MY">Malaysia</option>
                                    <option value="MT">Malta</option>
                                    <option value="NZ">New Zealand</option>
                                    <option value="NO">Norway</option>
                                    <option value="PK">Pakistan</option>
                                    <option value="PH">Philippines</option>
                                    <option value="QA">Qatar</option>
                                    <option value="SA">Saudi Arabia</option>
                                    <option value="SG">Singapore</option>
                                    <option value="ZA">South Africa</option>
                                    <option value="ES">Spain</option>
                                    <option value="SE">Sweden</option>
                                    <option value="TH">Thailand</option>
                                    <option value="AE">
                                      United Arab Emirates
                                    </option>
                                    <option value="GB">United Kingdom</option>
                                    <option value="US">United States</option>
                                    <option value="VN">Vietnam</option>
                                  </select>
                                </div>
                              </div>
                              <div className="form-outline form-white mb-4">
                                <label className="form-label" htmlFor="address">
                                  Address(Apartment, unit, or street address
                                  number and street name)*
                                </label>
                                <input
                                  type="text"
                                  id="address"
                                  name="address"
                                  value={formData.address}
                                  className="form-control"
                                  placeholder="Address"
                                  onChange={handleInputChange}
                                  disabled={freeShipping ? true : false}
                                />
                              </div>
                              <div className="form-outline form-white mb-4">
                                <label className="form-label" htmlFor="city">
                                  City(Province or Territory if outside USA)*
                                </label>
                                <input
                                  type="text"
                                  id="city"
                                  name="city"
                                  value={formData.city}
                                  className="form-control"
                                  placeholder="City"
                                  onChange={handleInputChange}
                                  disabled={freeShipping ? true : false}
                                />
                              </div>
                              <div className="form-outline form-white mb-4">
                                <label className="form-label" htmlFor="state">
                                  State Code, Territory Code if outside USA. Put
                                  NA if not applicable.*
                                </label>
                                <input
                                  type="text"
                                  id="stateCode"
                                  name="stateCode"
                                  value={formData.stateCode}
                                  className="form-control"
                                  placeholder="State code"
                                  onChange={handleInputChange}
                                  disabled={freeShipping ? true : false}
                                />
                              </div>
                              <div className="form-outline form-white mb-4">
                                <label className="form-label" htmlFor="zipCode">
                                  Zip/Postal Code*
                                </label>
                                <input
                                  type="text"
                                  name="zipCode"
                                  id="zipCode"
                                  value={formData.zipCode}
                                  className="form-control"
                                  placeholder="Zip Code"
                                  onChange={handleInputChange}
                                  disabled={freeShipping ? true : false}
                                />
                              </div>
                              {retailMode[4] === "enroll" && (
                                <div className="form-outline form-white mb-4">
                                  <label
                                    className="form-label"
                                    htmlFor="paypalAccount"
                                  >
                                    Paypal/Gcash Account*
                                  </label>
                                  <input
                                    type="text"
                                    name="paypalAccount"
                                    id="paypalAccount"
                                    value={formData.paypalAccount}
                                    className="form-control"
                                    placeholder="paypal/gcash to receive your payout"
                                    onChange={handleInputChange}
                                    disabled={freeShipping && retailMode[4] === "enroll" ? true : false}
                                  />
                                </div>
                              )}
                              <div className="form-outline form-white mb-4">
                                <label className="form-label" htmlFor="zipCode">
                                  Promo Code
                                </label>
                                <input
                                  type="text"
                                  name="promoCode"
                                  id="promoCode"
                                  value={formData.promoCode}
                                  className="form-control"
                                  placeholder="IMPORTANT! Fill-up all required fields and quantity first."
                                  onChange={handleInputChange}
                                  disabled={freeShipping ? true : false}
                                />
                              </div>
                              <div className="form-outline form-white mb-4">
                                <Button
                                  type="button"
                                  variant="contained"
                                  className="multiStepButton"
                                  onClick={handlePromoCode}
                                >
                                  {freeShipping
                                    ? "FREE SHIPPING APPLIED"
                                    : promoCodeFound
                                    ? "PROMO CODE APPLIED"
                                    : "APPLY PROMO CODE"}
                                </Button>
                              </div>
                            </>
                          )}
                          <div className="d-flex justify-content-between">
                            <p className="mb-2">Total Qty(PCS)</p>
                            <p className="mb-2">{probioxQty + metaxQty}</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="mb-2">Subtotal</p>
                            <p className="mb-2">
                              ${(probioXTotal + metaXTotal).toFixed(2)}
                            </p>
                          </div>
                          {retailMode[4] === "enroll" && (
                            <div className="d-flex justify-content-between">
                              <p className="mb-2">Enrollment Fee</p>
                              <p className="mb-2">
                                ${enrollmentFee.toFixed(2)}
                              </p>
                            </div>
                          )}
                          <div className="d-flex justify-content-between">
                            <p className="mb-2">Shipping</p>
                            <p className="mb-2">${shippingFee.toFixed(2)}</p>
                          </div>

                          <div className="d-flex justify-content-between mb-4">
                            <p className="mb-2">Total</p>
                            <p className="mb-2">
                              $
                              {retailMode[4] === "enroll"
                                ? (
                                    probioXTotal +
                                    metaXTotal +
                                    enrollmentFee +
                                    shippingFee
                                  ).toFixed(2)
                                : (
                                    probioXTotal +
                                    metaXTotal +
                                    shippingFee
                                  ).toFixed(2)}
                            </p>
                          </div>
                          <Button
                            type="submit"
                            variant="contained"
                            className="multiStepButton"
                          >
                            $
                            {retailMode[4] === "enroll"
                              ? (
                                  probioXTotal +
                                  metaXTotal +
                                  shippingFee +
                                  enrollmentFee
                                ).toFixed(2)
                              : (
                                  probioXTotal +
                                  metaXTotal +
                                  shippingFee
                                ).toFixed(2)}{" "}
                            {retailMode[4] === "enroll"
                              ? "ENROLL AND CHECKOUT"
                              : "CHECKOUT"}
                          </Button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RetailStoreComponent;
