import React from "react";
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useField } from "formik";

const RadioButtonInputField = ({ label, ...props }: any) => {
    const [field] = useField(props)

    return <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        label={label} {...field} {...props}
    >
        <FormControlLabel value="m" control={<Radio sx={{
            color: "#C2C2C2",
            '&.Mui-checked': {
                color: "#151515",
            },
        }} />} label="Male" />
        <FormControlLabel value="f" control={<Radio sx={{
            color: "#C2C2C2",
            '&.Mui-checked': {
                color: "#151515",
            },
        }} />} label="Female" />
        <FormControlLabel value="o" control={<Radio sx={{
            color: "#C2C2C2",
            '&.Mui-checked': {
                color: "#151515",
            },
        }} />} label="Other"
        />
    </RadioGroup>
}

export default RadioButtonInputField