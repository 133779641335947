import {useContext, useEffect} from 'react';
import { SalesContext } from '../store/sales-context';
const useShippingFee: React.FC<any> = (country, bottles) => {
   
    const {shippingFee, setShippingFee} = useContext(SalesContext);
  useEffect(() => {
    if (country === "au") {
      if (bottles < 7) {
        setShippingFee("10.00");
      } else if (bottles > 6 && bottles < 21) {
        setShippingFee("16.77");
      }
    } else if (country === "ph") {
      setShippingFee("0.00"); //to be confirmed.
    } else {
      if (bottles < 7) {
        setShippingFee("25.00");
      } else if (bottles > 6 && bottles < 11) {
        setShippingFee("35.00");
      } else if (bottles > 10 && bottles < 21) {
        setShippingFee("42.00");
      }
    }
  }, [country, bottles, setShippingFee]);

  return shippingFee;
}

export default useShippingFee