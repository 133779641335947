import { useContext } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import UserContext from "../store/user-context";
const StatBox: React.FC<{
  title: string;
  subtitle: string;
  icon: JSX.Element;
  definition: string;
  increase: string;
}> = ({ title, subtitle, icon, definition, increase }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useContext(UserContext);

  return (
    <Box width="100%" m="0 30px">
      <Box display="flex" justifyContent="space-between">
        {icon}
        {user.currentPackage !== "Retailer" && (
          <Box bgcolor={colors.grey[1000]} p="5px 10px" borderRadius="4px">
            {definition}
          </Box>
        )}
      </Box>
      <Box>
        <Typography
          variant="h2"
          fontWeight="bold"
          sx={{ color: colors.grey[900] }}
        >
          {title}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="space-between" mt="2px">
        {user.currentPackage !== "Retailer" && (
          <Typography variant="h5" sx={{ color: colors.grey[1300] }}>
            {subtitle}
          </Typography>
        )}
        &nbsp;
      </Box>
    </Box>
  );
};

export default StatBox;
