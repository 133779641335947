import React from "react";
import { tokens } from "../../theme";
import { cutOffDay } from "../../utils/LA-time";

import { Box, Typography, useTheme } from "@mui/material";

const PendingFT = () => {
  console.log('LA TIME');
    console.log(cutOffDay());
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
    //   display="flex"
    //   justifyContent="space-between"
      p={2}
      sx={{ backgroundColor: "#F23D44" }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", textAlign:"center" }}>
        <Typography variant="h5" fontWeight="200" color="white" >
          You have pending Fast Track Commission. Please activate your account until "{cutOffDay()}" LA Time to receive your commission. 
        </Typography>
      </Box>
    </Box>
  );
};

export default PendingFT;
