import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { FormikValues } from "formik";
import React from "react";
import './FormNavigation.css';
interface Props {
    hasPrevious?:boolean;
    onBackClick: (values: FormikValues) => void;
    isLastStep: boolean
}

const FormNavigation = (props: Props) => {
    return (
        <div style = {{display: 'flex', marginTop: 50, justifyContent:'space-between' }}>
            {props.hasPrevious ? <Button variant="contained" onClick={props.onBackClick} className="multiStepButton">Back</Button>: <Link  to="/members"><Button variant="contained" className="multiStepButton">Back</Button></Link>}
             
            <Button type="submit" color="primary" className="multiStepButton" variant="contained">{props.isLastStep ? 'Submit for Payment': 'Next'}</Button>
        </div>
    )
}

export default FormNavigation;