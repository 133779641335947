import { useContext, useEffect } from "react";
import { CountryContext } from "../../../store/country-context";
import { Select, Typography } from "@mui/material";
import { useField, useFormikContext } from "formik";
import MenuItem from "@mui/material/MenuItem";

const SelectCountryField = ({ label, ...props }: any) => {
  const {  setCountryCode} = useContext(CountryContext);
  const { values} = useFormikContext<any>();
  const [field, meta] = useField(props);

  useEffect(() => {
    setCountryCode(values.country);
    
  }, [setCountryCode, values.country]);

  
  return (
    <>
      <Select
        label={label}
        {...field}
        error={meta.touched && Boolean(meta.error)}
      >
        <MenuItem value="">--Select Country--</MenuItem>
        <MenuItem value="au">Australia</MenuItem>
        <MenuItem value="cnd">Canada</MenuItem>
        <MenuItem value="usa">United States</MenuItem>
        <MenuItem value="ph">Philippines</MenuItem>
        <MenuItem value="sg">Singapore</MenuItem>
      </Select>
      {meta.touched && Boolean(meta.error) && (
        <Typography
          fontSize={10}
          color="#f44336"
          sx={{ marginTop: "5px", marginLeft: "15px" }}
        >
          required
        </Typography>
      )}
    </>
  );
};

export default SelectCountryField;
